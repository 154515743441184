import React, {useEffect, useState} from 'react';
import { useLocation, Link } from "react-router-dom";
import { useHistory } from 'react-router';
import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';

import { add_delivery_destination } from '../../../Context/actions';
import LoadingSpinner from "../../loading/LoadingSpinner";
import LoadingBar from 'react-top-loading-bar';

function Add_delivery_destination() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const [deliveryDestinationName, setDeliveryDestinationName] = useState();
    const [deliveryDestinationAddress, setDeliveryDestinationAddress] = useState();
    const [deliveryDestinationPhone, setDeliveryDestinationPhone] = useState();
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0)

    const history = useHistory();

    const handleonSubmit = async e => {
    e.preventDefault();
        setLoading(true)
        setProgress(progress + 40)
        const promise_login = Promise.resolve(add_delivery_destination(deliveryDestinationName,deliveryDestinationAddress,deliveryDestinationPhone));

        promise_login.then((value) => {
            if(value.hasil == '1'){
                //sukses
                setLoading(false)
                setProgress(100)
                alert(value.message);
                history.push('/delivery_destination');
                window.location.reload(true);
            }else if(value.hasil == '2'){
                //username password salah
                setLoading(false)
                setProgress(100)
                alert(value.message);
                history.push('/delivery_destination');
                window.location.reload(true);
            }
        });
        
    }

    

  return (
    <div>
      <div>
          <LoadingBar
            color='#f11946'
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
          /> 
          <Header_admin></Header_admin>
          <main id="main" class="main">

            <div class="pagetitle">
              <h1>Tambah Pabrik</h1>
              <nav>
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                  <li class="breadcrumb-item"><Link to="/delivery_destination">Pabrik</Link></li>
                  <li class="breadcrumb-item active">Tambah Pabrik</li>
                </ol>
              </nav>
            </div>

            <section class="section">
              <div class="row">
                <div class="col-lg-12">

                  <div class="card">
                    <div class="card-body">
                          <form onSubmit={handleonSubmit}>
                          <div class="row mb-3">
                                <label for="inputText" class="col-sm-2 col-form-label">Nama Pabrik</label>
                                <div class="col-sm-10">
                                <input type="text" class="form-control" name="deliveryDestinationName" id="deliveryDestinationName" onChange={(e) => setDeliveryDestinationName(e.target.value)} />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="inputText" class="col-sm-2 col-form-label">Alamat Pabrik</label>
                                <div class="col-sm-10">
                                <input type="text" class="form-control" name="deliveryDestinationAddress" id="deliveryDestinationAddress" onChange={(e) => setDeliveryDestinationAddress(e.target.value)} />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="inputText" class="col-sm-2 col-form-label">Telepon Pabrik</label>
                                <div class="col-sm-10">
                                <input type="text" class="form-control" name="deliveryDestinationPhone" id="deliveryDestinationPhone" onChange={(e) => setDeliveryDestinationPhone(e.target.value)} />
                                </div>
                            </div>
                          

                          <div class="row mb-3">
                              <label for="inputText" class="col-sm-2 col-form-label"></label>
                              <div class="col-sm-12">
                                  <button type="submit" className="button_simpan" disabled={loading}>
                                    {loading ? (
                                      <i
                                        className="fa fa-refresh fa-spin"
                                        style={{ marginRight: "5px" }}
                                      />
                                    ):
                                    (<i
                                      className="fa fa-save"
                                      style={{ marginRight: "5px" }}
                                    />)} &nbsp;
                                    {loading && <span>Harap Tunggu ...</span>}
                                    {!loading && <span>Simpan</span>}
                                  </button>
                                  
                              </div>
                          </div>

                          </form>

                    </div>
                  </div>

                </div>
              </div>
            </section>

          </main>
          <Footer_admin></Footer_admin>
        </div>
    </div>
  );
}

export default Add_delivery_destination;