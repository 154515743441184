import React, {useEffect, useState} from 'react';
import { useLocation, Link } from "react-router-dom";
import { useHistory } from 'react-router';
import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';
import Sidebar_admin from '../../../Layout/Sidebar_admin';

import { add_driver } from '../../../Context/actions';
import LoadingBar from 'react-top-loading-bar';


function Add_driver() {

    const [driverName, setDriverName] = useState();
    const [driverAddress, setDriverAddress] = useState();
    const [driverPhone, setDriverPhone] = useState();
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const history = useHistory();


    const handleonSubmit = async e => {
    e.preventDefault();
        setLoading(true)
        setProgress(progress + 40)
        //console.log(login(username,password));
        const promise_login = Promise.resolve(add_driver(driverName,driverAddress,driverPhone));

        promise_login.then((value) => {
            if(value.hasil == '1'){
                setLoading(false)
                setProgress(100)
                alert(value.message);
                history.push('/driver');
                window.location.reload(true);
            }else if(value.hasil == '2'){
                setLoading(false)
                setProgress(100)
                alert(value.message);
                history.push('/driver');
                window.location.reload(true);
            }
        });
    }

    

  return (
 
        <div>
            <LoadingBar
              color='#f11946'
              progress={progress}
              onLoaderFinished={() => setProgress(0)}
            /> 
            <Header_admin></Header_admin>
            <main id="main" class="main">

              <div class="pagetitle">
                <h1>Tambah Sopir</h1>
                <nav>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li class="breadcrumb-item"><Link to="/type_price">Sopir</Link></li>
                    <li class="breadcrumb-item active">Tambah Sopir</li>
                  </ol>
                </nav>
              </div>

              <section class="section">
                <div class="row">
                  <div class="col-lg-12">

                    <div class="card">
                      <div class="card-body">
                            <form onSubmit={handleonSubmit}>
                            <div class="row mb-3">
                                <label for="inputText" class="col-sm-2 col-form-label">Nama Sopir</label>
                                <div class="col-sm-10">
                                <input type="text" class="form-control" name="driverName" id="driverName" onChange={(e) => setDriverName(e.target.value)} />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="inputText" class="col-sm-2 col-form-label">Alamat Sopir</label>
                                <div class="col-sm-10">
                                <input type="text" class="form-control" name="driverAddress" id="driverAddress" onChange={(e) => setDriverAddress(e.target.value)} />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="inputText" class="col-sm-2 col-form-label">Telepon Sopir</label>
                                <div class="col-sm-10">
                                <input type="text" class="form-control" name="driverPhone" id="driverPhone" onChange={(e) => setDriverPhone(e.target.value)} />
                                </div>
                            </div>

                            <div class="row mb-3">
                              <label for="inputText" class="col-sm-2 col-form-label"></label>
                                <div class="col-sm-10">
                                    <button type="submit" className="button_simpan" disabled={loading}>
                                      {loading ? (
                                        <i
                                          className="fa fa-refresh fa-spin"
                                          style={{ marginRight: "5px" }}
                                        />
                                      ):
                                      (<i
                                        className="fa fa-save"
                                        style={{ marginRight: "5px" }}
                                      />)} &nbsp;
                                      {loading && <span>Harap Tunggu ...</span>}
                                      {!loading && <span>Simpan</span>}
                                    </button>
                                </div>
                            </div>

                            </form>

                      </div>
                    </div>

                  </div>
                </div>
              </section>

            </main>
            <Footer_admin></Footer_admin>
        </div>
        
  );
}

export default Add_driver;