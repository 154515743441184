import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { change_password,isAuthenticated,logout } from '../../../Context/actions';

import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';
import LoadingBar from 'react-top-loading-bar';

import 'jquery/dist/jquery.min.js';
 
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

function Changepassword() {

  const history = useHistory();

  let [nama, setNama] = useState('0');

  const [username, setUsername] = useState();
  const [oldpassword, setOldPassword] = useState();
  const [newpassword, setNewPassword] = useState();

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  if(nama == '0'){
    const promise_auth = Promise.resolve(isAuthenticated());
    promise_auth.then((value) => {
      
      
        if(value.hasil == '1'){
            //sukses
            setNama(value.message);
        }else if(value.hasil == '2'){
            //auth gagal
            localStorage.setItem('token', '');
            localStorage.setItem('username', '');
            localStorage.setItem('nama', '');
            setNama('0');
            history.push("/Notfound");
        }
    },[]);
  }

  
  const handleonSubmit = async e => {
    e.preventDefault();
        
        const new_password_cek = document.getElementById("new_password").value;
        const new_password_confirmation_cek = document.getElementById("new_password_confirmation").value;
        const newpassword = document.getElementById("new_password_confirmation").value;
        if(new_password_cek === new_password_confirmation_cek){
          setProgress(progress + 40)
          setLoading(true)
          const promise_login = Promise.resolve(change_password(nama,oldpassword,newpassword));
          console.log(nama,oldpassword,newpassword)

          promise_login.then((value) => {
              if(value.hasil == '1'){
                  //sukses
                  setProgress(100);
                  setLoading(false);
                  alert(value.message);
                  const promise1 = Promise.resolve(logout(localStorage.getItem('username'),localStorage.getItem('token')));
                  promise1.then((value) => {
                    //console.log(value.hasil);
                    
                      if(value.hasil == '1'){
                          //sukses
                          setNama('0');
                          history.push("/admin");
                          window.location.reload(true);
                      }else if(value.hasil == '2'){
                          //logout gagal
                          setNama('0');
                          history.push("/admin");
                          window.location.reload(true);
                      }
                  });
              }else{
                setProgress(100);
                setLoading(false);
                alert(value.message);
              }
          });
        }else{
          alert("Password baru dan konfirmasi password harus sama.")
        }
        
  }

  

  return (
 
        <div>
            <LoadingBar
              color='#f11946'
              progress={progress}
              onLoaderFinished={() => setProgress(0)}
            />
            <Header_admin></Header_admin>
            <main id="main" class="main">

              <div class="pagetitle">
                <h1>Ganti Sandi</h1>
                <nav>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><Link to="/dashboard">Beranda</Link></li>
                    <li class="breadcrumb-item active"><Link to="/changepassword">Ganti Sandi</Link></li>
                  </ol>
                </nav>
              </div>

              <section class="section">
              <div class="row">
        <div class="col-lg-12">

          <div class="card">
            <div class="card-body">

              <form onSubmit={handleonSubmit}>
                <div class="row mb-3">
                  <label for="inputText" class="col-sm-2 col-form-label">Username</label>
                  <div class="col-sm-10">
                    <input type="text" id="username" name="username" value={nama} onChange={(e) => setUsername(e.target.value)} class="form-control" disabled />
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputPassword" class="col-sm-2 col-form-label">Password Lama</label>
                  <div class="col-sm-10">
                    <input type="text" id="old_password" name="old_password" onChange={(e) => setOldPassword(e.target.value)} class="form-control" />
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputPassword" class="col-sm-2 col-form-label">Password Baru</label>
                  <div class="col-sm-10">
                    <input type="text" id="new_password" name="new_password" class="form-control" />
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputPassword" class="col-sm-2 col-form-label">Konfirmasi Password Baru</label>
                  <div class="col-sm-10">
                    <input type="text" id="new_password_confirmation" name="new_password_confirmation" onChange={(e) => setNewPassword(e.target.value)} class="form-control" />
                  </div>
                </div>

                <div class="row mb-3">
                  <label class="col-sm-2 col-form-label"></label>
                  <div class="col-sm-10">
                    <button type="submit" className="button_simpan" disabled={loading}>
                      {loading ? (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                      ):
                      (<i
                        className="fa fa-save"
                        style={{ marginRight: "5px" }}
                      />)} &nbsp;
                      {loading && <span>Harap Tunggu ...</span>}
                      {!loading && <span>Simpan</span>}
                    </button>
                  </div>
                </div>

              </form>

            </div>
          </div>

        </div>
      </div>
              </section>

            </main>
         

            <Footer_admin></Footer_admin>

            
        </div>
        
  );
}

export default Changepassword;