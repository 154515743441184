import React, {useEffect, useState} from 'react';
import ReactPaginate from "react-paginate";
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {Modal, Button, Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { delete_rent,invoice } from '../../../Context/actions';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';

import LoadingSpinner from "../../loading/LoadingSpinner";
import LoadingBar from 'react-top-loading-bar';
 
import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';

function Order() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const history = useHistory();

  const [rent, setRent] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState();
  const [invoiceDate, setInvoiceDate] = useState();
  const [customerName, setCustomerName] = useState();
  const [createdDate, setCreatedDate] = useState();
  const [bank, setBank] = useState([]);

  const [page, setPage] = useState(1);
  const [awal, setAwal] = useState();
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");

  const [loading, setLoading] = useState(false);
  const [loadingCari, setLoadingCari] = useState(false);
  const [loadingResetPencarian, setLoadingResetPencarian] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [loadingResi, setLoadingResi] = useState(false);
  const [loadingCreateOrder, setLoadingCreateOrder] = useState(false);
  const [progress, setProgress] = useState(0);

  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('info');
  const [alertTitle, setAlertTitle] = useState('Mohon Ditunggu');

  const settings = {
      method: 'GET',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'usercode': localStorage.getItem('username'),
          'token': localStorage.getItem('token'),
      }
  };

  useEffect(() => {
      let url;
      const fetchData = async () => {
      if(query == ''){
          url = 'https://revasamudra.ivantie.com/backend/revasamudra/api/showRentGetDataPagination';
          const res = await fetch(
              url, settings
          );
          const json = await res.json();
          
          
          setPage(page);
          setPages(json.pages);
          setRows(json.rows);
          
      }else{
        url = 'https://revasamudra.ivantie.com/backend/revasamudra/api/showRentGetDataPaginationSearch/'+query;
        console.log(url)
        const res = await fetch(
            url, settings
        );
        const json = await res.json();
        
        
        setPage(page);
        setPages(json.pages);
        setRows(json.rows);
      }

      }

      fetchData();
  }, [page, keyword]);

  useEffect(() => {
      
      let url;
      setProgress(progress + 40);
      if(query == ''){
          
          if(awal == undefined){
              url = 'https://revasamudra.ivantie.com/backend/revasamudra/api/showRentPagination/0';
          }else{
              url = 'https://revasamudra.ivantie.com/backend/revasamudra/api/showRentPagination/'+awal;
          }

          console.log(url);
          const fetchData = async () => {
          const res = await fetch(
              url, settings
          );
          const json = await res.json();
          
          setProgress(100);
          setRent(json.data);
          
          };
          fetchData();
      }
    },[]);

  const changePage = ({ selected }) => {
      setProgress(progress + 40);
      if(query == ''){
          setPage(selected+1);
          
          let awal;
          
          awal = (Number((selected+1)+'0') - 10)

          setAwal(awal);

          const fetchData = async () => {
          const res = await fetch(
              'https://revasamudra.ivantie.com/backend/revasamudra/api/showRentPagination/'+awal, settings
          );
          const json = await res.json();
          
          if(json.status == "true"){
              setProgress(100);
              setRent([]);
              setRent(json.data);
          }else{
              setProgress(100);
              setMsg(
                  "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
              );
          }
          
          };
          fetchData();
      }else{
          setPage(selected+1);
          let awal;
          
          awal = (Number((selected+1)+'0') - 10)

          setAwal(awal);
          
          const fetchData = async () => {
          const res = await fetch(
              'https://revasamudra.ivantie.com/backend/revasamudra/api/showRentPaginationSearch/'+awal+'/'+query, settings
          );
          const json = await res.json();
          
          if(json.status == "true"){
              setProgress(100);
              setRent([]);
              setRent(json.data);
              
              setPages(json.pages);
              setRows(json.rows);
          }else{
              setProgress(100);
              setMsg(
                  "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
              );
          }
          
          };
          fetchData();
      }
      
  };

  function searchData(){
      setProgress(progress + 40);
      setLoadingCari(true);
      const fetchData = async () => { 
      const res = await fetch(
          'https://revasamudra.ivantie.com/backend/revasamudra/api/showRentPaginationSearch/0/'+query, settings
      );
      const json = await res.json();
      
      if(json.status == "true"){
          setProgress(100);
          setLoadingCari(false);
          setRent([]);
          setPages(0);
          setRows(0);
          setPage(1);
          setRent(json.data);
          setPages(json.pages);
          setRows(json.rows);
          
      }else{
          setProgress(100);
          setLoadingCari(false);
          setRent(json.data);
          setPages(json.pages);
          setRows(json.rows);
          setMsg(
              "Data yang anda cari tidak di temukan. Pencarian ini berdasarkan Nama Penerima !"
          );
      }
      
      };
      fetchData(); 
  }; 

  function resetPencarian(){
    setLoadingResetPencarian(true)
    setQuery("");
    setTimeout(
        function() {
          setLoadingResetPencarian(false)
          window.location.reload(true);
        }
        ,
        2000
    );
  }

  function cetak_invoice(id){
    window.open("https://revasamudra.ivantie.com/backend/revasamudra/api/invoice/"+id);
    // setProgress(progress + 40);
    // setLoadingResi(true);
    // const promise_login = Promise.resolve(invoice(id));

    // promise_login.then((value) => {
    //     if(value.hasil == '1'){
    //         setProgress(100);
    //         setLoadingResi(false);
    //         window.open(value.message);
    //     }
    // });
  }

  
  function edit_rent(id){
    history.push('/edit_rent?invoiceNumber='+id);
  }

  function upload_tt(id){
    history.push('/upload_tt?invoiceNumber='+id);
  }

  
  function delete_rent_onclick(id){
    if (window.confirm('Are you sure you want to delete this ?')) {
      setProgress(progress + 40);
      setLoading(true);
      setAlert(true);
      setAlertSeverity("info");
      setAlertContent("Proses Delete ...");
      const promise_login = Promise.resolve(delete_rent(id));

        promise_login.then((value) => {
            console.log(value)
            if(value.hasil == '1'){
                //sukses
                setProgress(100);
                setLoading(false)
                setAlertSeverity("success");
                setAlertContent(value.message + "Hapus Invoice Berhasil. Refresh dalam 3 detik lagi ...");
                setTimeout(() => {
                  history.push('/rent');
                  window.location.reload(true);
                }, "3000");
            }else if(value.hasil == '2'){
                //username password salah
                setProgress(100);
                setLoading(false)
                setAlertSeverity("error");
                setAlertContent(value.message);
            }else if(value.hasil == '3'){
              //username password salah
              setProgress(100);
              setLoading(false)
              setAlertSeverity("error");
              setAlertContent(value.message);
          }
        });
        
    } else {
      setProgress(100);
      setLoading(false)
      // Do nothing!
    }
  }

  function edit_rent(id){
    history.push('/edit_rent?invoiceNumber='+id);
  }

  function ambilData(invoiceNumber){
    let url;
    setProgress(progress + 40);
    setLoading(true);
    setAlert(true);
    setAlertSeverity("info");
    setAlertContent("Load data Nomor Rekening")

    url = 'https://revasamudra.ivantie.com/backend/revasamudra/api/searchBank';

    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'usercode': localStorage.getItem('username'),
            'token': localStorage.getItem('token'),
        }
    };

    const fetchData = async () => {
    const res = await fetch(
        url,settings
    );
    const json = await res.json();
    
    setProgress(100);
    setLoading(false);
    setBank(json.data);
    setInvoiceNumber(invoiceNumber);
    setShow(true);
    setAlert(false);
    
    };
    fetchData();
  }

  function pilih(nomorRekening,atasNama,bank){
    window.open("https://revasamudra.ivantie.com/backend/revasamudra/api/invoice/"+invoiceNumber+"/"+nomorRekening+"/"+atasNama+"/"+bank);
  }

  function formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear(),
          hours = '' + d.getHours(),
          minutes = '' + d.getMinutes(),
          seconds = '' + d.getSeconds();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
      if (hours.length < 2) 
      hours = '0' + hours;
      if (minutes.length < 2) 
          minutes = '0' + minutes;
      if (seconds.length < 2) 
      seconds = '0' + seconds;

      return [day, month, year].join('/') + " " + [hours, minutes, seconds].join(':');
  }

  function formatJustDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = '' + d.getHours(),
        minutes = '' + d.getMinutes(),
        seconds = '' + d.getSeconds();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    if (hours.length < 2) 
    hours = '0' + hours;
    if (minutes.length < 2) 
        minutes = '0' + minutes;
    if (seconds.length < 2) 
    seconds = '0' + seconds;

    return [day, month, year].join('/');
}

const pay_styles = {
  fontWeight: 'bold',
  fontSize: '17px',
  color: 'red',
  fontStyle: 'italic',
  // ... other CSS properties
};

function currencyFormat(num) {
  return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

  return (
    
    <div>
      <LoadingBar
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%' }}>
                  {alertContent}
                </Alert>
        </Snackbar>
    <Header_admin></Header_admin>
  <main id="main" class="main">

    <div class="pagetitle">
      <h1>Data Invoice</h1>
      &nbsp;
      <nav>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
          <li class="breadcrumb-item active">Invoice</li>
        </ol>
      </nav>
    </div>

    <section class="section">
    <div class="row">
                  <div class="col-lg-4">
                      <button className="button_tambah_item" style={{width: "100%"}} onClick={(e) => {
                          e.preventDefault();
                          window.location.href='/add_rent';
                          }}>
                        <i className="fa fa-plus"
                          style={{ marginRight: "5px" }}
                        />
                        &nbsp;
                        Buat Invoice
                      </button>
                  </div>
                  <div class="col-lg-4 mt-1">
                  <input
                        type="text"
                        className="form-control"
                        style={{width: "100%"}}
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        placeholder="Cari sesuatu disini..."
                      />
                  </div>
                  <div class="col-lg-2">
                  <button type="button" style={{width: "100%"}} onClick={searchData} className="button_cari" disabled={loadingCari}>
                        {loadingCari ? (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px" }}
                          />
                        ):
                        (<i
                          className="fa fa-search"
                          style={{ marginRight: "5px" }}
                        />)}
                        {loadingCari && <span>Cari</span>}
                        {!loadingCari && <span>Cari</span>}
                      </button>
                  </div>
                  
                  <div class="col-lg-2">
                      <button type="button" style={{width: "100%"}} onClick={resetPencarian} className="button_reset_pencarian" disabled={loadingResetPencarian}>
                        {loadingResetPencarian ? (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: "5px" }}
                          />
                        ):
                        (<i
                          className="fa fa-refresh"
                          style={{ marginRight: "5px" }}
                        />)}
                        {loadingResetPencarian && <span>Reset Pencarian</span>}
                        {!loadingResetPencarian && <span>Reset Pencarian</span>}
                      </button>
                  </div>
                  
                </div>
      <br />
      <div class="row"> 
        <div class="col-lg-12">
        <div>
            <table className="table table-hover table-bordered">
                <thead>
                <tr>
                    <th>No Invoice</th>
                    <th>Tgl Invoice</th>
                    <th>Customer</th>
                    <th>Total</th>
                    <th>Unggah TT</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {
                rent != 'Data Tidak Tersedia' ?
                rent.map((rent) => (
                    <tr>
                    <td>
                      {rent.invoiceNumber}
                      <br />
                      {rent.pay !== 0 ? (
                          <div style={pay_styles}>Lunas</div>
                      ) : (
                          <div></div>
                      )}

                    </td>
                    <td>{formatJustDate(rent.invoiceDate)}</td>
                    <td>{rent.customerName}</td>
                    <td><i><b>{currencyFormat(rent.totalPrice)}</b></i></td>
                    <td>
                      
                        {rent.linkTt === null ? (
                          <button type="button" className="button_unggah" onClick={() => upload_tt(rent.invoiceNumber)} disabled={loading}>
                            {loading ? (
                              <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                              />
                            ):
                            (<i
                              className="fa fa-edit"
                              style={{ marginRight: "5px" }}
                            />)}
                            {loading && <span>Unggah</span>}
                            {!loading && <span>Unggah</span>}  
                          </button>
                        ):
                        (
                          <button type="button" className="button_perbaiki_unggah" onClick={() => upload_tt(rent.invoiceNumber)} disabled={loading}>
                            {loading ? (
                              <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                              />
                            ):
                            (<i
                              className="fa fa-edit"
                              style={{ marginRight: "5px" }}
                            />)}
                            {loading && <span>Perbaiki</span>}
                            {!loading && <span>Perbaiki</span>}  
                          </button>
                        )}
                        
                    </td>
                    <td>
                      <div class="w3-show-inline-block">
                        <div class="w3-bar">
                          <button type="button" className="button_edit" onClick={() => edit_rent(rent.invoiceNumber)} disabled={loading}>
                            {loading ? (
                              <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                              />
                            ):
                            (<i
                              className="fa fa-edit"
                              style={{ marginRight: "5px" }}
                            />)}
                            {loading && <span>Edit</span>}
                            {!loading && <span>Edit</span>}  
                          </button>&nbsp;
                          <button type="button" className="button_hapus" onClick={() => delete_rent_onclick(rent.id)} disabled={loading}>
                            {loading ? (
                              <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                              />
                            ):
                            (<i
                              className="fa fa-times-circle"
                              style={{ marginRight: "5px" }}
                            />)}
                            {loading && <span>Hapus</span>}
                            {!loading && <span>Hapus</span>}
                          </button>&nbsp;
                          <button type="button" className="button_cetak" onClick={() => ambilData(rent.invoiceNumber)} disabled={loading}>
                            {loading ? (
                              <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                              />
                            ):
                            (<i
                              className="fa fa-print"
                              style={{ marginRight: "5px" }}
                            />)}
                            {loading && <span>Cetak Invoice</span>}
                            {!loading && <span>Cetak Invoice</span>}
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
                :
                <tr>
                    <td colSpan={5}>Data Tidak Tersedia</td>
                </tr>
                }
                </tbody>
            </table>
            <p>
                Total Rows: {rows} Page: {page} of {pages}
            </p>
            <p className="has-text-centered has-text-danger">{msg}</p>

            <div id="container">
                <ReactPaginate
                    previousLabel={"< Prev"}
                    nextLabel={"Next >"}
                    pageCount={pages}
                    onPageChange={changePage}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
            </div>
        </div>

        </div>
      </div>
    </section>

  </main>
  <Modal size="lg" show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Data Nomor Rekening</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <form class="row g-3 needs-validation" novalidate>
      <div class="row">
        <div class="col-12">
        <div class="table-responsive">
          <table id="example" className="table table-hover table-bordered">
              <thead>
              <tr>
                  <th>Bank</th>
                  <th>No Rekening</th>
                  <th>Atas Nama</th>
                  <th>Action</th>
              </tr>
              </thead>
              <tbody>
              {
              bank != 'Data Tidak Tersedia' ?
              bank.map((bank) => (
                  <tr>
                  <td>{bank.bank}</td>
                  <td>{bank.accountNumber}</td>
                  <td>{bank.owner}</td>
                  <td>
                    <div class="w3-show-inline-block">
                      <div class="w3-bar">
                        <button type="button" className="button_edit" disabled={loading} onClick={() => pilih(bank.accountNumber,bank.owner,bank.bank)}>
                          {loading ? (
                            <i
                              className="fa fa-refresh fa-spin"
                              style={{ marginRight: "5px" }}
                            />
                          ):
                          (<i
                            className="fa fa-edit"
                            style={{ marginRight: "5px" }}
                          />)}
                          {loading && <span>Pilih</span>}
                          {!loading && <span>Pilih</span>}
                        </button>
                      </div>
                    </div>
                  </td>
                  </tr>
              ))
              :
              <tr>
                  <td colSpan={5}>Data Tidak Tersedia</td>
              </tr>
              }
              </tbody>
          </table>
          </div>
        </div>
      </div>
    </form>
    </Modal.Body>
  </Modal>
  <Footer_admin></Footer_admin>
    </div>
            
          
            
        
  );
}

export default Order;