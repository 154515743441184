import React, {useEffect, useState} from 'react';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import Select from "react-select";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import TableRows from "./TableRows";
import { useLocation,Link } from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import { useHistory } from 'react-router';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Snackbar from "@material-ui/core/Snackbar";


import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';

import { upload_rent } from '../../../Context/actions';
import { delete_detail_rent } from '../../../Context/actions';
import LoadingBar from 'react-top-loading-bar';


function Upload_rent() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const history = useHistory();

    const [show, setShow] = useState(false);
    const [closeSnackbar, setCloseSnakbar] = useState(false);
    const handleCloseSnackbar = () => setAlert(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const checkList = React.useRef(null);

    const [orderRecap, setOrderRecap] = useState([]);
    
    const [rentEdit, setRentEdit] = useState([]);
    
    const [invoiceNumber, setInvoiceNumber] = useState();
    const [linkTt, setLinkTt] = useState(null);
    const [file, setFile] = useState();

    const [alert, setAlert] = useState(false);
    const [alertContent, setAlertContent] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');
    const [alertTitle, setAlertTitle] = useState('Mohon Ditunggu');

    const [rowsData, setRowsData] = useState([]);


    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'usercode': localStorage.getItem('username'),
            'token': localStorage.getItem('token'),
        }
    };

    function simpan(){
        setProgress(progress + 40)
        setLoading(true)

        setAlert(true);
        setAlertSeverity("info");
        setAlertContent("Proses simpan update ...");
  
        // console.log("pphmoney :" + pphMoney,"stapel money" + totalStapelMoney,"stapel pocket money" + totalStapelPocketMoney,"harbor money" + totalHarborMoney,"depo money" + totalDepoMoney,"coollie money" + totalCoollieMoney,"overtime money" + totalOvertimeMoney);
        if(linkTt.size > 1e6){
            window.alert("Please upload a file smaller than 1 MB");
        }else{
            const promise1 = Promise.resolve(upload_rent(invoiceNumber,linkTt));
            promise1.then((value) => {
            console.log(value.hasil);
            if(value.hasil == '1'){
                //sukses
                    setProgress(100);
                    setLoading(false);
                    console.log(value.message);
                    setAlertSeverity("success");
                    setAlertContent(value.message + ". Refresh dalam 3 detik lagi ...");
                    setTimeout(() => {
                    history.push('/upload_tt?invoiceNumber='+params.get("invoiceNumber"));
                    window.location.reload(true);
                    }, "3000");
            }else if(value.hasil == '2'){
                    //username password salah
                    setProgress(100);
                    setLoading(false);
                    console.log(value.message);
                    setAlertSeverity("success");
                    setAlertContent(value.message + ". Refresh dalam 3 detik lagi ...");
                    setTimeout(() => {
                    history.push('/upload_tt?invoiceNumber='+params.get("invoiceNumber"));
                    window.location.reload(true);
                    }, "3000");
            }else{
                setProgress(100);
                setLoading(false);
                setAlertSeverity("success");
                setAlertContent(value.message + ". Refresh dalam 3 detik lagi ...");
                setTimeout(() => {
                history.push('/upload_tt?invoiceNumber='+params.get("invoiceNumber"));
                window.location.reload(true);
                }, "3000");
            }
            });
        
        }
    }
  
    useEffect(() => {
    
      const fetchData = async () => {
          
        const res = await fetch(
          'https://revasamudra.ivantie.com/backend/revasamudra/api/editRentPage/'+params.get("invoiceNumber"), settings
        );
        const json = await res.json();
        console.log(json.data)
        setRentEdit(json.data);
        setInvoiceNumber(json.data[0].invoiceNumber);
        setLinkTt(json.data[0].linkTt);
      };
      fetchData();
  
    },[]);
    
    const style_pembatas = {
      color: "#061C54",
      fontStyle: "bold"
    };

    function handleChangeFile(e) {
        console.log(e.target.files);
        setFile(URL.createObjectURL(e.target.files[0]));
        setLinkTt(e.target.files[0]);
    }

    function resetFile() {
        setFile('');
        document.getElementById('logoResi').value = '';
    }

    function showFile(id){
        console.log(id);
        window.open(id);
    }
  
    const myImageStyle = { width: '200px', height: '250px', border:'5px solid #555' };

  return (
        <div>
            <LoadingBar
                color='#f11946'
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            <Snackbar open={alert} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertContent}
                    </Alert>
            </Snackbar>
            <Header_admin></Header_admin>
            <main id="main" class="main">
                <div class="pagetitle">
                    <h1>Edit Invoice</h1>
                    <br />
                    <nav>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                        <li class="breadcrumb-item active">Edit Invoice</li>
                    </ol>
                    </nav>
                </div>
                <div class="row mb-3 justify-content-center align-items-center">
                    <div class="col-sm-12">
                    {alert ? 
                    <Alert severity={alertSeverity}>
                        <AlertTitle>{alertTitle}</AlertTitle>
                        {alertContent}
                    </Alert> : 
                    <></> 
                    }
                    </div>
                </div>

              <section class="section">
                  <div class="row">
            <div class="col-lg-12">

              <div class="card">
                <div class="card-body">
                    
                    {rentEdit.map(item => (
                        <React.Fragment>
                        <div class="row mb-3 align-items-center">
                            <h4 style={style_pembatas}>Data Invoice</h4>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-6 mb-3">
                                <div class="row">
                                <div class="col-md-4">
                                    <label>No Invoice</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" id="invoiceNumber" defaultValue={invoiceNumber} disabled name="invoiceNumber" onChange={(e) => setInvoiceNumber(e.target.value)} class="form-control" />
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                                <label for="inputText" class="col-sm-2 col-form-label">Ganti Tanda Terima</label>
                                <div class="col-sm-10">
                                <input type="file" class="form-control" name="logoResi" id="logoResi" accept="application/pdf" onChange={handleChangeFile} />
                                </div>
                                
                            </div>
                            <div class="row mb-3">
                                <label for="inputText" class="col-sm-2 col-form-label">Tanda Terima</label>
                                <div class="col-sm-3">
                                {item.linkTt === null ? (
                                    'Belum Ada Tanda Terima'
                                ):(
                                    <button type="button"class="btn btn-dark" onClick={() => {
                                            window.open(item.linkTt, "Popup", "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30")
                                        }}>Lihat
                                    </button>
                                )}
                                </div>
                                {/* <div class="col-sm-3">
                                    {
                                        file ? <button type="button"class="btn btn-dark" onClick={resetFile}>Reset</button>: ''
                                    }
                                
                                </div> */}
                            </div>
                        </React.Fragment>
                    ))}
                    
                    <div class="row mb-3 justify-content-center align-items-center">
                        <div class="col-sm-12">
                        <button className="button_simpan" disabled={loading} onClick={simpan}>
                            {loading ? (
                            <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                            />
                            ):
                            (<i
                            className="fa fa-save"
                            style={{ marginRight: "5px" }}
                            />)} &nbsp;
                            {loading && <span>Harap Tunggu ...</span>}
                            {!loading && <span>Simpan</span>}
                        </button>
                        </div>
                    </div>
                </div>
              </div>

            </div>
          </div>
                  </section>

            </main>

            <Footer_admin></Footer_admin>

            
        </div>
  );
}

export default Upload_rent;