import React, {useEffect, useState} from 'react';
import Select from "react-select";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import TableRows from "./TableRows";
import { Link } from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import { useHistory } from 'react-router';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Snackbar from "@material-ui/core/Snackbar";

import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';

import { create_order_recap } from '../../../Context/actions';

import LoadingBar from 'react-top-loading-bar';


function Add_order() {
    const history = useHistory();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const [dateOrderRecap, setDateOrderRecap] = useState();
    const [containerSize, setContainerSize] = useState();
    const [containerNumber, setContainerNumber] = useState();
    const [pocketMoney, setPocketMoney] = useState();
    const [coolieMoney, setCoolieMoney] = useState();
    const [otherMoney, setOtherMoney] = useState();
    const [billMoney, setBillMoney] = useState();
    const [note, setNote] = useState();
    const [downpaymentMoney, setDownpaymentMoney] = useState();
    const [harborMoney, setHarborMoney] = useState();
    const [stapelMoney, setStapelMoney] = useState();
    const [depoMoney, setDepoMoney] = useState();
    const [stapelPocketMoney, setStapelPocketMoney] = useState();
    const [overtimeMoney, setOverTimeMoney] = useState();
    const [from, setFrom] = useState();
    const [destination, setDestination] = useState();
    const [harbor, setHarbor] = useState();
    const [depo, setDepo] = useState();

    const [idCustomer, setIdCustomer] = useState([]);
    const [selectCustomer, setSelectCustomer] = useState([]);
    const [idFleet, setIdFleet] = useState([]);
    const [selectFleet, setSelectFleet] = useState([]);
    const [idDriver, setIdDriver] = useState([]);
    const [selectDriver, setSelectDriver] = useState([]);
    const [idFrom, setIdFrom] = useState([]);
    const [selectFrom, setSelectFrom] = useState([]);
    const [suggestFrom,setSuggestFrom] = React.useState([])
    const [idDestination, setIdDestination] = useState([]);
    const [selectDestination, setSelectDestination] = useState([]);
    const [suggestDestination,setSuggestDestination] = React.useState([])
    const [idDeliveryDestination, setIdDeliveryDestination] = useState([]);
    const [selectDeliveryDestination, setSelectDeliveryDestination] = useState([]);
    const [idHarbor, setIdHarbor] = useState([]);
    const [selectHarbor, setSelectHarbor] = useState([]);
    const [suggestHarbor,setSuggestHarbor] = React.useState([])
    const [idDepo, setIdDepo] = useState([]);
    const [selectDepo, setSelectDepo] = useState([]);
    const [suggestDepo,setSuggestDepo] = React.useState([])
    const [idActivity, setIdActivity] = useState([]);
    const [selectActivity, setSelectActivity] = useState([]);

    const [alert, setAlert] = useState(false);
    const [alertContent, setAlertContent] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');

    const [rowsData, setRowsData] = useState([]);

    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'usercode': localStorage.getItem('username'),
            'token': localStorage.getItem('token'),
        }
    };

   

    //search customer
    useEffect(() => {
      setAlert(true);
      setAlertContent("Load data customer ...");
      fetch("https://revasamudra.ivantie.com/backend/revasamudra/api/searchCustomer",settings)
      .then((res) => res.json())
      .then((data) => {
        const dataCustomer = data.data.map((item) => ({
          label: item.customerName,
          value: item.customerCode
        }));
        setSelectCustomer(dataCustomer);
        setAlert(false);

        //search armada
        setAlert(true);
        setAlertContent("Load data armada ...");
        fetch("https://revasamudra.ivantie.com/backend/revasamudra/api/searchFleet",settings)
        .then((res) => res.json())
        .then((data) => {
          const dataFleet = data.data.map((item) => ({
            label: item.numberPlate + "(" + item.ownership + ")",
            value: item.numberPlate
          }));
          setSelectFleet(dataFleet);
          setAlert(false);

          //search sopir
          setAlert(true);
          setAlertContent("Load data sopir ...");
          fetch("https://revasamudra.ivantie.com/backend/revasamudra/api/searchDriver",settings)
          .then((res) => res.json())
          .then((data) => {
            const dataDriver = data.data.map((item) => ({
              label: item.driverName,
              value: item.driverCode
            }));
            setSelectDriver(dataDriver);
            setAlert(false);

            //search dari
            setAlert(true);
            setAlertContent("Load data dari ...");
            fetch("https://revasamudra.ivantie.com/backend/revasamudra/api/searchFrom",settings)
            .then((res) => res.json())
            .then((data) => {
              let arrayFrom = []
              arrayFrom = data.data.map(function(obj){
                return obj.from
              })
              setSuggestFrom([...arrayFrom])
              setAlert(false);

              //search tujuan
              setAlert(true);
              setAlertContent("Load data tujuan ...");
              fetch("https://revasamudra.ivantie.com/backend/revasamudra/api/searchDestination",settings)
              .then((res) => res.json())
              .then((data) => {
                let arrayDestination = []
                arrayDestination = data.data.map(function(obj){
                  return obj.destination
                })
                setSuggestDestination([...arrayDestination])
                setAlert(false);

                //search pabrik
                setAlert(true);
                setAlertContent("Load data pabrik ...");
                fetch("https://revasamudra.ivantie.com/backend/revasamudra/api/searchDeliveryDestination",settings)
                .then((res) => res.json())
                .then((data) => {
                  const dataDeliveryDestination = data.data.map((item) => ({
                    label: item.deliveryDestinationName,
                    value: item.deliveryDestinationCode
                  }));
                  setSelectDeliveryDestination(dataDeliveryDestination);
                  setAlert(false);

                  //search pelabuhan
                  setAlert(true);
                  setAlertContent("Load data pelabuhan ...");
                  fetch("https://revasamudra.ivantie.com/backend/revasamudra/api/searchHarbor",settings)
                  .then((res) => res.json())
                  .then((data) => {
                    let arrayHarbor = []
                    arrayHarbor = data.data.map(function(obj){
                      return obj.harbor
                    })
                    setSuggestHarbor([...arrayHarbor])
                    setAlert(false);

                    //search depo
                    setAlert(true);
                    setAlertContent("Load data depo ...");
                    fetch("https://revasamudra.ivantie.com/backend/revasamudra/api/searchDepo",settings)
                    .then((res) => res.json())
                    .then((data) => {
                      let arrayDepo = []
                      arrayDepo = data.data.map(function(obj){
                        return obj.depo
                      })
                      setSuggestDepo([...arrayDepo])
                      setAlert(false);
                    });
                  });
                });
              });
            });
          });
        });

      });
    },[]);

    useEffect(() => {
      let activity = [{
          label: 'EKSPORT',
          value: 'EKSPORT'
        },{
          label: 'IMPORT',
          value: 'IMPORT'
        },{
          label: 'LOKAL FULL',
          value: 'LOKAL FULL'
        },{
          label: 'LOKAL EMPTY',
          value: 'LOKAL EMPTY'
        },{
          label: 'ROUNDUSE',
          value: 'ROUNDUSE'
        },{
          label: 'DROP EMPTY',
          value: 'DROP EMPTY'
        },{
          label: 'DROP FULL',
          value: 'DROP FULL'
        }];

        setSelectActivity(activity)
    },[]);


    function simpan(){

      setLoading(true);

      setProgress(progress + 40)
      setLoading(true)

      setAlert(true);
      setAlertContent("Proses Simpan ...")

      console.log(dateOrderRecap,' | ',idCustomer.idCustomer.value,' | ',idCustomer.idCustomer.label,' | ',idFleet.idFleet.value,' | ',idDriver.idDriver.value,' | ',idDriver.idDriver.label,' | ',from,' | ',destination,' | ',idActivity.idActivity.value,' | ',containerSize,' | ',idDeliveryDestination.idDeliveryDestination.value,' | ',idDeliveryDestination.idDeliveryDestination.label,' | ',containerNumber,' | ',pocketMoney,' | ',coolieMoney,' | ',otherMoney,' | ',billMoney,' | ',note,' | ',downpaymentMoney,' | ',harbor,' | ',harborMoney,' | ',stapelMoney,' | ',depo,' | ',depoMoney,' | ',stapelPocketMoney,' | ',overtimeMoney);

      if(dateOrderRecap == undefined){
        setProgress(100);
        setLoading(false);
        setAlertSeverity("error");
        setAlertContent("Harap isi Tanggal Rekap");
        setTimeout(() => {
          setAlert(false);
        }, "3000");
      }else{
        const promise1 = Promise.resolve(create_order_recap(dateOrderRecap,idCustomer.idCustomer.value,idCustomer.idCustomer.label,idFleet.idFleet.value,idDriver.idDriver.value,idDriver.idDriver.label,from,destination,idActivity.idActivity.value,containerSize,idDeliveryDestination.idDeliveryDestination.value,idDeliveryDestination.idDeliveryDestination.label,containerNumber,pocketMoney,coolieMoney,otherMoney,billMoney,note,downpaymentMoney,harbor,harborMoney,stapelMoney,depo,depoMoney,stapelPocketMoney,overtimeMoney));
        promise1.then((value) => {
          console.log(value.hasil);
          if(value.hasil == '1'){
              //sukses
              setProgress(100);
              setLoading(false);
              setAlertSeverity("success");
              setAlertContent(value.message + ". Refresh dalam 3 detik lagi ...");
              setTimeout(() => {
                history.push('/Order_recap');
                window.location.reload(true);
              }, "3000");
          }else if(value.hasil == '2'){
              setProgress(100);
              setLoading(false);
              setAlertSeverity("success");
              setAlertContent(value.message + ". Refresh dalam 3 detik lagi ...");
              setTimeout(() => {
                history.push('/Order_recap');
                window.location.reload(true);
              }, "3000");
          }
        });
      }

     

     
    }
   

    

    const style_pembatas = {
      color: "#061C54",
      fontStyle: "bold"
     };

     const form_order = {
      color: "#061C54",
      fontStyle: "bold"
     };

    const handleChangeCustomer = (idCustomer) => {
      setIdCustomer({ idCustomer });
    }
  
    const handleChangeFleet = (idFleet) => {
      setIdFleet({ idFleet });
    }
     
    const handleChangeDriver = (idDriver) => {
      setIdDriver({ idDriver });
    }

    const handleChangeFrom = (idFrom) => {
      setIdFrom({ idFrom });
    }

    const handleChangeDestination = (idDestination) => {
      setIdDestination({ idDestination });
    }

    const handleChangeDeliveryDestination = (idDeliveryDestination) => {
      setIdDeliveryDestination({ idDeliveryDestination });
    }

    const handleChangeHarbor = (idHarbor) => {
      setIdHarbor({ idHarbor });
    }

    const handleChangeDepo = (idDepo) => {
      setIdDepo({ idDepo });
    }

    const handleChangeActivity = (idActivity) => {
      setIdActivity({ idActivity });
    }

  return (
      <div>
          <LoadingBar
            color='#f11946'
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
          />
          <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%' }}>
                  {alertContent}
                </Alert>
          </Snackbar>
          <Header_admin></Header_admin>
          <main id="main" class="main">

            <div class="pagetitle">
              <h1>Buat Rekap Order</h1>
              <br />
              <nav>
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                  <li class="breadcrumb-item active">Rekap Order</li>
                </ol>
              </nav>
            </div>
            <div class="row mb-3 justify-content-center align-items-center">
              <div class="col-sm-12">
                {alert ? 
                <Alert severity={alertSeverity}>
                  <AlertTitle>Mohon ditunggu</AlertTitle>
                  {alertContent}
                </Alert> : 
                <></> 
                }
              </div>
            </div>

            <section class="section">
            <div class="row">
      <div class="col-lg-12">

        <div class="card">
          <div class="card-body"> 
              <div class="row mb-3">
                  <div class="col-md-6 mb-3">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Tanggal Rekap</label>
                      </div>
                      <div class="col-md-8">
                        <input type="date" id="dateOrderRecap" name="dateOrderRecap" onChange={(e) => setDateOrderRecap(e.target.value)} class="form-control" required />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Plat Nomor</label>
                        </div>
                        <div class="col-md-8">
                          <div style={{width: '100%'}}>
                          <Select
                              options={selectFleet}
                              onChange={handleChangeFleet}
                          />
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Customer</label>
                        </div>
                        <div class="col-md-8">
                          <div style={{width: '100%'}}>
                          <Select
                              options={selectCustomer}
                              onChange={handleChangeCustomer}
                          />
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Sopir</label>
                        </div>
                        <div class="col-md-8">
                          <div style={{width: '100%'}}>
                          <Select
                              options={selectDriver}
                              onChange={handleChangeDriver}
                          />
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Ukuran</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" id="containerSize" name="containerSize" onChange={(e) => setContainerSize(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Kegiatan</label>
                        </div>
                        <div class="col-md-8">
                          <div style={{width: '100%'}}>
                          <Select
                              options={selectActivity}
                              onChange={handleChangeActivity}
                          />
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Nomor Kontainer</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" id="containerNumber" name="containerNumber" onChange={(e) => setContainerNumber(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Dari</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" class="form-control" name='from' onChange={(e) => setFrom(e.target.value)} list='suggestion_from'></input>
                          <datalist id='suggestion_from'>
                            {suggestFrom.map((make,index) =>{
                              return (<option key={index} value={make}>{make}</option>)
                            })}
                          </datalist>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Tujuan</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" class="form-control" name='destination' onChange={(e) => setDestination(e.target.value)} list='suggestion_destination'></input>
                          <datalist id='suggestion_destination'>
                            {suggestDestination.map((make,index) =>{
                              return (<option key={index} value={make}>{make}</option>)
                            })}
                          </datalist>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Pabrik</label>
                        </div>
                        <div class="col-md-8">
                          <div style={{width: '100%'}}>
                          <Select
                              options={selectDeliveryDestination}
                              onChange={handleChangeDeliveryDestination}
                          />
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Saku</label>
                        </div>
                        <div class="col-md-8">
                          <input type="number" id="pocketMoney" name="pocketMoney" onChange={(e) => setPocketMoney(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Kuli</label>
                        </div>
                        <div class="col-md-8">
                          <input type="number" id="coolieMoney" name="coolieMoney" onChange={(e) => setCoolieMoney(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Lain - Lain</label>
                        </div>
                        <div class="col-md-8">
                          <input type="number" id="otherMoney" name="otherMoney" onChange={(e) => setOtherMoney(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Bon (Rp)</label>
                        </div>
                        <div class="col-md-8">
                          <input type="number" id="billMoney" name="billMoney" onChange={(e) => setBillMoney(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Keterangan</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" id="note" name="note" onChange={(e) => setNote(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Pelabuhan</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" class="form-control" name='harbor' onChange={(e) => setHarbor(e.target.value)} list='suggestion_harbor'></input>
                          <datalist id='suggestion_harbor'>
                            {suggestHarbor.map((make,index) =>{
                              return (<option key={index} value={make}>{make}</option>)
                            })}
                          </datalist>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Nominal (Pelabuhan)</label>
                        </div>
                        <div class="col-md-8">
                          <input type="number" id="harborMoney" name="harborMoney" onChange={(e) => setHarborMoney(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Depo</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" class="form-control" name='depo' onChange={(e) => setDepo(e.target.value)} list='suggestion_depo'></input>
                          <datalist id='suggestion_depo'>
                            {suggestDepo.map((make,index) =>{
                              return (<option key={index} value={make}>{make}</option>)
                            })}
                          </datalist>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Nominal (Depo)</label>
                        </div>
                        <div class="col-md-8">
                          <input type="number" id="depoMoney" name="depoMoney" onChange={(e) => setDepoMoney(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Stapel</label>
                        </div>
                        <div class="col-md-8">
                          <input type="text" id="stapelMoney" name="stapelMoney" onChange={(e) => setStapelMoney(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-3">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Ekstra</label>
                        </div>
                        <div class="col-md-8">
                          <input type="number" id="stapelPocketMoney" name="stapelPocketMoney" onChange={(e) => setStapelPocketMoney(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>Lemburan</label>
                        </div>
                        <div class="col-md-8">
                          <input type="number" id="overtimeMoney" name="overtimeMoney" onChange={(e) => setOverTimeMoney(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-4">
                        <label>DP (Isi jika ada DP Masuk)</label>
                        </div>
                        <div class="col-md-8">
                          <input type="number" id="downpaymentMoney" name="downpaymentMoney" onChange={(e) => setDownpaymentMoney(e.target.value)} class="form-control" />
                        </div>
                      </div>
                  </div>
              </div>
              <div class="row mb-3 justify-content-center align-items-center">
                <div class="col-sm-12">
                  <button className="button_simpan" disabled={loading} onClick={simpan}>
                    {loading ? (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                    ):
                    (<i
                      className="fa fa-save"
                      style={{ marginRight: "5px" }}
                    />)} &nbsp;
                    {loading && <span>Harap Tunggu ...</span>}
                    {!loading && <span>Simpan</span>}
                  </button>
                </div>
              </div>
              
          </div>
        </div>

      </div>
    </div>
            </section>

          </main>
          
          <Footer_admin></Footer_admin>

          
      </div>
  );
}

export default Add_order;