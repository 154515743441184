import React, {useEffect, useState} from 'react';
import { useLocation, Link } from "react-router-dom";
import { useHistory } from 'react-router';
import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';
import LoadingBar from 'react-top-loading-bar';

import { edit_item } from '../../../Context/actions';
import Select from "react-select";


function Edit_item() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const [itemEdit, setItemEdit] = useState([]);
    
    const [itemCode, setItemCode] = useState();
    const [itemName, setItemName] = useState();
    const [itemUnitName, setItemUnitName] = useState();
    const [itemStock, setItemStock] = useState();
    const [itemCategory, setItemCategory] = useState();
    const [itemBrand, setItemBrand] = useState();

    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const history = useHistory();


    useEffect(() => {
      setProgress(progress + 40)
      const settings = {
          method: 'GET',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'usercode': localStorage.getItem('username'),
              'token': localStorage.getItem('token'),
          }
      };

        const fetchData = async () => {
            
          const res = await fetch(
            'https://revasamudra.ivantie.com/backend/revasamudra/api/editItemPage/'+params.get("id"), settings
          );
          setProgress(100)
          const json = await res.json();
          setItemEdit(json.data);
          setItemCode(json.data[0].itemCode);
          setItemName(json.data[0].itemName);
          setItemUnitName(json.data[0].itemUnitName);
          setItemStock(json.data[0].itemStock);
          setItemCategory(json.data[0].itemCategory);
          setItemBrand(json.data[0].itemBrand);
        };
        fetchData();

        // priceEdit.map(item => (
        //   console.log("Test")
        // ))
    },[]);

    const handleonSubmit = async e => {
    e.preventDefault();

      const promise_login = Promise.resolve(edit_item(params.get("id"),itemCode,itemName,itemUnitName,itemStock,itemCategory,itemBrand));
      setLoading(true)
      setProgress(progress + 40)
      promise_login.then((value) => {
          if(value.hasil == '1'){
              //sukses
              setLoading(false)
              setProgress(100)
              alert(value.message);
              history.push('/item');
              window.location.reload(true);
          }else if(value.hasil == '2'){
              //username password salah
              setLoading(false)
              setProgress(100)
              alert(value.message);
              history.push('/item');
              window.location.reload(true);
          }
      });
      
    }

  return (
 
        <div>
            <LoadingBar
              color='#f11946'
              progress={progress}
              onLoaderFinished={() => setProgress(0)}
            />
            <Header_admin></Header_admin>
            <main id="main" class="main">

              <div class="pagetitle">
                <h1>Ubah Barang</h1>
                <nav>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li class="breadcrumb-item"><Link to="/item">Barang</Link></li>
                    <li class="breadcrumb-item active">Ubah Barang</li>
                  </ol>
                </nav>
              </div>

              <section class="section">
                <div class="row">
                  <div class="col-lg-12">

                    <div class="card">
                      <div class="card-body">
                            <form onSubmit={handleonSubmit}>
                            {itemEdit.map(item => (
                                <React.Fragment>
                                  <div class="row mb-12">
                                    <label for="inputText" class="col-sm-2 col-form-label">Kode</label>
                                    <div class="col-sm-10">
                                    <input type="text" class="form-control" name="itemCode" id="itemCode" defaultValue={item.itemCode} value={itemCode} onChange={(e) => setItemCode(e.target.value)} disabled="true" />
                                    </div>
                                  </div>
                                  <br />
                                  <div class="row mb-12">
                                    <label for="inputText" class="col-sm-2 col-form-label">Barang</label>
                                    <div class="col-sm-10">
                                    <input type="text" class="form-control" name="itemName" id="itemName" defaultValue={item.itemName} value={itemName} onChange={(e) => setItemName(e.target.value)} />
                                    </div>
                                  </div>
                                  <br />
                                  <div class="row mb-12">
                                    <label for="inputText" class="col-sm-2 col-form-label">Satuan</label>
                                    <div class="col-sm-10">
                                    <input type="text" class="form-control" name="itemUnitName" id="itemUnitName" defaultValue={item.itemUnitName} value={itemUnitName} onChange={(e) => setItemUnitName(e.target.value)} />
                                    </div>
                                  </div>
                                  <br />
                                  <div class="row mb-12">
                                    <label for="inputText" class="col-sm-2 col-form-label">Katagori</label>
                                    <div class="col-sm-10">
                                    <input type="text" class="form-control" name="itemCategory" id="itemCategory" defaultValue={item.itemCategory} value={itemCategory} onChange={(e) => setItemCategory(e.target.value)} />
                                    </div>
                                  </div>
                                  <br />
                                  <div class="row mb-12">
                                    <label for="inputText" class="col-sm-2 col-form-label">Merk</label>
                                    <div class="col-sm-10">
                                    <input type="text" class="form-control" name="itemBrand" id="itemBrand" defaultValue={item.itemBrand} value={itemBrand} onChange={(e) => setItemBrand(e.target.value)} />
                                    </div>
                                  </div>
                                </React.Fragment>
                            ))}
                            
                            <br />
                            <div class="row mb-12">
                                <div class="col-sm-12">
                                    <button type="submit" className="button_simpan" disabled={loading}>
                                      {loading ? (
                                        <i
                                          className="fa fa-refresh fa-spin"
                                          style={{ marginRight: "5px" }}
                                        />
                                      ):
                                      (<i
                                        className="fa fa-save"
                                        style={{ marginRight: "5px" }}
                                      />)} &nbsp;
                                      {loading && <span>Harap Tunggu ...</span>}
                                      {!loading && <span>Simpan</span>}
                                    </button>
                                </div>
                            </div>

                            </form>

                      </div>
                    </div>

                  </div>
                </div>
              </section>

            </main>
            <Footer_admin></Footer_admin>
        </div>
        
  );
}

export default Edit_item;