import React, {useEffect, useState, useRef } from 'react';
import ReactPaginate from "react-paginate";
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { delete_item } from '../../../Context/actions';
import Select from "react-select";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';

import LoadingBar from 'react-top-loading-bar';


import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

function Search_price() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [idCustomer, setIdCustomer] = useState([]);
  const [selectCustomer, setSelectCustomer] = useState([]);
  const [fromDate, setFromDate] = useState(['2000-01-01']);
  const [untilDate, setUntilDate] = useState(['2000-01-01']);

  const [loading, setLoading] = useState(false);
  const [loadingCari, setLoadingCari] = useState(false);
  const [loadingResetPencarian, setLoadingResetPencarian] = useState(false);
  const [progress, setProgress] = useState(0)

  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('info');
  const [alertTitle, setAlertTitle] = useState('Mohon Ditunggu');

  const history = useHistory();

  const [item, setItem] = useState([]);

  const tableCustomStyles = {
    headRow: {
      style: {
        color:'#223336',
        backgroundColor: '#e7eef0'
      },
    },
    rows: {
      style: {
        color: "STRIPEDCOLOR",
        backgroundColor: "STRIPEDCOLOR"
      },
      stripedStyle: {
        color: "NORMALCOLOR",
        backgroundColor: "NORMALCOLOR"
      }
    }
  }

  const settings = {
      method: 'GET',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'usercode': localStorage.getItem('username'),
          'token': localStorage.getItem('token'),
      }
  };

  const handleChangeCustomer = (idCustomer) => {
      setIdCustomer({ idCustomer });
  }

  useEffect(() => {
    //search armada
    setAlert(true);
    setAlertSeverity("info");
    setAlertTitle("Mohon Ditunggu");
    setAlertContent("Load data customer ...");
    fetch("https://revasamudra.ivantie.com/backend/revasamudra/api/searchCustomer",settings)
    .then((res) => res.json())
    .then((data) => {
        const dataFleet = data.data.map((item) => ({
        label: item.customerName,
        value: item.customerCode
        }));
        setSelectCustomer(dataFleet);
        setAlert(false);

    });
},[]);

  function searchData(){
    setProgress(progress + 40)
    setLoading(true)
    setAlert(true);
    setAlertSeverity("info");
    setAlertContent("Proses ambil data ...");
    let customerPar;
    if(idCustomer.length !== 0){
      customerPar = idCustomer.idCustomer.value;
    }else{
      customerPar = '0';
    }

    const fetchData = async () => { 
      const res = await fetch(
          'https://revasamudra.ivantie.com/backend/revasamudra/api/searchPriceNew/'+customerPar,settings
      );
      const json = await res.json();
      setProgress(100);
      setLoading(false);
      setAlertSeverity("success");
      setAlertTitle("Berhasil");
      setAlertContent("Data Berhasil di ambil");
      setItem(json);
      setTimeout(() => {
        setAlert(false)
      }, "3000");
      
      };
      fetchData(); 
  };


  return (
      <div>
        <LoadingBar
          color='#f11946'
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        /> 
        <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%' }}>
                  {alertContent}
                </Alert>
        </Snackbar>
        <Header_admin></Header_admin>
        <main id="main" class="main">

          <div class="pagetitle">
            <h1>Pencarian Harga</h1>
            <nav>
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li class="breadcrumb-item active">Pencarian</li>
              </ol>
            </nav>
          </div>

          <section class="section">
              {alert ? 
              <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-md-12">
                        <Alert severity={alertSeverity}>
                          <AlertTitle>{alertTitle}</AlertTitle>
                          {alertContent}
                        </Alert>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
               : 
              <></> 
              }
          <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row mb-3">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-8">
                                <label class="mb-1">Customer</label>
                                <div style={{width: '100%'}}>
                                <Select
                                    options={selectCustomer}
                                    onChange={handleChangeCustomer}
                                />
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    <div class="row mb-3">
                        <div class="col-md-6">
                          <div class="row">
                              <div class="col-md-8">
                                  <button type="button" onClick={searchData} className="button_cari">
                                      <span>Lihat Data</span>
                                  </button>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
              <div class="card">
                {/* <div class="table-responsive">
                  <table id="example" class="table table-hover table-bordered">
                    <thead>
                      <tr>
                        <th>Tujuan</th>
                        <th>Saku</th>
                        <th>Harga</th>
                        <th>Customer</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      item != 'Data Tidak Tersedia' ?
                      item.map(item => (
                        
                          <tr>
                            <td>{item.destination}</td>
                            <td>{item.pocketMoney.toLocaleString()}</td>
                            <td>{item.priceCustomer.toLocaleString()}</td>
                            <td>{item.customerName}</td>
                          </tr>
                        
                        ))
                        :
                        <tr>
                          <td colSpan={2}>Data Tidak Tersedia</td>
                        </tr>
                    }
                      
                      
                    </tbody>
                  </table>
                </div> */}
                <DataTableExtensions
                    {...item}
                  >
                  <DataTable
                      noHeader
                      defaultSortField="id"
                      defaultSortAsc={false}
                      pagination
                      highlightOnHover
                      customStyles={tableCustomStyles}
                    />
                </DataTableExtensions>
              </div>
              </div>
            </div>
          </section>

        </main>

        <Footer_admin></Footer_admin>

      </div>
  );
}

export default Search_price;