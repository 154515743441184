import React, {useEffect, useState} from 'react';
import { useLocation, Link } from "react-router-dom";
import { useHistory } from 'react-router';
import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';
import Sidebar_admin from '../../../Layout/Sidebar_admin';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import { pay_rent } from '../../../Context/actions';
import LoadingBar from 'react-top-loading-bar';

import 'jquery/dist/jquery.min.js';

import $ from 'jquery'; 
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"

function Pay_rent() {

    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [invoiceNumber, setInvoiceNumber] = useState();
    const [totalPrice, setTotalPrice] = useState();
    const [payPrice, setPayPrice] = useState();
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const [alert, setAlert] = useState(false);
    const [alertContent, setAlertContent] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');

    const history = useHistory();

    function isEmpty(value) {
      return (value == null || (typeof value === "string" && value.trim().length === 0));
    }

    function currencyFormat(num) {
      return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    useEffect(() => {
      if(isEmpty(params.get("invoiceNumber"))){
        setInvoiceNumber('');
        setTotalPrice(0);
      }else{
        setInvoiceNumber(params.get("invoiceNumber"));
        setTotalPrice(params.get("totalPrice"));
      }
    },[]);

    const handleonSubmit = async e => {
    e.preventDefault();
        setLoading(true)
        setProgress(progress + 40)

        setAlert(true);
        setAlertContent("Proses Simpan ...")

        const promise_login = Promise.resolve(pay_rent(invoiceNumber,payPrice));

        promise_login.then((value) => {
            if(value.hasil == '1'){
                setProgress(100);
                setLoading(false);
                setAlertSeverity("success");
                setAlertContent(value.message + ". Refresh dalam 3 detik lagi ...");
                setTimeout(() => {
                    history.push('/pay_rent');
                    window.location.reload(true);
                }, "3000");
            }else if(value.hasil == '2'){
                setProgress(100);
                setLoading(false);
                setAlertSeverity("error");
                setAlertContent(value.message);
            }else if(value.hasil == '3'){
                setProgress(100);
                setLoading(false);
                setAlertSeverity("error");
                setAlertContent(value.message);
            }
        });
    }

  return (
 
        <div>
            <LoadingBar
              color='#f11946'
              progress={progress}
              onLoaderFinished={() => setProgress(0)}
            /> 
            <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%' }}>
                      {alertContent}
                    </Alert>
            </Snackbar>
            <Header_admin></Header_admin>
            <main id="main" class="main">

              <div class="pagetitle">
                <h1>Pembayaran Invoice</h1>
                <nav>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li class="breadcrumb-item active">Pembayaran</li>
                  </ol>
                </nav>
              </div>

              <section class="section">
                <div class="row">
                  <div class="col-lg-12">

                    <div class="card">
                      <div class="card-body">
                            <form onSubmit={handleonSubmit}>
                            <div class="row mb-3">
                                <label for="inputText" class="col-sm-2 col-form-label">No Invoice</label>
                                <div class="col-sm-10">
                                <input type="text" class="form-control" name="invoiceNumber" id="invoiceNumber" onChange={(e) => setInvoiceNumber(e.target.value)} defaultValue={invoiceNumber} value={invoiceNumber} />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="inputText" class="col-sm-2 col-form-label">Total Tagihan</label>
                                <div class="col-sm-10">
                                <input type="text" class="form-control" name="tagihan" id="tagihan" defaultValue={totalPrice} value={totalPrice} disabled="true" />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="inputText" class="col-sm-2 col-form-label">Jumlah Bayar</label>
                                <div class="col-sm-10">
                                <input type="text" class="form-control" name="payPrice" id="payPrice" onChange={(e) => setPayPrice(e.target.value)} />
                                </div>
                            </div>

                            <div class="row mb-3">
                              <label for="inputText" class="col-sm-2 col-form-label"></label>
                                <div class="col-sm-10">
                                    <button type="submit" className="button_simpan" disabled={loading}>
                                      {loading ? (
                                        <i
                                          className="fa fa-refresh fa-spin"
                                          style={{ marginRight: "5px" }}
                                        />
                                      ):
                                      (<i
                                        className="fa fa-save"
                                        style={{ marginRight: "5px" }}
                                      />)} &nbsp;
                                      {loading && <span>Harap Tunggu ...</span>}
                                      {!loading && <span>Simpan</span>}
                                    </button>
                                </div>
                            </div>

                            </form>

                      </div>
                    </div>

                  </div>
                </div>
              </section>

            </main>
            <Footer_admin></Footer_admin>
        </div>
        
  );
}

export default Pay_rent;