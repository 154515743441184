import React, { useState } from "react";
import { render } from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import Experiment from "./Pages/Experiment/Exp";

import Comingsoon from "./Pages/Home/Comingsoon";
import Home from "./Pages/Home/Home";
import Tracking from "./Pages/Tracking/Tracking";
import Cektarif from "./Pages/Cektarif/Cektarif";
import Portofolio from "./Pages/About/Portofolio";
import Services from "./Pages/About/Services";
import Superiority from "./Pages/About/Superiority";
import Termsandcondition from "./Pages/About/Termsandcondition";
import Contact from "./Pages/Contact/Contact";

import Dashboard_admin from "./Pages/Admin/Dashboard/Dashboard";
import Users_admin from "./Pages/Admin/Users/Users";
import Edit_users_admin from "./Pages/Admin/Users/Edit_users";
import Edit_profile_admin from "./Pages/Admin/Profile/Edit_profile";
import Add_users_admin from "./Pages/Admin/Users/Add_users";

//customer
import Customer_admin from "./Pages/Admin/Customer/Customer";
import Add_customer_admin from "./Pages/Admin/Customer/Add_customer";
import Edit_customer_admin from "./Pages/Admin/Customer/Edit_customer";
//fleet
import Fleet_admin from "./Pages/Admin/Fleet/Fleet";
import Add_fleet_admin from "./Pages/Admin/Fleet/Add_fleet";
import Edit_fleet_admin from "./Pages/Admin/Fleet/Edit_fleet";
//driver
import Driver_admin from "./Pages/Admin/Driver/Driver";
import Add_driver_admin from "./Pages/Admin/Driver/Add_driver";
import Edit_driver_admin from "./Pages/Admin/Driver/Edit_driver";
//item
import Item_admin from "./Pages/Admin/Item/Item";
import Add_item_admin from "./Pages/Admin/Item/Add_item";
import Edit_item_admin from "./Pages/Admin/Item/Edit_item";
//supplier
import Supplier_admin from "./Pages/Admin/Supplier/Supplier";
import Add_supplier_admin from "./Pages/Admin/Supplier/Add_supplier";
import Edit_supplier_admin from "./Pages/Admin/Supplier/Edit_supplier";
//delivery destination
import Delivery_destination_admin from "./Pages/Admin/Delivery_destination/Delivery_destination";
import Add_delivery_destination_admin from "./Pages/Admin/Delivery_destination/Add_delivery_destination";
import Edit_delivery_destination_admin from "./Pages/Admin/Delivery_destination/Edit_delivery_destination";
//order recap
import Order_recap from "./Pages/Admin/Order_recap/Order_recap";
import Add_order_recap from "./Pages/Admin/Order_recap/Add_order_recap";
import Edit_order_recap from "./Pages/Admin/Order_recap/Edit_order_recap";
//search price
import Search_price from "./Pages/Admin/Search_price/Search_price";
//rent
import Rent from "./Pages/Admin/Rent/Rent";
import Add_rent from "./Pages/Admin/Rent/Add_rent";
import Edit_rent from "./Pages/Admin/Rent/Edit_rent";
import Upload_rent from "./Pages/Admin/Rent/Upload_rent";
import Pay_rent from "./Pages/Admin/Pay/Pay_rent";
//use
import Use from "./Pages/Admin/Use/Use";
import Add_use from "./Pages/Admin/Use/Add_use";
import Edit_use from "./Pages/Admin/Use/Edit_use";
//purchase
import Purchase from "./Pages/Admin/Purchase/Purchase";
import Add_purchase from "./Pages/Admin/Purchase/Add_purchase";
import Edit_purchase from "./Pages/Admin/Purchase/Edit_purchase";
//maintanance
import Maintanance from "./Pages/Admin/Maintanance/Maintanance";
import Add_maintanance from "./Pages/Admin/Maintanance/Add_maintanance";
import Edit_maintanance from "./Pages/Admin/Maintanance/Edit_maintanance";
//report
import Driver_report from "./Pages/Admin/Report/driver_report";
import Recap_order_report from "./Pages/Admin/Report/recap_order_report";
import Invoice_report from "./Pages/Admin/Report/invoice_report";
import Invoice_due_date_report from "./Pages/Admin/Report/invoice_due_date_report";
import Profit_report from "./Pages/Admin/Report/profit_report";
import Profit_paid_report from "./Pages/Admin/Report/profit_paid_report";
import Profit_not_paid_report from "./Pages/Admin/Report/profit_not_paid_report";
//view
import Invoice_due_date_view from "./Pages/Admin/View/invoice_due_date_view";

import Profile_admin from "./Pages/Admin/Profile/Profile";
import Changepassword_admin from "./Pages/Admin/Profile/Changepassword";

import Notfound from "./Pages/Notfound/Notfound";
import Admin from "./Pages/Admin/Login/Login";
import {UserContext} from './Context/context';
//import App from "./App";
import reportWebVitals from "./reportWebVitals";
import createBrowserHistory from 'history/createBrowserHistory';
import Edit_order from "./Pages/Admin/Order_recap/Edit_order_recap";





export default function App() {
  const [user, setUser] = useState();
  const history = createBrowserHistory();
    return (
      <div>
        <Router forceRefresh={false} history={history}>
          <div>
            <Switch>
            <UserContext.Provider value={{user,setUser}} >
              <Route
                exact
                path="/"
                render={() => {
                    return (
                      <Redirect to="/" />
                    )
                }}
              />
              <Route exact path="/exp" component={Experiment} />

              <Route exact path="/" component={Comingsoon} />
              <Route exact path="/portofolio" component={Portofolio} />
              <Route exact path="/services" component={Services} />
              <Route exact path="/superiority" component={Superiority} />
              <Route exact path="/termsandcondition" component={Termsandcondition} />
              <Route exact path="/kontak" component={Contact} />
              <Route exact path="/tracking" component={Tracking} />
              <Route exact path="/cektarif" component={Cektarif} />

              <Route forceRefresh={true} exact path="/admin" component={Admin} />
              <Route exact path="/dashboard" component={Dashboard_admin} />
              <Route exact path="/users" component={Users_admin} />
              <Route exact path="/edit_users" component={Edit_users_admin} />
              <Route exact path="/edit_profile" component={Edit_profile_admin} />
              <Route exact path="/add_users" component={Add_users_admin} />
              <Route exact path="/customer" component={Customer_admin} />
              <Route exact path="/add_customer" component={Add_customer_admin} />
              <Route exact path="/edit_customer" component={Edit_customer_admin} />
              <Route exact path="/fleet" component={Fleet_admin} />
              <Route exact path="/add_fleet" component={Add_fleet_admin} />
              <Route exact path="/edit_fleet" component={Edit_fleet_admin} />
              <Route exact path="/driver" component={Driver_admin} />
              <Route exact path="/add_driver" component={Add_driver_admin} />
              <Route exact path="/edit_driver" component={Edit_driver_admin} />
              <Route exact path="/item" component={Item_admin} />
              <Route exact path="/add_item" component={Add_item_admin} />
              <Route exact path="/edit_item" component={Edit_item_admin} />
              <Route exact path="/supplier" component={Supplier_admin} />
              <Route exact path="/add_supplier" component={Add_supplier_admin} />
              <Route exact path="/edit_supplier" component={Edit_supplier_admin} />
              <Route exact path="/delivery_destination" component={Delivery_destination_admin} />
              <Route exact path="/add_delivery_destination" component={Add_delivery_destination_admin} />
              <Route exact path="/edit_delivery_destination" component={Edit_delivery_destination_admin} />
              <Route exact path="/profile" component={Profile_admin} />
              <Route exact path="/changepassword" component={Changepassword_admin} />
              <Route exact path="/order_recap" component={Order_recap} />
              <Route exact path="/add_order_recap" component={Add_order_recap} />
              <Route exact path="/edit_order_recap" component={Edit_order_recap} />
              <Route exact path="/rent" component={Rent} />
              <Route exact path="/add_rent" component={Add_rent} />
              <Route exact path="/edit_rent" component={Edit_rent} />
              <Route exact path="/upload_tt" component={Upload_rent} />
              <Route exact path="/pay_rent" component={Pay_rent} />
              <Route exact path="/use" component={Use} />
              <Route exact path="/add_use" component={Add_use} />
              <Route exact path="/edit_use" component={Edit_use} />
              <Route exact path="/purchase" component={Purchase} />
              <Route exact path="/add_purchase" component={Add_purchase} />
              <Route exact path="/edit_purchase" component={Edit_purchase} />
              <Route exact path="/maintanance" component={Maintanance} />
              <Route exact path="/add_maintanance" component={Add_maintanance} />
              <Route exact path="/edit_maintanance" component={Edit_maintanance} />
              <Route exact path="/search_price" component={Search_price} />
              <Route exact path="/driver_report" component={Driver_report} />
              <Route exact path="/recap_order_report" component={Recap_order_report} />
              <Route exact path="/invoice_report" component={Invoice_report} />
              <Route exact path="/profit_report" component={Profit_report} />
              <Route exact path="/profit_paid_report" component={Profit_paid_report} />
              <Route exact path="/profit_not_paid_report" component={Profit_not_paid_report} />
              <Route exact path="/invoice_due_date_report" component={Invoice_due_date_report} />
              <Route exact path="/invoice_due_date_view" component={Invoice_due_date_view} />
              <Route exact path="/notfound" component={Notfound} />
              </UserContext.Provider>
            </Switch>
          </div>
        </Router>
      </div>
    );
  
}

render(<React.StrictMode><App /></React.StrictMode>, document.getElementById("root"));
reportWebVitals();