import React, {useState,useEffect} from 'react';

import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Pengeluaran from '../../../assets_landingpage/img/pengeluaran.jpg';
import Omset from '../../../assets_landingpage/img/omset.jpg';
import Dp from '../../../assets_landingpage/img/dp.jpg';
import Lunas from '../../../assets_landingpage/img/lunas.jpg';
import Piutang from '../../../assets_landingpage/img/piutang.jpg';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';


function Profit_report() {
  const [pendapatanKotor, setPendapatanKotor] = useState(0);
  const [totalDp, setTotalDp] = useState(0);
  const [sudahDiBayar, setSudahDiBayar] = useState(0);
  const [belumDiBayar, setBelumDiBayar] = useState(0);
  const [pengeluaran, setPengeluaran] = useState(0);
  const history = useHistory();

  const settings = {
      method: 'GET',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'usercode': localStorage.getItem('username'),
          'token': localStorage.getItem('token'),
      }
  };


  useEffect(() => {

    const fetchData = async () => { 
    const res = await fetch(
        'https://revasamudra.ivantie.com/backend/revasamudra/api/profitReport',settings
    );
    const json = await res.json();
    if(json.status == true){
        setPendapatanKotor(json.data.pendapatan_kotor);
        setBelumDiBayar(json.data.belum_dibayar);
        setPengeluaran(json.data.pengeluaran);
        setSudahDiBayar(json.data.sudah_dibayar);
        setTotalDp(json.data.total_dp);
    }else{
        setPendapatanKotor(0);
        setBelumDiBayar(0);
        setPengeluaran(0);
        setSudahDiBayar(0);
        setTotalDp(0);
    }
    
    };
    fetchData(); 
  }, []);

  function lihatSudahBayar(){
    history.push('/profit_paid_report');
  }

  function lihatBelumBayar(){
    history.push('/profit_not_paid_report');
  }

  function currencyFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  return (
 
        
    <div>
    <Header_admin></Header_admin>
    <main id="main" class="main">

      <div class="pagetitle">
        <h1>Dashboard</h1>
        <nav>
          <ol class="breadcrumb">
            <li class="breadcrumb-item active">Home</li>
          </ol>
        </nav>
      </div>

      <section class="section">
        <div class="card">
            <div class="card-body">
            <div class="row">
            <div class="col-lg-4">
                <div style={{margin: '2%'}} class="form-group row">
                <Card sx={{ maxWidth: 345 }}>
                    <CardMedia
                    component="img"
                    height="160px"
                    image={Omset}
                    alt="Omset"
                    />
                    <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        Pendapatan Kotor
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                    <p style={{fontWeight: 'bold',fontSize: '25px',color: 'red'}}>{currencyFormat(pendapatanKotor)}</p> 
                    </Typography>
                    </CardContent>
                    <CardActions>
                    {/* <button type="button" size="small" className="button_edit" onClick={() => lihat()}>Lihat</button> */}
                    </CardActions>
                </Card>
                </div>
            </div>
            <div class="col-lg-4">
            
                <div style={{margin: '2%'}} class="form-group row">
                <Card sx={{ maxWidth: 345 }}>
                    <CardMedia
                    component="img"
                    height="160px"
                    image={Dp}
                    alt="Chevrolet"
                    />
                    <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        Downpayment (DP)
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                    <p style={{fontWeight: 'bold',fontSize: '25px',color: 'red'}}>{currencyFormat(totalDp)}</p> 
                    </Typography>
                    </CardContent>
                    <CardActions>
                    {/* <button type="button" size="small" className="button_edit" onClick={() => lihat()}>Lihat</button> */}
                    </CardActions>
                </Card>
                </div>
            </div>
            <div class="col-lg-4">
            
                <div style={{margin: '2%'}} class="form-group row">
                <Card sx={{ maxWidth: 345 }}>
                    <CardMedia
                    component="img"
                    height="160px"
                    image={Lunas}
                    alt="Chevrolet"
                    />
                    <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        Sudah Dibayar
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                    <p style={{fontWeight: 'bold',fontSize: '25px',color: 'red'}}>{currencyFormat(sudahDiBayar)} <Link to="/profit_paid_report"><i class="bi bi-file-earmark-check" style={{color:'black'}}></i></Link></p> 
                    </Typography>
                    </CardContent>
                    <CardActions>
                    {/* <button type="button" size="small" className="button_edit" onClick={() => lihatSudahBayar()}>Lihat</button> */}
                    </CardActions>
                </Card>
                </div>
            </div>
            <div class="col-lg-4">
            
                <div style={{margin: '2%'}} class="form-group row">
                <Card sx={{ maxWidth: 345 }}>
                    <CardMedia
                    component="img"
                    height="160px"
                    image={Piutang}
                    alt="Chevrolet"
                    />
                    <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        Belum Dibayar
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                    <p style={{fontWeight: 'bold',fontSize: '25px',color: 'red'}}>{currencyFormat(belumDiBayar)} <Link to="/profit_not_paid_report"><i class="bi bi-file-earmark-check" style={{color:'black'}}></i></Link></p> 
                    </Typography>
                    </CardContent>
                    <CardActions>
                    {/* <button type="button" size="small" className="button_edit" onClick={() => lihatBelumBayar()}>Lihat</button> */}
                    </CardActions>
                </Card>
                </div>
            </div>
            <div class="col-lg-4">
            
                <div style={{margin: '2%'}} class="form-group row">
                <Card sx={{ maxWidth: 345 }}>
                    <CardMedia
                    component="img"
                    height="160px"
                    image={Pengeluaran}
                    alt="Chevrolet"
                    />
                    <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        Pengeluaran
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                    <p style={{fontWeight: 'bold',fontSize: '25px',color: 'red'}}>{currencyFormat(pengeluaran)}</p> 
                    </Typography>
                    </CardContent>
                    <CardActions>
                    {/* <button type="button" size="small" className="button_edit" onClick={() => lihat()}>Lihat</button> */}
                    </CardActions>
                </Card>
                </div>
            </div>
            </div>
            </div>
        </div>
      </section>

    </main>
    <Footer_admin></Footer_admin>
    </div>
        
  );
}

export default Profit_report;