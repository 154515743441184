import React, { useState, useContext } from 'react';
import ClientCaptcha from "react-client-captcha";
import "react-client-captcha/dist/index.css";
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import { UserContext } from '../../../Context/context'; 
import { login } from '../../../Context/actions';
import LoadingBar from 'react-top-loading-bar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Snackbar from "@material-ui/core/Snackbar";

export default function Login() {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [code, setCode] = useState("0");
    const [captchaCode, setCaptchaCode] = useState("kosong");

    const [alert, setAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertContent, setAlertContent] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');

    const handleSubmit = async e => {
        e.preventDefault();

        if(captchaCode == 'kosong'){
          alert("Mohon masukkan captcha terlebih dahulu.");
        }else{
          if(captchaCode === code){
            setLoading(true);
            setProgress(progress + 40)
            //console.log(login(username,password));
            const promise_login = Promise.resolve(login(username,password));
    
            promise_login.then((value) => {
                if(value.hasil == '1'){
                    //sukses
                    setLoading(false);
                    setProgress(100)
                    setAlert(true);
                    setAlertTitle("Informasi");
                    setAlertSeverity("success");
                    setAlertContent("Selamat datang " + value.message + ". Anda akan di arahkan ke halaman selanjutnya dalam 3 detik lagi ...");
                    setTimeout(() => {
                      history.push("/dashboard");
                    }, "5000");
                   
                }else if(value.hasil == '2'){
                    //gagal
                    setLoading(false);
                    setProgress(100)
                    setAlert(true);
                    setAlertTitle("Informasi");
                    setAlertSeverity("error");
                    setAlertContent(value.message);
                    setTimeout(() => {
                      setAlert(false);
                    }, "3000");
                }else if(value.hasil == '3'){
                    //error axios
                    setLoading(false);
                    setProgress(100)
                    setAlert(true);
                    setAlertTitle("Informasi");
                    setAlertSeverity("error");
                    setAlertContent(value.message);
                    setTimeout(() => {
                      setAlert(false);
                    }, "3000");
                }
            });
          }else{
            setAlert(true);
            setAlertTitle("Informasi");
            setAlertSeverity("error");
            setAlertContent("Mohon masukkan captcha dengan benar.");
            setTimeout(() => {
              setAlert(false);
            }, "3000");
          }
        }
    }
    
    
  return(
    
    <div>
      <LoadingBar
            color='#f11946'
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
          />
        <main>
    <div class="container">

      <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

              <div class="d-flex justify-content-center py-4">
            
              </div>
              <div class="card">

                <div class="card-body">

                  <div>
                    {alert ? 
                      <Alert severity={alertSeverity}>
                        <AlertTitle>{alertTitle}</AlertTitle>
                        {alertContent}
                      </Alert> : 
                      <></> 
                    }
                  </div>

                  <div class="pt-4 pb-2">
                    <h5 class="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                    <p class="text-center small">Enter your username and password to login</p>
                  </div>

                  <form class="row g-3 needs-validation" onSubmit={handleSubmit} novalidate>

                    <div class="col-12">
                      <label for="yourUsername" class="form-label">Username</label>
                      <div class="input-group has-validation">
                        <input type="text" name="username" class="form-control" id="username" value={username} onChange={(e) => setUsername(e.target.value)} required />
                        <div class="invalid-feedback">Please enter your username.</div>
                      </div>
                    </div>

                    <div class="col-12">
                      <label for="yourPassword" class="form-label">Password</label>
                      <input type="password" name="password" class="form-control" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                      <div class="invalid-feedback">Please enter your password!</div>
                    </div>

                    <div class="col-12">
                        <div class="row mb-3">
                          <div class="col-6">
                            <ClientCaptcha captchaCode={setCode} />
                          </div>
                          <div class="col-6">
                            <input type="text" name="captchaCode" class="form-control" placeholder='Type Code' id="captchaCode" onChange={(e) => setCaptchaCode(e.target.value)} required />
                          </div>
                        </div>
                        
                        {
                          code == '0' ? 
                          <button class="btn btn-primary w-100" disabled type="submit">Login</button>
                          :
                          loading ? (<div><img src={require('../../../assets_admin_new/img/square-loader.gif')} width="100px" height="100px" alt="" />&nbsp;&nbsp;Please Wait ...</div>):(<button class="btn btn-primary w-100" type="submit">Login</button>)
                        }
                      
                      
                      
                      
                    </div>
                    
                  </form>

                </div>
              </div>


            </div>
          </div>
        </div>

      </section>

    </div>
  </main>
    </div>
    
  );
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}