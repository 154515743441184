import React, {useEffect, useState} from 'react';
import doSomething from "./Add_rent";

function TableRows({rowsData, deleteTableRows, handleChange}) {
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [orderRecap, setOrderRecap] = useState([]);

    function ambilData(){
        doSomething()
        // let url;
        // setProgress(progress + 40);
        // setLoading(true);
  
        // url = 'http://revasamudra.ivantie.com/backend/revasamudra/api/searchOrderRecap/C-0001';
  
        // const settings = {
        //     method: 'GET',
        //     headers: {
        //         Accept: 'application/json',
        //         'Content-Type': 'application/json',
        //         'userCode': localStorage.getItem('username'),
        //         'token': localStorage.getItem('token'),
        //     }
        // };
  
        // const fetchData = async () => {
        // const res = await fetch(
        //     url,settings
        // );
        // const json = await res.json();
        
        // if(json.status == "true"){
        //   setProgress(100);
        //   setLoading(false);
        //   setOrderRecap(json.data);
        // }else{
        //   setProgress(100);
        //   setLoading(false);
        // }
        
        // };
        // fetchData();
    }
    

    return(
        
        rowsData.map((data, index)=>{
            const {numberPlate,containerSize,driverCode,driverName,pocketMoney,from,destination,stapelMoney,stapelPocketMoney,containerNumber,dateOrderRecap,orderRecapId,deliveryDestinationCode,deliveryDestinationName,otherMoney,harbor,harborMoney,depo,depoMoney,coollieMoney,overtimeMoney,downpayment,priceCustomer,priceRent,subTotal}= data;
            return(
                <tr key={index}>
                <td>
                    <div class="row mb-3">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                            <label>No Rekap Order</label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" value={orderRecapId}  onChange={(evnt)=>(handleChange(index, evnt))} name="orderRecapId" className="form-control" disabled="true" />
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4">
                                <label>Pelabuhan / Nominal</label>
                                </div>
                                <div class="col-md-4">
                                    <input type="text" value={harbor}  onChange={(evnt)=>(handleChange(index, evnt))} name="harbor" className="form-control" disabled="true" /> 
                                </div>
                                <div class="col-md-4">
                                    <input type="text" value={harborMoney}  onChange={(evnt)=>(handleChange(index, evnt))} name="harborMoney" className="form-control" disabled="true" /> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                            <label>Plat Nomor</label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" value={numberPlate}  onChange={(evnt)=>(handleChange(index, evnt))} name="numberPlate" className="form-control" disabled="true" /> 
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                            <div class="col-md-4">
                            <label>Depo / Nominal</label>
                            </div>
                            <div class="col-md-4">
                                <input type="text" value={depo}  onChange={(evnt)=>(handleChange(index, evnt))} name="depo" className="form-control" disabled="true" />
                            </div>
                            <div class="col-md-4">
                                <input type="text" value={depoMoney}  onChange={(evnt)=>(handleChange(index, evnt))} name="depo" className="form-control" disabled="true" />
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                            <label>Tgl Jalan</label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" value={dateOrderRecap}  onChange={(evnt)=>(handleChange(index, evnt))} name="dateOrderRecap" className="form-control" disabled="true" /> 
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                            <div class="col-md-4">
                            <label>Lain - Lain</label>
                            </div>
                            <div class="col-md-8">
                                <input type="number" value={otherMoney} onChange={(evnt)=>(handleChange(index, evnt))} name="otherMoney" className="form-control" disabled="true" />
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                            <label>Pabrik</label>
                            </div>
                            <div class="col-md-4">
                                <input type="text" value={deliveryDestinationCode}  onChange={(evnt)=>(handleChange(index, evnt))} name="deliveryDestinationCode" className="form-control" disabled="true" /> 
                            </div>
                            <div class="col-md-4">
                                <input type="text" value={deliveryDestinationName}  onChange={(evnt)=>(handleChange(index, evnt))} name="deliveryDestinationName" className="form-control" disabled="true" /> 
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                            <div class="col-md-4">
                            <label>Dari / Ke</label>
                            </div>
                            <div class="col-md-4">
                                <input type="text" value={from} onChange={(evnt)=>(handleChange(index, evnt))} name="from" className="form-control" disabled="true" />
                            </div>
                            <div class="col-md-4">
                                <input type="text" value={destination} onChange={(evnt)=>(handleChange(index, evnt))} name="destination" className="form-control" disabled="true" />
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                            <label>Ukuran</label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" value={containerSize}  onChange={(evnt)=>(handleChange(index, evnt))} name="containerSize" className="form-control" disabled="true" /> 
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                            <div class="col-md-4">
                            <label>No Container</label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" value={containerNumber} onChange={(evnt)=>(handleChange(index, evnt))} name="containerNumber" className="form-control" disabled="true" />
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                            <label>Sopir</label>
                            </div>
                            <div class="col-md-4">
                                <input type="text" value={driverCode}  onChange={(evnt)=>(handleChange(index, evnt))} name="driverCode" className="form-control" disabled="true" /> 
                            </div>
                            <div class="col-md-4">
                                <input type="text" value={driverName}  onChange={(evnt)=>(handleChange(index, evnt))} name="driverName" className="form-control" disabled="true" /> 
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4">
                                <label>Ekstra</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" value={stapelPocketMoney} onChange={(evnt)=>(handleChange(index, evnt))} name="stapelPocketMoney" className="form-control" disabled="true" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                            <label>DP</label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" value={downpayment}  onChange={(evnt)=>(handleChange(index, evnt))} name="downpayment" className="form-control" disabled="true" /> 
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4">
                                <label>Stapel</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" value={stapelMoney} onChange={(evnt)=>(handleChange(index, evnt))} name="stapelMoney" className="form-control" disabled="true" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                            <label>Saku</label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" value={pocketMoney}  onChange={(evnt)=>(handleChange(index, evnt))} name="pocketMoney" className="form-control" disabled="true" /> 
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4">
                                <label>Harga Untuk Customer</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" value={priceCustomer} onChange={(evnt)=>(handleChange(index, evnt))} name="priceCustomer" className="form-control" disabled="true" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                            <label>Kuli</label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" value={coollieMoney}  onChange={(evnt)=>(handleChange(index, evnt))} name="coollieMoney" className="form-control" disabled="true" /> 
                            </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                            <div class="col-md-4">
                            <label>Harga Mobil Orang Lain</label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" value={priceRent}  onChange={(evnt)=>(handleChange(index, evnt))} name="priceRent" className="form-control" disabled="true" />
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4">
                                <label>Lemburan</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" value={overtimeMoney}  onChange={(evnt)=>(handleChange(index, evnt))} name="overtimeMoney" className="form-control" disabled="true" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4">
                                <label>Sub Total</label>
                                </div>
                                <div class="col-md-4">
                                    <input type="text" value={subTotal}  onChange={(evnt)=>(handleChange(index, evnt))} name="subTotal" className="form-control" disabled="true" />
                                </div>
                                <div class="col-md-4">
                                    <button className="btn btn-outline-danger" onClick={()=>(deleteTableRows(index))}>HAPUS</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                
            </tr>
            
            )
        })
    )
    
}
export default TableRows;