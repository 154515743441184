import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory } from 'react-router';
import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';
import LoadingBar from 'react-top-loading-bar';

import 'jquery/dist/jquery.min.js';
 
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

function Profile() {
  const [profile, setProfile] = useState([]);
  const [nama, setNama] = useState();
  const [file, setFile] = useState();

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const history = useHistory();

  useEffect(() => {
    setProgress(progress + 40)
    setLoading(true)
    const fetchData = async () => {
      const res = await fetch(
        'https://athaexpress.net/backend/ongkir/api/profil/'+localStorage.getItem('username'),
      );
      const json = await res.json();
      if(json.status == "true"){
        setProgress(100);
        setLoading(false);
        setProfile(json.data);
        setNama(json.data[0].nama);
      }else{
        setProgress(100);
        setLoading(false);
        setProfile("Profile Tidak Ditemukan");
      }
      
      
    };
    fetchData();
    
  },[]);

  function handleChangeEdit(){
    history.push('/edit_profile?username='+base64_encode(localStorage.getItem('username')));
  }

  const myImageStyle = { width: '200px', height: '250px', border:'5px solid #555' };

  return (
    <div>
        <LoadingBar
          color='#f11946'
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
        <Header_admin></Header_admin>
        <main id="main" class="main">

          <div class="pagetitle">
            <h1>Profil</h1>
            <nav>
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><Link to="/dashboard">Beranda</Link></li>
                <li class="breadcrumb-item active"><Link to="/type_item">Profil</Link></li>
              </ol>
            </nav>
          </div>

          <section class="section profile">
            <div class="row">
              <div class="col-xl-4">

                <div class="card">
                  <div class="card-body profile-card pt-4 d-flex flex-column align-items-center">

                    <img src={require('../../../assets_landingpage/img/fasworld.jpg')} alt="Profile" width="130px" height="130px" class="rounded-circle" />
                    &nbsp;
                    <h3>{nama}</h3>
                    <div class="social-links mt-2">
                      <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
                      <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
                      <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
                      <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-xl-8">

                <div class="card">
                  <div class="card-body pt-3">
                    <ul class="nav nav-tabs nav-tabs-bordered">

                      <li class="nav-item">
                        <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview">Overview</button>
                      </li>

                      {/* <li class="nav-item">
                        <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit">Edit Profile</button>
                      </li> */}

                      {/* <li class="nav-item">
                        <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-settings">Settings</button>
                      </li> */}

                      {/* <li class="nav-item">
                        <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-change-password">Change Password</button>
                      </li> */}

                    </ul>
                    <div class="tab-content pt-2">

                      <div class="tab-pane fade show active profile-overview" id="profile-overview">

                        { profile.map(item => (
                          <div>
                          <h5 class="card-title">Profile Details</h5>

                          <div class="row">
                            <div class="col-lg-3 col-md-4 label ">Username</div>
                            <div class="col-lg-9 col-md-8">{item.usercode}</div>
                          </div>

                          <div class="row">
                            <div class="col-lg-3 col-md-4 label">Nama</div>
                            <div class="col-lg-9 col-md-8">{item.nama}</div>
                          </div>

                          <div class="row">
                            <div class="col-lg-3 col-md-4 label">Alamat</div>
                            <div class="col-lg-9 col-md-8">{item.address}</div>
                          </div>
                          

                          <div class="row">
                            <div class="col-lg-3 col-md-4 label">Status</div>
                            <div class="col-lg-9 col-md-8">
                            <p style={{color: "red"}}>
                              {
                                item.flagstatus == '1' ? "Aktif" : "Tidak Aktif"
                              }
                            </p>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-3 col-md-4 label">Logo Resi</div>
                            <img src={file ? file:item.logo_resi} alt="logo_resi" style={myImageStyle} />
                          </div>
                          <div class="row">
                            <div class="col-lg-12 col-md-12 label"><button type="button" className="button_edit" disabled={loading} onClick={() => handleChangeEdit(item.usercode)}>
                              {loading ? (
                                <i
                                  className="fa fa-refresh fa-spin"
                                  style={{ marginRight: "5px" }}
                                />
                              ):
                              (<i
                                className="fa fa-save"
                                style={{ marginRight: "5px" }}
                              />)} &nbsp;
                              {loading && <span>Harap Tunggu ...</span>}
                              {!loading && <span>Edit</span>}  
                            </button></div>
                          </div>
                          
                          </div>
                        ))}
                        
                      </div>

                      {/* <div class="tab-pane fade pt-3" id="profile-settings">

                        <form>

                          <div class="row mb-3">
                            <label for="fullName" class="col-md-4 col-lg-3 col-form-label">Email Notifications</label>
                            <div class="col-md-8 col-lg-9">
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="changesMade" checked />
                                <label class="form-check-label" for="changesMade">
                                  Changes made to your account
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="newProducts" checked />
                                <label class="form-check-label" for="newProducts">
                                  Information on new products and services
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="proOffers" />
                                <label class="form-check-label" for="proOffers">
                                  Marketing and promo offers
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="securityNotify" checked disabled />
                                <label class="form-check-label" for="securityNotify">
                                  Security alerts
                                </label>
                              </div>
                            </div>
                          </div>

                          <div class="text-center">
                            <button type="submit" class="btn btn-primary">Save Changes</button>
                          </div>
                        </form>

                      </div> */}

                      {/* <div class="tab-pane fade pt-3" id="profile-change-password">
                        <form>

                          <div class="row mb-3">
                            <label for="currentPassword" class="col-md-4 col-lg-3 col-form-label">Current Password</label>
                            <div class="col-md-8 col-lg-9">
                              <input name="password" type="password" class="form-control" id="currentPassword" />
                            </div>
                          </div>

                          <div class="row mb-3">
                            <label for="newPassword" class="col-md-4 col-lg-3 col-form-label">New Password</label>
                            <div class="col-md-8 col-lg-9">
                              <input name="newpassword" type="password" class="form-control" id="newPassword" />
                            </div>
                          </div>

                          <div class="row mb-3">
                            <label for="renewPassword" class="col-md-4 col-lg-3 col-form-label">Re-enter New Password</label>
                            <div class="col-md-8 col-lg-9">
                              <input name="renewpassword" type="password" class="form-control" id="renewPassword" />
                            </div>
                          </div>

                          <div class="text-center">
                            <button type="submit" class="btn btn-primary">Change Password</button>
                          </div>
                        </form>

                      </div> */}

                    </div>

                  </div>
                </div>

              </div>
            </div>
          </section>

        </main>
        <Footer_admin></Footer_admin>

        
    </div>
  );
}

export default Profile;