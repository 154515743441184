import React, {useEffect, useState} from 'react';
import ReactPaginate from "react-paginate";
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {Modal, Button, Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { delete_item } from '../../../Context/actions';
import Select from "react-select";

import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';

import LoadingBar from 'react-top-loading-bar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Snackbar from "@material-ui/core/Snackbar";

import 'jquery/dist/jquery.min.js';
 
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 

function Search_price() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false);
  const [loadingCari, setLoadingCari] = useState(false);
  const [loadingResetPencarian, setLoadingResetPencarian] = useState(false);
  const [progress, setProgress] = useState(0)

  const history = useHistory();

  const [item, setItem] = useState([]);

  const [page, setPage] = useState(1);
  const [awal, setAwal] = useState();
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const [idFleet, setIdFleet] = useState([]);
  const [selectFleet, setSelectFleet] = useState([]);
  const [idDriver, setIdDriver] = useState([]);
  const [selectDriver, setSelectDriver] = useState([]);
  const [fromDate, setFromDate] = useState([]);
  const [untilDate, setUntilDate] = useState([]);

  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('info');


    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'usercode': localStorage.getItem('username'),
            'token': localStorage.getItem('token'),
        }
    };

    useEffect(() => {
        //search armada
        setAlert(true);
        setAlertContent("Load data armada ...");
        fetch("https://revasamudra.ivantie.com/backend/revasamudra/api/searchFleet",settings)
        .then((res) => res.json())
        .then((data) => {
            const dataFleet = data.data.map((item) => ({
            label: item.numberPlate + "(" + item.ownership + ")",
            value: item.numberPlate
            }));
            setSelectFleet(dataFleet);
            setAlert(false);

            // search sopir
            setAlert(true);
            setAlertContent("Load data sopir ...");
            fetch("https://revasamudra.ivantie.com/backend/revasamudra/api/searchDriver",settings)
            .then((res) => res.json())
            .then((data) => {
            const dataDriver = data.data.map((item) => ({
                label: item.driverName,
                value: item.driverCode
            }));
            setSelectDriver(dataDriver);
            setAlert(false);

            });

        });
    },[]);

    function createReport(){
        console.log(idDriver.idDriver.value,idFleet.idFleet.value,fromDate,untilDate);
        window.open("https://revasamudra.ivantie.com/backend/revasamudra/api/totalanDriver/"+idDriver.idDriver.value+"/"+idFleet.idFleet.value+"/"+fromDate+"/"+untilDate);
    }

    const handleChangeFleet = (idFleet) => {
        setIdFleet({ idFleet });
    }
    
    const handleChangeDriver = (idDriver) => {
        setIdDriver({ idDriver });
    }


  return (
      <div>
         <LoadingBar
            color='#f11946'
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
          />
          <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%' }}>
                  {alertContent}
                </Alert>
          </Snackbar>
        <Header_admin></Header_admin>
        <main id="main" class="main">

            <div class="pagetitle">
                <h1>Totalan Sopir</h1>
                <nav>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li class="breadcrumb-item active">Totalan Sopir</li>
                </ol>
                </nav>
            </div>
            <div class="row mb-3 justify-content-center align-items-center">
                <div class="col-sm-12">
                {alert ? 
                <Alert severity={alertSeverity}>
                    <AlertTitle>Mohon ditunggu</AlertTitle>
                    {alertContent}
                </Alert> : 
                <></> 
                }
                </div>
            </div>

          <section class="section">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body"> 
                        <div class="row mb-3">
                            <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-8">
                                    <label>Sopir</label>
                                    <div style={{width: '100%'}}>
                                    <Select
                                        options={selectDriver}
                                        onChange={handleChangeDriver}
                                    />
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-8">
                                    <label>Plat Nomor</label>
                                    <div style={{width: '100%'}}>
                                    <Select
                                        options={selectFleet}
                                        onChange={handleChangeFleet}
                                    />
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-8">
                                    <label>Dari Tanggal</label>
                                    <input type="date" id="fromDate" name="fromDate" onChange={(e) => setFromDate(e.target.value)} class="form-control" required />
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-8">
                                    <label>Sampai Tanggal</label>
                                    <input type="date" id="untilDate" name="untilDate" onChange={(e) => setUntilDate(e.target.value)} class="form-control" required />
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-8">
                                    <button type="button" onClick={createReport} className="button_cari">
                                        <span>Buat Totalan</span>
                                    </button>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
          </section>

        </main>

        <Footer_admin></Footer_admin>

      </div>
  );
}

export default Search_price;