import React, {useEffect, useState} from 'react';
import { useLocation, Link } from "react-router-dom";
import { useHistory } from 'react-router';
import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';
import LoadingBar from 'react-top-loading-bar';

import { add_supplier } from '../../../Context/actions';
import Select from "react-select";


function Add_supplier() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const [supplierEdit, setSupplierEdit] = useState();
    const [supplierCode, setSupplierCode] = useState();
    const [supplierName, setSupplierName] = useState();
    const [supplierAddress, setSupplierAddress] = useState();
    const [supplierPhone, setSupplierPhone] = useState();

    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const history = useHistory();

    const handleonSubmit = async e => {
    e.preventDefault();

      const promise_login = Promise.resolve(add_supplier(supplierName,supplierAddress,supplierPhone));
      setLoading(true)
      setProgress(progress + 40)
      promise_login.then((value) => {
          if(value.hasil == '1'){
              //sukses
              setLoading(false)
              setProgress(100)
              alert(value.message);
              history.push('/supplier');
              window.location.reload(true);
          }else if(value.hasil == '2'){
              //username password salah
              setLoading(false)
              setProgress(100)
              alert(value.message);
              history.push('/supplier');
              window.location.reload(true);
          }
      });
      
    }

  return (
 
        <div>
            <LoadingBar
              color='#f11946'
              progress={progress}
              onLoaderFinished={() => setProgress(0)}
            />
            <Header_admin></Header_admin>
            <main id="main" class="main">

              <div class="pagetitle">
                <h1>Tambah Pemasok</h1>
                <nav>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li class="breadcrumb-item"><Link to="/supplier">Pemasok</Link></li>
                    <li class="breadcrumb-item active">Tambah Pemasok</li>
                  </ol>
                </nav>
              </div>

              <section class="section">
                <div class="row">
                  <div class="col-lg-12">

                    <div class="card">
                      <div class="card-body">
                            <form onSubmit={handleonSubmit}>
                            <div class="row mb-12">
                                <label for="inputText" class="col-sm-2 col-form-label">Nama Pemasok</label>
                                <div class="col-sm-10">
                                <input type="text" class="form-control" name="supplierName" id="supplierName" onChange={(e) => setSupplierName(e.target.value)} />
                                </div>
                            </div>
                            <br />
                            <div class="row mb-12">
                                <label for="inputText" class="col-sm-2 col-form-label">Alamat Pemasok</label>
                                <div class="col-sm-10">
                                <input type="text" class="form-control" name="supplierAddress" id="supplierAddress" onChange={(e) => setSupplierAddress(e.target.value)} />
                                </div>
                            </div>
                            <br />
                            <div class="row mb-12">
                                <label for="inputText" class="col-sm-2 col-form-label">Telepon Pemasok</label>
                                <div class="col-sm-10">
                                <input type="text" class="form-control" name="supplierPhone" id="supplierPhone" onChange={(e) => setSupplierPhone(e.target.value)} />
                                </div>
                            </div>
                            <br />
                            <div class="row mb-12">
                                <div class="col-sm-12">
                                    <button type="submit" className="button_simpan" disabled={loading}>
                                      {loading ? (
                                        <i
                                          className="fa fa-refresh fa-spin"
                                          style={{ marginRight: "5px" }}
                                        />
                                      ):
                                      (<i
                                        className="fa fa-save"
                                        style={{ marginRight: "5px" }}
                                      />)} &nbsp;
                                      {loading && <span>Harap Tunggu ...</span>}
                                      {!loading && <span>Simpan</span>}
                                    </button>
                                </div>
                            </div>

                            </form>

                      </div>
                    </div>

                  </div>
                </div>
              </section>

            </main>
            <Footer_admin></Footer_admin>
        </div>
        
  );
}

export default Add_supplier;