import React, {useEffect, useState} from 'react';
import { useLocation, Link } from "react-router-dom";
import { useHistory } from 'react-router';
import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';
import Sidebar_admin from '../../../Layout/Sidebar_admin';

import { edit_driver } from '../../../Context/actions';
import LoadingBar from 'react-top-loading-bar';


function Edit_driver() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const [driverEdit, setDriverEdit] = useState([]);

    const [driverCode, setDriverCode] = useState();
    const [driverName, setDriverName] = useState();
    const [driverAddress, setDriverAddress] = useState();
    const [driverPhone, setDriverPhone] = useState();
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0)

    const history = useHistory();

    useEffect(() => {
        setProgress(progress + 40);
        const settings = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'usercode': localStorage.getItem('username'),
                'token': localStorage.getItem('token'),
            }
        };
        const fetchData = async () => {
            
          const res = await fetch(
            'https://revasamudra.ivantie.com/backend/revasamudra/api/editDriverPage/'+params.get("id"),settings
          );
          const json = await res.json();
          setProgress(100);
          setDriverEdit(json.data);
          setDriverCode(json.data[0].driverCode);
          setDriverName(json.data[0].driverName);
          setDriverAddress(json.data[0].driverAddress);
          setDriverPhone(json.data[0].driverPhone);
        };
        fetchData();
    },[]);

    const handleonSubmit = async e => {
    e.preventDefault();
        setLoading(true)
        setProgress(progress + 40)
        //console.log(login(username,password));
        const promise_login = Promise.resolve(edit_driver(params.get("id"),driverCode,driverName,driverAddress,driverPhone));

        promise_login.then((value) => {
            if(value.hasil == '1'){
                setLoading(false)
                setProgress(100)
                alert(value.message);
                history.push('/driver');
                window.location.reload(true);
            }else if(value.hasil == '2'){
                setLoading(false)
                setProgress(100)
                alert(value.message);
                history.push('/driver');
                window.location.reload(true);
            }
        });
    }

    

  return (
 
        <div>
            <LoadingBar
              color='#f11946'
              progress={progress}
              onLoaderFinished={() => setProgress(0)}
            /> 
            <Header_admin></Header_admin>
            <main id="main" class="main">

              <div class="pagetitle">
                <h1>Ubah Sopir</h1>
                <nav>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li class="breadcrumb-item"><Link to="/driver">Sopir</Link></li>
                    <li class="breadcrumb-item active">Ubah Sopir</li>
                  </ol>
                </nav>
              </div>

              <section class="section">
                <div class="row">
                  <div class="col-lg-12">

                    <div class="card">
                      <div class="card-body">
                            <form onSubmit={handleonSubmit}>
                            {driverEdit.map(item => (
                            <React.Fragment>
                              <div class="row mb-3">
                                  <label for="inputText" class="col-sm-2 col-form-label">Kode Sopir</label>
                                  <div class="col-sm-10">
                                  <input type="text" class="form-control" name="driverCode" id="driverCode" defaultValue={item.driverCode} value={driverCode} onChange={(e) => setDriverCode(e.target.value)} disabled="true" />
                                  </div>
                              </div>
                              <div class="row mb-3">
                                  <label for="inputText" class="col-sm-2 col-form-label">Nama Sopir</label>
                                  <div class="col-sm-10">
                                  <input type="text" class="form-control" name="driverName" id="driverName" defaultValue={item.driverName} value={driverName} onChange={(e) => setDriverName(e.target.value)} />
                                  </div>
                              </div>
                              <div class="row mb-3">
                                  <label for="inputText" class="col-sm-2 col-form-label">Alamat Sopir</label>
                                  <div class="col-sm-10">
                                  <input type="text" class="form-control" name="driverAddress" id="driverAddress" defaultValue={item.driverAddress} value={driverAddress} onChange={(e) => setDriverAddress(e.target.value)} />
                                  </div>
                              </div>
                              <div class="row mb-3">
                                  <label for="inputText" class="col-sm-2 col-form-label">Telepon Sopir</label>
                                  <div class="col-sm-10">
                                  <input type="text" class="form-control" name="driverPhone" id="driverPhone" defaultValue={item.driverPhone} value={driverPhone} onChange={(e) => setDriverPhone(e.target.value)} />
                                  </div>
                              </div>
                            </React.Fragment>
                            ))}
                            

                            <div class="row mb-3">
                              <label for="inputText" class="col-sm-2 col-form-label"></label>
                                <div class="col-sm-10">
                                    <button type="submit" className="button_simpan" disabled={loading}>
                                      {loading ? (
                                        <i
                                          className="fa fa-refresh fa-spin"
                                          style={{ marginRight: "5px" }}
                                        />
                                      ):
                                      (<i
                                        className="fa fa-save"
                                        style={{ marginRight: "5px" }}
                                      />)} &nbsp;
                                      {loading && <span>Harap Tunggu ...</span>}
                                      {!loading && <span>Simpan</span>}
                                    </button>
                                </div>
                            </div>

                            </form>

                      </div>
                    </div>

                  </div>
                </div>
              </section>

            </main>
            <Footer_admin></Footer_admin>
        </div>
        
  );
}

export default Edit_driver;