import React, {useEffect, useState,useRef} from 'react';
import ReactPaginate from "react-paginate";
import axios from 'axios';
import { Link,useLocation  } from 'react-router-dom';
import { useHistory  } from 'react-router';
import {Modal, Button, Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { delete_driver } from '../../../Context/actions';
import Select from "react-select";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Snackbar from "@material-ui/core/Snackbar";

import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';

import LoadingBar from 'react-top-loading-bar';


import 'jquery/dist/jquery.min.js';
 
//Datatable Modules
import $ from 'jquery'; 
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"

function Driver() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseSnackbar = () => setAlert(false);

  const [loading, setLoading] = useState(false);
  const [loadingCari, setLoadingCari] = useState(false);
  const [loadingResetPencarian, setLoadingResetPencarian] = useState(false);
  const [progress, setProgress] = useState(0)

  const history = useHistory();

  const [driverName, setDriverName] = useState();
  const [driverAddress, setDriverAddress] = useState();
  const [driverPhone, setDriverPhone] = useState();
  


  const [msg, setMsg] = useState("");

  
  const [idCustomer, setIdCustomer] = useState([]);
  const [selectCustomer, setSelectCustomer] = useState([]);
  const [idDriver, setIdDriver] = useState([]);
  const [selectDriver, setSelectDriver] = useState([]);
  const [fromDate, setFromDate] = useState([]);
  const [untilDate, setUntilDate] = useState([]);
  const [awal, setAwal] = useState("");
  const [akhir, setAkhir] = useState("");
  const [driver, setDriver] = useState("");
  const [customer, setCustomer] = useState("");
  const [recapOrder, setRecapOrder] = useState([]);


  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('info');

  const location = useLocation()
  const params = new URLSearchParams(location.search)


  const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'usercode': localStorage.getItem('username'),
            'token': localStorage.getItem('token'),
        }
    };


useEffect(() => {

    setAlert(true);
    setAlertContent("Load data Invoice ...");

    const fetchData = async () => { 
    const res = await fetch(
        'https://revasamudra.ivantie.com/backend/revasamudra/api/invoiceDueDateView',settings
    );
    const json = await res.json();

    setProgress(100);
    setLoadingCari(false)
    setRecapOrder([]);
    setRecapOrder(json.data);
    setAwal(awal);
    setAkhir(akhir);
    setDriver(driver);
    setCustomer(customer);
    setAlert(false);
    };
    fetchData(); 
 }, []);


function formatJustDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hours = '' + d.getHours(),
      minutes = '' + d.getMinutes(),
      seconds = '' + d.getSeconds();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;
  if (hours.length < 2) 
  hours = '0' + hours;
  if (minutes.length < 2) 
      minutes = '0' + minutes;
  if (seconds.length < 2) 
  seconds = '0' + seconds;

  return [day, month, year].join('/');
}

function currencyFormat(num) {
  return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
  
function pay(invoiceNumber,totalPrice){
  history.push('/pay_rent?invoiceNumber='+invoiceNumber+'&totalPrice='+totalPrice);
}

$("#example21").DataTable({
    "bDestroy": true
    // dom: 'Bfrtip',
    // buttons: [
    //     'excel'
    // ]
});

  return (
      <div>
        <LoadingBar
          color='#f11946'
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        /> 
        <Snackbar open={alert} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={alertSeverity} sx={{ width: '100%' }}>
                {alertContent}
                </Alert>
        </Snackbar>
        <Header_admin></Header_admin>
        <main id="main" class="main">

          <div class="pagetitle">
            <h1>Data Invoice Belum Lunas</h1>
            <nav>
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li class="breadcrumb-item active">Inv Belum Lunas</li>
              </ol>
            </nav>
          </div>

          <section class="section">
              {alert ? 
                  <div class="row">
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="row mb-3">
                            <div class="col-md-12">
                            <Alert severity={alertSeverity}>
                              <AlertTitle>Info</AlertTitle>
                              {alertContent}
                            </Alert>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  : 
                <></> 
              }
            <div class="row">
              <div class="col-lg-12">
              <div class="table-responsive">
              <table id="example2" class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th>No Invoice</th>
                    <th>Tgl Invoice</th>
                    <th>Customer</th>
                    <th>Note</th>
                    <th>Total Harga</th>
                    <th>Tgl Jatuh Tempo</th>
                    <th>Bayar</th>
                  </tr>
                </thead>
                <tbody>
                {
                  recapOrder != 'Data Tidak Tersedia' ?
                  recapOrder.map(recap => (
                    
                    <tr>
                      <td>{recap.invoiceNumber}</td>
                      <td>{formatJustDate(recap.invoiceDate)}</td>
                      <td>{recap.customerName}</td>
                      <td>{recap.note}</td>
                      <td>{currencyFormat(recap.totalPrice)}</td>
                      <td>{formatJustDate(recap.tempo)}</td>
                      <td>
                          <button type="button" className="button_hapus" onClick={() => pay(recap.invoiceNumber,recap.totalPrice)} disabled={loading}>
                           Bayar
                          </button>
                      </td>
                    </tr>
                  
                  ))
                  :
                  <tr>
                    <td colSpan={5}>Data Tidak Tersedia</td>
                  </tr>
                }
                </tbody>
              </table>
              </div>
              </div>
            </div>
          </section>

        </main>

        <Footer_admin></Footer_admin>

      </div>
  );
}

export default Driver;