import React from 'react';
import { Link } from 'react-router-dom';
import svgNotFound from '../../assets_admin_new/img/not-found.svg';

function Notfound() {
  
  return (
 
        <div>
            <main>

			<div class="container">

				<section class="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
				<h1>404</h1>
				<h2>The page you are looking for doesn't exist.</h2>
				<Link class="nav-link btn" to="/admin">Back to home</Link>
				<img src={svgNotFound} class="img-fluid py-5" alt="Page Not Found" />
				</section>

				</div>

            </main>
        </div>
        
  );
}

export default Notfound;