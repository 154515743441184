import React, {useEffect, useState} from 'react';
import Select from "react-select";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import TableRows from "./TableRows";
import { useLocation,Link } from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import { useHistory } from 'react-router';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Snackbar from "@material-ui/core/Snackbar";

import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';

import { edit_order_recap } from '../../../Context/actions';
import { format } from 'date-fns'

import LoadingBar from 'react-top-loading-bar';


function Edit_order() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const history = useHistory();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const [orderRecapEdit, setOrderRecapEdit] = useState([]);
    const [orderRecapCode, setOrderRecapCode] = useState();
    const [dateOrderRecap, setDateOrderRecap] = useState();
    const [containerSize, setContainerSize] = useState('0');
    const [containerNumber, setContainerNumber] = useState('0');
    const [pocketMoney, setPocketMoney] = useState('0');
    const [coolieMoney, setCoolieMoney] = useState('0');
    const [otherMoney, setOtherMoney] = useState();
    const [billMoney, setBillMoney] = useState('0');
    const [note, setNote] = useState('0');
    const [downpaymentMoney, setDownpaymentMoney] = useState('0');
    const [harborMoney, setHarborMoney] = useState('0');
    const [stapelMoney, setStapelMoney] = useState('0');
    const [depoMoney, setDepoMoney] = useState('0');
    const [stapelPocketMoney, setStapelPocketMoney] = useState('0');
    const [overtimeMoney, setOvertimeMoney] = useState('0');
    const [from, setFrom] = useState('0');
    const [destination, setDestination] = useState('0');
    const [harbor, setHarbor] = useState('0');
    const [depo, setDepo] = useState('0');

    const [idCustomer, setIdCustomer] = useState([]);
    const [selectCustomer, setSelectCustomer] = useState([]);
    const [customerCode, setCustomerCode] = useState();
    const [customerName, setCustomerName] = useState();
    const [idFleet, setIdFleet] = useState([]);
    const [selectFleet, setSelectFleet] = useState([]);
    const [fleet, setFleet] = useState();
    const [idDriver, setIdDriver] = useState([]);
    const [selectDriver, setSelectDriver] = useState([]);
    const [driverCode, setDriverCode] = useState();
    const [driverName, setDriverName] = useState();
    const [idFrom, setIdFrom] = useState([]);
    const [selectFrom, setSelectFrom] = useState([]);
    const [suggestFrom, setSuggestFrom] = React.useState([]);
    const [idDestination, setIdDestination] = useState([]);
    const [selectDestination, setSelectDestination] = useState([]);
    const [suggestDestination, setSuggestDestination] = React.useState([]);
    const [idDeliveryDestination, setIdDeliveryDestination] = useState([]);
    const [selectDeliveryDestination, setSelectDeliveryDestination] = useState([]);
    const [deliveryDestinationCode, setDeliveryDestinationCode] = useState('0');
    const [deliveryDestinationName, setDeliveryDestinationName] = useState('0');
    const [idHarbor, setIdHarbor] = useState([]);
    const [selectHarbor, setSelectHarbor] = useState([]);
    const [suggestHarbor, setSuggestHarbor] = React.useState([]);
    const [idDepo, setIdDepo] = useState([]);
    const [selectDepo, setSelectDepo] = useState([]);
    const [suggestDepo, setSuggestDepo] = React.useState([]);
    const [idActivity, setIdActivity] = useState([]);
    const [selectActivity, setSelectActivity] = useState([]);
    const [activity, setActivity] = useState('0');

    const [alert, setAlert] = useState(false);
    const [alertContent, setAlertContent] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');

    const [rowsData, setRowsData] = useState([]);

    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'usercode': localStorage.getItem('username'),
            'token': localStorage.getItem('token'),
        }
    };

    //search customer
    useEffect(() => {
      setAlert(true);
      setAlertContent("Load data customer ...");
      fetch("https://revasamudra.ivantie.com/backend/revasamudra/api/searchCustomer",settings)
      .then((res) => res.json())
      .then((data) => {
        const dataCustomer = data.data.map((item) => ({
          label: item.customerName,
          value: item.customerCode
        }));
        setSelectCustomer(dataCustomer);
        setAlert(false);

        //search armada
        setAlert(true);
        setAlertContent("Load data armada ...");
        fetch("https://revasamudra.ivantie.com/backend/revasamudra/api/searchFleet",settings)
        .then((res) => res.json())
        .then((data) => {
          const dataFleet = data.data.map((item) => ({
            label: item.numberPlate + "(" + item.ownership + ")",
            value: item.numberPlate
          }));
          setSelectFleet(dataFleet);
          setAlert(false);

          //search sopir
          setAlert(true);
          setAlertContent("Load data sopir ...");
          fetch("https://revasamudra.ivantie.com/backend/revasamudra/api/searchDriver",settings)
          .then((res) => res.json())
          .then((data) => {
            const dataDriver = data.data.map((item) => ({
              label: item.driverName,
              value: item.driverCode
            }));
            setSelectDriver(dataDriver);
            setAlert(false);

            //search dari
            setAlert(true);
            setAlertContent("Load data dari ...");
            fetch("https://revasamudra.ivantie.com/backend/revasamudra/api/searchFrom",settings)
            .then((res) => res.json())
            .then((data) => {
              let arrayFrom = []
              arrayFrom = data.data.map(function(obj){
                return obj.from
              })
              setSuggestFrom([...arrayFrom])
              setAlert(false);

              //search tujuan
              setAlert(true);
              setAlertContent("Load data tujuan ...");
              fetch("https://revasamudra.ivantie.com/backend/revasamudra/api/searchDestination",settings)
              .then((res) => res.json())
              .then((data) => {
                let arrayDestination = []
                arrayDestination = data.data.map(function(obj){
                  return obj.destination
                })
                setSuggestDestination([...arrayDestination])
                setAlert(false);

                //search pabrik
                setAlert(true);
                setAlertContent("Load data pabrik ...");
                fetch("https://revasamudra.ivantie.com/backend/revasamudra/api/searchDeliveryDestination",settings)
                .then((res) => res.json())
                .then((data) => {
                  const dataDeliveryDestination = data.data.map((item) => ({
                    label: item.deliveryDestinationName,
                    value: item.deliveryDestinationCode
                  }));
                  setSelectDeliveryDestination(dataDeliveryDestination);
                  setAlert(false);

                  //search pelabuhan
                  setAlert(true);
                  setAlertContent("Load data pelabuhan ...");
                  fetch("https://revasamudra.ivantie.com/backend/revasamudra/api/searchHarbor",settings)
                  .then((res) => res.json())
                  .then((data) => {
                    let arrayHarbor = []
                    arrayHarbor = data.data.map(function(obj){
                      return obj.harbor
                    })
                    setSuggestHarbor([...arrayHarbor])
                    setAlert(false);

                    //search depo
                    setAlert(true);
                    setAlertContent("Load data depo ...");
                    fetch("https://revasamudra.ivantie.com/backend/revasamudra/api/searchDepo",settings)
                    .then((res) => res.json())
                    .then((data) => {
                      let arrayDepo = []
                      arrayDepo = data.data.map(function(obj){
                        return obj.depo
                      })
                      setSuggestDepo([...arrayDepo])
                      setAlertContent("Load data Rekap Order "+params.get("id")+" ...")
                      const fetchData = async () => {
                          
                        const res = await fetch(
                          'https://revasamudra.ivantie.com/backend/revasamudra/api/editOrderRecapPage/'+params.get("id"), settings
                        );
                        const json = await res.json();
                        setAlert(false);
                        setOrderRecapEdit(json.data);
                        if(json.data[0].orderRecapCode == 'undefined' || json.data[0].orderRecapCode == '0' || json.data[0].orderRecapCode === null){
                          setOrderRecapCode('');
                        }else{
                          setOrderRecapCode(json.data[0].orderRecapCode);
                        } 
                        if(json.data[0].dateOrderRecap == 'undefined' || json.data[0].dateOrderRecap == '0' || json.data[0].dateOrderRecap === null){
                          setDateOrderRecap('');
                        }else{
                          setDateOrderRecap(json.data[0].dateOrderRecap);
                        }
                        if(json.data[0].containerSize == 'undefined' || json.data[0].containerSize == '0' || json.data[0].containerSize === null){
                          setContainerSize('');
                        }else{
                          setContainerSize(json.data[0].containerSize);
                        }
                        if(json.data[0].containerNumber == 'undefined' || json.data[0].containerNumber == '0' || json.data[0].containerNumber === null){
                          setContainerNumber('');
                        }else{
                          setContainerNumber(json.data[0].containerNumber);
                        }
                        if(json.data[0].pocketMoney == 'undefined' || json.data[0].pocketMoney == '0' || json.data[0].pocketMoney === null){
                          setPocketMoney('0');
                        }else{
                          setPocketMoney(json.data[0].pocketMoney);
                        }
                        if(json.data[0].coolieMoney == 'undefined' || json.data[0].coolieMoney == '0' || json.data[0].coolieMoney === null){
                          setCoolieMoney('0');
                        }else{
                          setCoolieMoney(json.data[0].coolieMoney);
                        }
                        if(json.data[0].otherMoney == 'undefined' || json.data[0].otherMoney == '0' || json.data[0].otherMoney === null){
                          setOtherMoney('0');
                        }else{
                          setOtherMoney(json.data[0].otherMoney);
                        }
                        if(json.data[0].billMoney == 'undefined' || json.data[0].billMoney == '0' || json.data[0].billMoney === null){
                          setBillMoney('0');
                        }else{
                          setBillMoney(json.data[0].billMoney);
                        }
                        if(json.data[0].note == 'undefined' || json.data[0].note == '0' || json.data[0].note === null){
                          setNote('');
                        }else{
                          setNote(json.data[0].note);
                        }
                        if(json.data[0].downpaymentMoney == 'undefined' || json.data[0].downpaymentMoney == '0' || json.data[0].downpaymentMoney === null){
                          setDownpaymentMoney('0');
                        }else{
                          setDownpaymentMoney(json.data[0].downpaymentMoney);
                        }
                        if(json.data[0].harborMoney == 'undefined' || json.data[0].harborMoney == '0' || json.data[0].harborMoney === null){
                          setHarborMoney('0');
                        }else{
                          setHarborMoney(json.data[0].harborMoney);
                        }
                        if(json.data[0].stapelMoney == 'undefined' || json.data[0].stapelMoney == '0' || json.data[0].stapelMoney === null){
                          setStapelMoney('0');
                        }else{
                          setStapelMoney(json.data[0].stapelMoney);
                        }
                        if(json.data[0].depoMoney == 'undefined' || json.data[0].depoMoney == '0' || json.data[0].depoMoney === null){
                          setDepoMoney('0');
                        }else{
                          setDepoMoney(json.data[0].depoMoney);
                        }
                        if(json.data[0].stapelPocketMoney == 'undefined' || json.data[0].stapelPocketMoney == '0' || json.data[0].stapelPocketMoney === null){
                          setStapelPocketMoney('0');
                        }else{
                          setStapelPocketMoney(json.data[0].stapelPocketMoney);
                        }
                        if(json.data[0].overtimeMoney == 'undefined' || json.data[0].overtimeMoney == '0' || json.data[0].overtimeMoney === null){
                          setOvertimeMoney('0');
                        }else{
                          setOvertimeMoney(json.data[0].overtimeMoney);
                        }
                        setFleet(json.data[0].numberPlate);
                        setCustomerCode(json.data[0].customerCode);
                        setCustomerName(json.data[0].customerName);
                        setDriverCode(json.data[0].driverCode);
                        setDriverName(json.data[0].driverName);
                        setActivity(json.data[0].activity);
                        setFrom(json.data[0].from);
                        setDestination(json.data[0].destination);
                        setDeliveryDestinationCode(json.data[0].deliveryDestinationCode);
                        setDeliveryDestinationName(json.data[0].deliveryDestinationName);
                        setHarbor(json.data[0].harbor);
                        setDepo(json.data[0].depo);
                      };
                      fetchData();
                    });
                  });
                });
              });
            });
          });
        });

      });
    },[]);


    useEffect(() => {
      let activity = [{
          label: 'EKSPORT',
          value: 'EKSPORT'
        },{
          label: 'IMPORT',
          value: 'IMPORT'
        },{
          label: 'LOKAL FULL',
          value: 'LOKAL FULL'
        },{
          label: 'LOKAL EMPTY',
          value: 'LOKAL EMPTY'
        },{
          label: 'ROUNDUSE',
          value: 'ROUNDUSE'
        },{
          label: 'DROP EMPTY',
          value: 'DROP EMPTY'
        },{
          label: 'DROP FULL',
          value: 'DROP FULL'
        }];

        setSelectActivity(activity)
    },[]);
 
   
    function simpanEdit(){
      
      let customerCodeGet,customerNameGet,fleetGet,driverCodeGet,driverNameGet,deliveryDestinationCodeGet,deliveryDestinationNameGet,activityGet;

      if(idCustomer.length !== 0){
        customerCodeGet = idCustomer.idCustomer.value;
        customerNameGet = idCustomer.idCustomer.label;
      }else{
        customerCodeGet = customerCode;
        customerNameGet = customerName;
      }
      if(idFleet.length !== 0){
        fleetGet = idFleet.idFleet.value;
      }else{
        fleetGet = fleet;
      }
      if(idDriver.length !== 0){
        driverCodeGet = idDriver.idDriver.value;
        driverNameGet = idDriver.idDriver.label;
      }else{
        driverCodeGet = driverCode;
        driverNameGet = driverName;
      }
      if(idDeliveryDestination.length !== 0){
        deliveryDestinationCodeGet = idDeliveryDestination.idDeliveryDestination.value;
        deliveryDestinationNameGet = idDeliveryDestination.idDeliveryDestination.label;
      }else{
        deliveryDestinationCodeGet = deliveryDestinationCode;
        deliveryDestinationNameGet = deliveryDestinationName;
      }
      if(idActivity.length !== 0){
        activityGet = idActivity.idActivity.value;
      }else{
        activityGet = activity;
      }

      setLoading(true);
      setProgress(progress + 40)

      setAlert(true);
      setAlertContent("Proses simpan update ...");

      console.log(params.get("id")," | ",orderRecapCode," | ",dateOrderRecap," | ",customerCodeGet," | ",customerNameGet," | ",fleetGet," | ",driverCodeGet," | ",driverNameGet," | ",from," | ",destination," | ",activityGet," | ",containerSize," | ",deliveryDestinationCodeGet," | ",deliveryDestinationNameGet," | ",containerNumber," | ",pocketMoney," | ",coolieMoney," | ",otherMoney," | ",billMoney," | ",note," | ",downpaymentMoney," | ",harbor," | ",harborMoney," | ",stapelMoney," | ",depo," | ",depoMoney," | ",stapelPocketMoney," | ",overtimeMoney);
      
      const promise1 = Promise.resolve(edit_order_recap(params.get("id"),orderRecapCode,dateOrderRecap,customerCodeGet,customerNameGet,fleetGet,driverCodeGet,driverNameGet,from,destination,activityGet,containerSize,deliveryDestinationCodeGet,deliveryDestinationNameGet,containerNumber,pocketMoney,coolieMoney,otherMoney,billMoney,note,downpaymentMoney,harbor,harborMoney,stapelMoney,depo,depoMoney,stapelPocketMoney,overtimeMoney));
      promise1.then((value) => {
        console.log(value);
        if(value.hasil == '1'){
            //sukses
            setLoading(false);
            setProgress(100);
            setAlertSeverity("success");
            setAlertContent(value.message + ". Refresh dalam 3 detik lagi ...");
            setTimeout(() => {
              // history.push('/edit_order_recap?id='+params.get("id"));
              history.push('/order_recap');
              window.location.reload(true);
            }, "3000");
        }else if(value.hasil == '2'){
            //username password salah
            setLoading(false);
            setProgress(100);
            setAlertSeverity("error");
            setAlertContent(value.message);
            console.log(params.get("id")," | ",orderRecapCode," | ",dateOrderRecap," | ",customerCodeGet," | ",customerNameGet," | ",fleetGet," | ",driverCodeGet," | ",driverNameGet," | ",from," | ",destination," | ",activityGet," | ",containerSize," | ",deliveryDestinationCodeGet," | ",deliveryDestinationNameGet," | ",containerNumber," | ",pocketMoney," | ",coolieMoney," | ",otherMoney," | ",billMoney," | ",note," | ",downpaymentMoney," | ",harbor," | ",harborMoney," | ",stapelMoney," | ",depo," | ",depoMoney," | ",stapelPocketMoney," | ",overtimeMoney);
            setTimeout(() => {
              // history.push('/edit_order_recap?id='+params.get("id"));
              // window.location.reload(true);
            }, "3000");
        }else if(value.hasil == '3'){
          //username password salah
          setLoading(false);
          setProgress(100);
          setAlertSeverity("error");
          setAlertContent(value.message);
          console.log(params.get("id")," | ",orderRecapCode," | ",dateOrderRecap," | ",customerCodeGet," | ",customerNameGet," | ",fleetGet," | ",driverCodeGet," | ",driverNameGet," | ",from," | ",destination," | ",activityGet," | ",containerSize," | ",deliveryDestinationCodeGet," | ",deliveryDestinationNameGet," | ",containerNumber," | ",pocketMoney," | ",coolieMoney," | ",otherMoney," | ",billMoney," | ",note," | ",downpaymentMoney," | ",harbor," | ",harborMoney," | ",stapelMoney," | ",depo," | ",depoMoney," | ",stapelPocketMoney," | ",overtimeMoney);
          setTimeout(() => {
            // history.push('/edit_order_recap?id='+params.get("id"));
            // window.location.reload(true);
          }, "3000");
      }
      });
    }
    
    const style_pembatas = {
      color: "#061C54",
      fontStyle: "bold"
    };

    const handleChangeCustomer = (idCustomer) => {
      setIdCustomer({ idCustomer });
    }
  
    const handleChangeFleet = (idFleet) => {
      setIdFleet({ idFleet });
    }
     
    const handleChangeDriver = (idDriver) => {
      setIdDriver({ idDriver });
    }

    const handleChangeFrom = (idFrom) => {
      setIdFrom({ idFrom });
    }

    const handleChangeDestination = (idDestination) => {
      setIdDestination({ idDestination });
    }

    const handleChangeDeliveryDestination = (idDeliveryDestination) => {
      setIdDeliveryDestination({ idDeliveryDestination });
    }

    const handleChangeHarbor = (idHarbor) => {
      setIdHarbor({ idHarbor });
    }

    const handleChangeDepo = (idDepo) => {
      setIdDepo({ idDepo });
    }

    const handleChangeActivity = (idActivity) => {
      setIdActivity({ idActivity });
    }

  return (
    <div>
        <LoadingBar
          color='#f11946'
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
        <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%' }}>
                  {alertContent}
                </Alert>
        </Snackbar>
        <Header_admin></Header_admin>
        <main id="main" class="main">

          <div class="pagetitle">
            <h1>Ubah Rekap Order</h1>
            <br />
            <nav>
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li class="breadcrumb-item active">Ubah Rekap Order</li>
              </ol>
            </nav>
          </div>
          <div class="row mb-3 justify-content-center align-items-center">
            <div class="col-sm-12">
              {alert ? 
              <Alert severity={alertSeverity}>
                <AlertTitle>Mohon ditunggu</AlertTitle>
                {alertContent}
              </Alert> : 
              <></> 
              }

              
            </div>
          </div>

          <section class="section">
              <div class="row">
                <div class="col-lg-12">

                  <div class="card">
                    <div class="card-body">
                        <div class="row mb-3 align-items-center">
                        </div>
                        {orderRecapEdit.map(item => (
                            <React.Fragment>
                              <div class="row">
                                  <div class="col-md-6 mb-3">
                                    <div class="row">
                                      <div class="col-md-4">
                                        <label>Tanggal Rekap</label>
                                      </div>
                                      <div class="col-md-8">
                                        <input type="date" id="dateOrderRecap" name="dateOrderRecap" defaultValue={dateOrderRecap} value={dateOrderRecap} onChange={(e) => setDateOrderRecap(e.target.value)} class="form-control" />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6 mb-3">
                                      <div class="row">
                                          <div class="col-md-4">
                                          <label>Plat Nomor</label>
                                          </div>
                                          <div class="col-md-8">
                                          <div style={{width: '100%'}}>
                                              <Select
                                              defaultValue={{label: item.numberPlate, value: item.numberPlate}}
                                              options={selectFleet}
                                              onChange={handleChangeFleet}
                                              />
                                          </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-md-6 mb-3">
                                      <div class="row">
                                          <div class="col-md-4">
                                          <label>Customer</label>
                                          </div>
                                          <div class="col-md-8">
                                          <div style={{width: '100%'}}>
                                              <Select
                                              defaultValue={{label: item.customerName, value: item.customerCode}}
                                              options={selectCustomer}
                                              onChange={handleChangeCustomer}
                                              />
                                          </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-md-6 mb-3">
                                      <div class="row">
                                          <div class="col-md-4">
                                          <label>Sopir</label>
                                          </div>
                                          <div class="col-md-8">
                                          <div style={{width: '100%'}}>
                                              <Select
                                              defaultValue={{label: item.driverName, value: item.driverCode}}
                                              options={selectDriver}
                                              onChange={handleChangeDriver}
                                              />
                                          </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-4">
                                        <label>Ukuran</label>
                                        </div>
                                        <div class="col-md-8">
                                          <input type="text" id="containerSize" name="containerSize" defaultValue={containerSize} value={containerSize} onChange={(e) => setContainerSize(e.target.value)} class="form-control" />
                                        </div>
                                      </div>
                                  </div>
                                  <div class="col-md-6 mb-3">
                                      <div class="row">
                                          <div class="col-md-4">
                                          <label>Kegiatan</label>
                                          </div>
                                          <div class="col-md-8">
                                          <div style={{width: '100%'}}>
                                              <Select
                                              defaultValue={{label: item.activity, value: item.activity}}
                                              options={selectActivity}
                                              onChange={handleChangeActivity}
                                              />
                                          </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-md-6 mb-3">
                                      <div class="row">
                                        <div class="col-md-4">
                                        <label>Nomor Kontainer</label>
                                        </div>
                                        <div class="col-md-8">
                                          <input type="text" id="containerNumber" name="containerNumber" defaultValue={containerNumber} value={containerNumber} onChange={(e) => setContainerNumber(e.target.value)} class="form-control" />
                                        </div>
                                      </div>
                                  </div>
                                  <div class="col-md-6 mb-3">
                                      <div class="row">
                                          <div class="col-md-4">
                                          <label>Dari</label>
                                          </div>
                                          <div class="col-md-8">
                                            <input type="text" id="from" name="from" list='suggestion_from' defaultValue={from} value={from} onChange={(e) => setFrom(e.target.value)} class="form-control" />
                                            <datalist id='suggestion_from'>
                                              {suggestFrom.map((make,index) =>{
                                                return (<option key={index} value={make}>{make}</option>)
                                              })}
                                            </datalist>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-md-6 mb-3">
                                      <div class="row">
                                          <div class="col-md-4">
                                          <label>Tujuan</label>
                                          </div>
                                          <div class="col-md-8">
                                            <input type="text" id="destination" name="destination" list='suggestion_destination' defaultValue={destination} value={destination} onChange={(e) => setDestination(e.target.value)} class="form-control" />
                                            <datalist id='suggestion_destination'>
                                              {suggestDestination.map((make,index) =>{
                                                return (<option key={index} value={make}>{make}</option>)
                                              })}
                                            </datalist>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-md-6 mb-3">
                                      <div class="row">
                                          <div class="col-md-4">
                                          <label>Pabrik</label>
                                          </div>
                                          <div class="col-md-8">
                                          <div style={{width: '100%'}}>
                                              <Select
                                              defaultValue={{label: item.deliveryDestinationName, value: item.deliveryDestinationCode}}
                                              options={selectDeliveryDestination}
                                              onChange={handleChangeDeliveryDestination}
                                              />
                                          </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-4">
                                        <label>Saku</label>
                                        </div>
                                        <div class="col-md-8">
                                          <input type="number" id="pocketMoney" name="pocketMoney" defaultValue={pocketMoney} value={pocketMoney} onChange={(e) => setPocketMoney(e.target.value)} class="form-control" />
                                        </div>
                                      </div>
                                  </div>
                                  <div class="col-md-6 mb-3">
                                      <div class="row">
                                        <div class="col-md-4">
                                        <label>Kuli</label>
                                        </div>
                                        <div class="col-md-8">
                                          <input type="number" id="coolieMoney" name="coolieMoney" defaultValue={coolieMoney} value={coolieMoney} onChange={(e) => setCoolieMoney(e.target.value)} class="form-control" />
                                        </div>
                                      </div>
                                  </div>
                                  <div class="col-md-6 mb-3">
                                      <div class="row">
                                        <div class="col-md-4">
                                        <label>Lain - Lain</label>
                                        </div>
                                        <div class="col-md-8">
                                          <input type="number" id="otherMoney" name="otherMoney" defaultValue={otherMoney} value={otherMoney} onChange={(e) => setOtherMoney(e.target.value)} class="form-control" required />
                                        </div>
                                      </div>
                                  </div>
                                  <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-4">
                                        <label>Bon (Rp)</label>
                                        </div>
                                        <div class="col-md-8">
                                          <input type="number" id="billMoney" name="billMoney" defaultValue={billMoney} value={billMoney} onChange={(e) => setBillMoney(e.target.value)} class="form-control" />
                                        </div>
                                      </div>
                                  </div>
                                  <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-4">
                                        <label>Keterangan</label>
                                        </div>
                                        <div class="col-md-8">
                                          <input type="text" id="note" name="note" defaultValue={note} value={note} onChange={(e) => setNote(e.target.value)} class="form-control" />
                                        </div>
                                      </div>
                                  </div>
                                  <div class="col-md-6 mb-3">
                                      <div class="row">
                                          <div class="col-md-4">
                                          <label>Pelabuhan</label>
                                          </div>
                                          <div class="col-md-8">
                                            <input type="text" id="harbor" name="harbor" list='suggestion_harbor' defaultValue={harbor} value={harbor} onChange={(e) => setHarbor(e.target.value)} class="form-control" />
                                            <datalist id='suggestion_harbor'>
                                              {suggestHarbor.map((make,index) =>{
                                                return (<option key={index} value={make}>{make}</option>)
                                              })}
                                            </datalist>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-4">
                                        <label>Nominal (Pelabuhan)</label>
                                        </div>
                                        <div class="col-md-8">
                                          <input type="number" id="harborMoney" name="harborMoney" defaultValue={harborMoney} value={harborMoney} onChange={(e) => setHarborMoney(e.target.value)} class="form-control" />
                                        </div>
                                      </div>
                                  </div>
                                  <div class="col-md-6 mb-3">
                                      <div class="row">
                                          <div class="col-md-4">
                                          <label>Depo</label>
                                          </div>
                                          <div class="col-md-8">
                                            <input type="text" id="depo" name="depo" list='suggestion_depo' defaultValue={depo} value={depo} onChange={(e) => setDepo(e.target.value)} class="form-control" />
                                            <datalist id='suggestion_depo'>
                                              {suggestDepo.map((make,index) =>{
                                                return (<option key={index} value={make}>{make}</option>)
                                              })}
                                            </datalist>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-md-6 mb-3">
                                      <div class="row">
                                        <div class="col-md-4">
                                        <label>Nominal (Depo)</label>
                                        </div>
                                        <div class="col-md-8">
                                          <input type="number" id="depoMoney" name="depoMoney" defaultValue={depoMoney} value={depoMoney} onChange={(e) => setDepoMoney(e.target.value)} class="form-control" />
                                        </div>
                                      </div>
                                  </div>
                                  <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-4">
                                        <label>Stapel</label>
                                        </div>
                                        <div class="col-md-8">
                                          <input type="text" id="stapelMoney" name="stapelMoney" defaultValue={stapelMoney} value={stapelMoney} onChange={(e) => setStapelMoney(e.target.value)} class="form-control" />
                                        </div>
                                      </div>
                                  </div>
                                  <div class="col-md-6 mb-3">
                                      <div class="row">
                                        <div class="col-md-4">
                                        <label>Ekstra</label>
                                        </div>
                                        <div class="col-md-8">
                                          <input type="number" id="stapelPocketMoney" name="stapelPocketMoney" defaultValue={stapelPocketMoney} value={stapelPocketMoney} onChange={(e) => setStapelPocketMoney(e.target.value)} class="form-control" />
                                        </div>
                                      </div>
                                  </div>
                                  <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-4">
                                        <label>Lemburan</label>
                                        </div>
                                        <div class="col-md-8">
                                          <input type="number" id="overtimeMoney" name="overtimeMoney" defaultValue={overtimeMoney} value={overtimeMoney} onChange={(e) => setOvertimeMoney(e.target.value)} class="form-control" />
                                        </div>
                                      </div>
                                  </div>
                                  <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-4">
                                        <label>DP (Isi jika ada DP Masuk)</label>
                                        </div>
                                        <div class="col-md-8">
                                          <input type="number" id="downpaymentMoney" name="downpaymentMoney" defaultValue={downpaymentMoney} value={downpaymentMoney} onChange={(e) => setDownpaymentMoney(e.target.value)} class="form-control" />
                                        </div>
                                      </div>
                                  </div>
                              </div>
                            </React.Fragment>
                        ))}

                <div class="row mb-3 justify-content-center align-items-center">
                  <div class="col-sm-12">
                    <button className="button_simpan" disabled={loading} onClick={simpanEdit}>
                      {loading ? (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                      ):
                      (<i
                        className="fa fa-save"
                        style={{ marginRight: "5px" }}
                      />)} &nbsp;
                      {loading && <span>Harap Tunggu ...</span>}
                      {!loading && <span>Simpan</span>}
                    </button>
                  </div>
                </div>
            </div>
          </div>

        </div>
      </div>
              </section>

        </main>
        <Footer_admin></Footer_admin>

        
    </div>
  );
}

export default Edit_order;