import React, {useEffect, useState} from 'react';
import { Link  } from 'react-router-dom';
import { useHistory  } from 'react-router';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from "react-select";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Snackbar from "@material-ui/core/Snackbar";

import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';

import LoadingBar from 'react-top-loading-bar';


import 'jquery/dist/jquery.min.js';
 
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 

function Recap_order_report() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseSnackbar = () => setAlert(false);

  const [loading, setLoading] = useState(false);
  const [loadingCari, setLoadingCari] = useState(false);
  const [loadingResetPencarian, setLoadingResetPencarian] = useState(false);
  const [progress, setProgress] = useState(0)

  const history = useHistory();

  const [driverName, setDriverName] = useState();
  const [driverAddress, setDriverAddress] = useState();
  const [driverPhone, setDriverPhone] = useState();
  


  const [msg, setMsg] = useState("");

  
  const [idCustomer, setIdCustomer] = useState([]);
  const [selectCustomer, setSelectCustomer] = useState([]);
  const [idDriver, setIdDriver] = useState([]);
  const [selectDriver, setSelectDriver] = useState([]);
  const [fromDate, setFromDate] = useState(['2000-01-01']);
  const [untilDate, setUntilDate] = useState(['2000-01-01']);
  const [awal, setAwal] = useState("");
  const [akhir, setAkhir] = useState("");
  const [driver, setDriver] = useState("");
  const [customer, setCustomer] = useState("");
  const [recapOrder, setRecapOrder] = useState([]);

  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('info');


  const settings = {
      method: 'GET',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'usercode': localStorage.getItem('username'),
          'token': localStorage.getItem('token'),
      }
  };

  const tableCustomStyles = {
    headRow: {
      style: {
        color:'#223336',
        backgroundColor: '#e7eef0'
      },
    },
    rows: {
      style: {
        color: "STRIPEDCOLOR",
        backgroundColor: "STRIPEDCOLOR"
      },
      stripedStyle: {
        color: "NORMALCOLOR",
        backgroundColor: "NORMALCOLOR"
      }
    }
  }

  useEffect(() => {
    //search armada
    setAlert(true);
    setAlertContent("Load data customer ...");
    fetch("https://revasamudra.ivantie.com/backend/revasamudra/api/searchCustomer",settings)
    .then((res) => res.json())
    .then((data) => {
        const dataFleet = data.data.map((item) => ({
        label: item.customerName,
        value: item.customerCode
        }));
        setSelectCustomer(dataFleet);
        setAlert(false);

        // search sopir
        setAlert(true);
        setAlertContent("Load data sopir ...");
        fetch("https://revasamudra.ivantie.com/backend/revasamudra/api/searchDriver",settings)
        .then((res) => res.json())
        .then((data) => {
        const dataDriver = data.data.map((item) => ({
            label: item.driverName,
            value: item.driverCode
        }));
        setSelectDriver(dataDriver);
        setAlert(false);

        });

    });
},[]);

function searchData(){
  let customerPar,driverPar,fromDatePar,untilDatePar;

  if(idCustomer.length !== 0){
    customerPar = idCustomer.idCustomer.value;
  }else{
    customerPar = '0';
  }
  if(idDriver.length !== 0){
    driverPar = idDriver.idDriver.value;
  }else{
    driverPar = '0';
  }
  if(fromDate == ''){
    fromDatePar = '2000-01-01';
  }else{
    fromDatePar = fromDate;
  }
  if(untilDate == ''){
    untilDatePar = '2000-01-01';
  }else{
    untilDatePar = untilDate;
  }

  setAlert(true);
  setAlertContent("Load data Rekap Order ...");
  
  const fetchData = async () => { 
  const res = await fetch(
      'https://revasamudra.ivantie.com/backend/revasamudra/api/recapOrderReportNew/'+fromDatePar+'/'+untilDatePar+'/'+driverPar+'/'+customerPar,settings
  );
  const json = await res.json();
  console.log(json);
    setAlert(false);
    setRecapOrder(json);
  };
  fetchData();
}

const handleChangeCustomer = (idCustomer) => {
    setIdCustomer({ idCustomer });
}

const handleChangeDriver = (idDriver) => {
    setIdDriver({ idDriver });
}


  return (
      <div>
        <LoadingBar
          color='#f11946'
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        /> 
        <Snackbar open={alert} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={alertSeverity} sx={{ width: '100%' }}>
                {alertContent}
                </Alert>
        </Snackbar>
        <Header_admin></Header_admin>
        <main id="main" class="main">

          <div class="pagetitle">
            <h1>Data Rekap Order</h1>
            <nav>
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li class="breadcrumb-item active">Lap Rekap Order</li>
              </ol>
            </nav>
          </div>

          <section class="section">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-body"> 
                    <div class="row mb-3">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-8">
                                <label>Sopir</label>
                                <div style={{width: '100%'}}>
                                <Select
                                    options={selectDriver}
                                    onChange={handleChangeDriver}
                                />
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-8">
                                <label>Customer</label>
                                <div style={{width: '100%'}}>
                                <Select
                                    options={selectCustomer}
                                    onChange={handleChangeCustomer}
                                />
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-8">
                                <label>Dari Tanggal</label>
                                <input type="date" id="fromDate" name="fromDate" onChange={(e) => setFromDate(e.target.value)} class="form-control" required />
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-8">
                                <label>Sampai Tanggal</label>
                                <input type="date" id="untilDate" name="untilDate" onChange={(e) => setUntilDate(e.target.value)} class="form-control" required />
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                          <div class="row">
                              <div class="col-md-8">
                                  <button type="button" onClick={searchData} className="button_cari">
                                      <span>Lihat Data</span>
                                  </button>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-body">
                    <div class="table-responsive">
                    <DataTableExtensions {...recapOrder}>
                      <DataTable
                          noHeader
                          defaultSortField="id"
                          defaultSortAsc={false}
                          pagination
                          highlightOnHover
                          customStyles={tableCustomStyles}
                      />
                    </DataTableExtensions>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </main>

        <Footer_admin></Footer_admin>

      </div>
  );
}

export default Recap_order_report;