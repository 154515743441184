import React, {useEffect, useState} from 'react';
import Select from "react-select";
import { useLocation, Link } from "react-router-dom";
import { useHistory } from 'react-router';
import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';
import Sidebar_admin from '../../../Layout/Sidebar_admin';

import { edit_fleet } from '../../../Context/actions';
import LoadingBar from 'react-top-loading-bar';


function Edit_fleet() {
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const [fleetEdit, setFleetEdit] = useState([]);

    const [numberPlate, setNumberPlate] = useState();
    const [note, setNote] = useState();
    const [ownership, setOwnership] = useState([]);
    const [idOwnership, setIdOwnership] = useState([]);
    const [selectOwnership, setSelectOwnership] = useState([]);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0)

    const history = useHistory();

    useEffect(() => {
        setProgress(progress + 40);
        const settings = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'usercode': localStorage.getItem('username'),
                'token': localStorage.getItem('token'),
            }
        };
        const fetchData = async () => {
            
          const res = await fetch(
            'https://revasamudra.ivantie.com/backend/revasamudra/api/editFleetPage/'+params.get("id"),settings
          );
          const json = await res.json();
          setProgress(100);
          setFleetEdit(json.data);
          setNumberPlate(json.data[0].numberPlate);
          setNote(json.data[0].note);
          setOwnership(json.data[0].ownership);
        };
        fetchData();
    },[]);

    const handleonSubmit = async e => {
    e.preventDefault();
        setLoading(true)
        setProgress(progress + 40)

        let ownershipCode;

        if(idOwnership.length !== 0){
            ownershipCode = idOwnership.idOwnership.value;
        }else{
            ownershipCode = ownership;
        }

        const promise_login = Promise.resolve(edit_fleet(params.get("id"),numberPlate,note,ownershipCode));

        promise_login.then((value) => {
            if(value.hasil == '1'){
                setLoading(false)
                setProgress(100)
                alert(value.message);
                history.push('/fleet');
                window.location.reload(true);
            }else if(value.hasil == '2'){
                setLoading(false)
                setProgress(100)
                alert(value.message);
                history.push('/fleet');
                window.location.reload(true);
            }
        });
    }

    useEffect(() => {
        let activity = [{
            label: 'Milik Sendiri',
            value: 'MS'
          },{
            label: 'Milik Orang Lain',
            value: 'MO'
          }];
  
          setSelectOwnership(activity)
    },[]);


    const handleChangeOwnership = (idOwnership) => {
        setIdOwnership({ idOwnership });
    }

    

  return (
 
        <div>
            <LoadingBar
              color='#f11946'
              progress={progress}
              onLoaderFinished={() => setProgress(0)}
            /> 
            <Header_admin></Header_admin>
            <main id="main" class="main">

              <div class="pagetitle">
                <h1>Ubah Armada</h1>
                <nav>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li class="breadcrumb-item"><Link to="/fleet">Armada</Link></li>
                    <li class="breadcrumb-item active">Ubah Armada</li>
                  </ol>
                </nav>
              </div>

              <section class="section">
                <div class="row">
                  <div class="col-lg-12">

                    <div class="card">
                      <div class="card-body">
                            <form onSubmit={handleonSubmit}>
                            {fleetEdit.map(item => (
                            <React.Fragment>
                              <div class="row mb-3">
                                  <label for="inputText" class="col-sm-2 col-form-label">Plat Nomor</label>
                                  <div class="col-sm-10">
                                  <input type="text" class="form-control" name="numberPlate" id="numberPlate" defaultValue={item.numberPlate} value={numberPlate} onChange={(e) => setNumberPlate(e.target.value)} />
                                  </div>
                              </div>
                              <div class="row mb-3">
                                  <label for="inputText" class="col-sm-2 col-form-label">Catatan</label>
                                  <div class="col-sm-10">
                                  <input type="text" class="form-control" name="note" id="note" defaultValue={item.note} value={note} onChange={(e) => setNote(e.target.value)} />
                                  </div>
                              </div>
                              <div class="row mb-3">
                                  <label for="inputText" class="col-sm-2 col-form-label">Kepemilikan</label>
                                  <div class="col-sm-10">
                                    <div style={{width: '100%'}}>
                                        <Select
                                        defaultValue={{label: (item.ownership == 'MS' ? ('Milik Sendiri'):('Milik Orang Lain')), value: item.ownership}}
                                        options={selectOwnership}
                                        onChange={handleChangeOwnership}
                                        />
                                    </div>
                                  </div>
                              </div>
                            </React.Fragment>
                            ))}
                            

                            <div class="row mb-3">
                              <label for="inputText" class="col-sm-2 col-form-label"></label>
                                <div class="col-sm-10">
                                    <button type="submit" className="button_simpan" disabled={loading}>
                                      {loading ? (
                                        <i
                                          className="fa fa-refresh fa-spin"
                                          style={{ marginRight: "5px" }}
                                        />
                                      ):
                                      (<i
                                        className="fa fa-save"
                                        style={{ marginRight: "5px" }}
                                      />)} &nbsp;
                                      {loading && <span>Harap Tunggu ...</span>}
                                      {!loading && <span>Simpan</span>}
                                    </button>
                                </div>
                            </div>

                            </form>

                      </div>
                    </div>

                  </div>
                </div>
              </section>

            </main>
            <Footer_admin></Footer_admin>
        </div>
        
  );
}

export default Edit_fleet;