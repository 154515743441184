import React, {useEffect, useState} from 'react';

import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import TableRows from "./TableRows";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import Select from "react-select";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import {Modal} from 'react-bootstrap';
import Snackbar from "@material-ui/core/Snackbar";

import Header_admin from '../../../Layout/Header_admin';
import Footer_admin from '../../../Layout/Footer_admin';

import { create_rent } from '../../../Context/actions';

import LoadingBar from 'react-top-loading-bar';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 


function Add_rent() {
    const history = useHistory();
    
    let [show, setShow] = useState(false);
    let [showBank, setShowBank] = useState(false);
    let handleClose = () => setShow(false);
    let handleCloseBank = () => setShow(false);

    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const [orderRecap, setOrderRecap] = useState([]);
    const [bank, setBank] = useState([]);
    const [invoiceNumber, setInvoiceNumber] = useState();
    const [invoiceDate, setInvoiceDate] = useState();
    const [idCustomer, setIdCustomer] = useState([]);
    const [selectCustomer, setSelectCustomer] = useState([]);
    const [note, setNote] = useState();
    const [pph, setPph] = useState(0);
    const [downpaymentMoney, setDownpaymentMoney] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [pay, setPay] = useState(0);
    const [pphType, setPphType] = useState('%');
    const [tempo, setTempo] = useState('2000-01-01');
    const [pphMoney, setPphMoney] = useState(0);
    const [fee, setFee] = useState();
    const [totalPocketMoney, setTotalPocketMoney] = useState(0);
    const [totalStapelMoney, setTotalStapelMoney] = useState(0);
    const [totalStapelPocketMoney, setTotalStapelPocketMoney] = useState(0);
    const [totalHarborMoney, setTotalHarborMoney] = useState(0);
    const [totalDepoMoney, setTotalDepoMoney] = useState(0);
    const [totalCoollieMoney, setTotalCoollieMoney] = useState(0);
    const [totalOvertimeMoney, setTotalOvertimeMoney] = useState(0);
    const [totalOtherMoney, setTotalOtherMoney] = useState(0);
    const [totalDownpaymentMoney, setTotalDownpaymentMoney] = useState(0);
    const [totalPriceCustomer, setTotalPriceCustomer] = useState(0);

    const [fleet, setFleet] = useState();
    const [ownership, setOwnership] = useState();
    const [containerSize, setContainerSize] = useState();
    const [driverCode, setDriverCode] = useState();
    const [driverName, setDriverName] = useState();
    const [pocketMoney, setPocketMoney] = useState();
    const [from, setFrom] = useState();
    const [destination, setDestination] = useState();
    const [priceCustomer, setPriceCustomer] = useState();
    const [stapelMoney, setStapelMoney] = useState();
    const [stapelPocketMoney, setStapelPocketMoney] = useState();
    const [containerNumber, setContainerNumber] = useState();
    const [travelDate, setTravelDate] = useState();
    const [orderRecapId, setOrderRecapId] = useState();
    const [deliveryDestinationCode, setDeliveryDestinationCode] = useState();
    const [deliveryDestinationName, setDeliveryDestinationName] = useState();
    const [priceRent, setPriceRent] = useState();
    const [otherMoney, setOtherMoney] = useState();
    const [harbor, setHarbor] = useState();
    const [harborMoney, setHarborMoney] = useState();
    const [depo, setDepo] = useState();
    const [depoMoney, setDepoMoney] = useState();
    const [coollieMoney, setCoollieMoney] = useState();
    const [overtimeMoney, setOvertimeMoney] = useState();
    const [downpayment, setDownpayment] = useState(0);

    const checkList = React.useRef(null);


    const [alert, setAlert] = useState(false);
    const [alertContent, setAlertContent] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');
    const [alertTitle, setAlertTitle] = useState('Mohon Ditunggu');

    const [rowsData, setRowsData] = useState([]);

    //initialize datatable
    $(document).ready(function () {
        setTimeout(function(){
        $('#example').DataTable();
        } ,1000);
    });

    const settings = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'usercode': localStorage.getItem('username'),
            'token': localStorage.getItem('token'),
        }
    };

    //search customer
    useEffect(() => {
      setAlert(true);
      setAlertContent("Load data customer ...");
      fetch("https://revasamudra.ivantie.com/backend/revasamudra/api/searchCustomer",settings)
      .then((res) => res.json())
      .then((data) => {
        const dataCustomer = data.data.map((item) => ({
          label: item.customerName,
          value: item.customerCode
        }));
        setSelectCustomer(dataCustomer);
        setAlert(false);

        setAlert(true);
        setAlertContent("Load data Nomor Invoice ...");
        fetch("https://revasamudra.ivantie.com/backend/revasamudra/api/addRentGetInvoiceNumber",settings)
        .then((res) => res.json())
        .then((data) => {
          // console.log(data.data);
          setInvoiceNumber(data.data);
          setAlert(false);

        });

      });
    },[]);

    const addTableRows = ()=>{
      let subTotalSum = 0;
      let totalPriceSum = 0;
      let totalPriceCustomerSum = 0;
      let totalDepoSum = 0, totalKuliSum = 0, totalStapelSum = 0, totalHarborSum = 0, totalOvertimeSum = 0;
      let totalPocketMoneySum = 0;
      let totalStapelPocketMoneySum = 0;
      let totalOtherMoneySum = 0;
      let totalDownpaymentSum = 0;
      
  
      if(rowsData.length > 0){
        // console.log(rowsData[0].orderRecapId,orderRecapId);
        // console.log(rowsData.length);
        if(rowsData.length == 1){
          if(orderRecapId == rowsData[0].orderRecapId){
            checkList.current=true;
          }else{
            checkList.current=false;
          }
        }else{
          for (let i = 0; i < rowsData.length; i++) {
            if(orderRecapId == rowsData[i].orderRecapId){
              checkList.current=true;
            }else{
              checkList.current=false;
            }
          }
        }
        
        if(checkList.current == true){
            setAlert(true);
            setAlertSeverity("error");
            setAlertContent("Rekap Order sudah ada dalam List.");
            setTimeout(() => {
                setAlert(false);
            }, "3000");
        }else{
        
          if(ownership == 'MO'){
            if((priceRent == '0' || priceRent == '') && (priceCustomer == '0' || priceCustomer == '')){
                setAlert(true);
                setAlertContent("Harap isi harga customer dan harga mobil orang lain")
            }else{
              subTotalSum = stapelMoney+harborMoney+depoMoney+coollieMoney+overtimeMoney+parseInt(document.getElementById("priceCustomer").value,10);
              const rowsInput={
                numberPlate:fleet,
                containerSize:containerSize,
                driverCode:driverCode,
                driverName:driverName,
                pocketMoney:pocketMoney,
                from:from,
                destination:destination,
                priceCustomer:document.getElementById("priceCustomer").value,
                stapelPocketMoney:stapelPocketMoney,
                subTotal: subTotalSum,
                containerNumber:containerNumber,
                dateOrderRecap:travelDate,
                stapelMoney:stapelMoney,
                orderRecapId:orderRecapId,
                deliveryDestinationCode:deliveryDestinationCode,
                deliveryDestinationName:deliveryDestinationName,
                priceRent:document.getElementById("priceRent").value,
                otherMoney:otherMoney,
                harbor:harbor,
                harborMoney:harborMoney,
                depo:depo,
                depoMoney:depoMoney,
                coollieMoney:coollieMoney,
                overtimeMoney:overtimeMoney,
                downpayment:downpayment
              } 
              totalPriceSum = totalPrice + subTotalSum;
              totalPriceCustomerSum = totalPriceCustomer + parseInt(document.getElementById("priceCustomer").value);
              totalDepoSum = totalDepoMoney + depoMoney;
              totalKuliSum = totalCoollieMoney + coollieMoney;
              totalPocketMoneySum = totalPocketMoney + pocketMoney;
              totalStapelSum = totalStapelMoney + stapelMoney;
              totalStapelPocketMoneySum = totalStapelPocketMoney + stapelPocketMoney;
              totalHarborSum = totalHarborMoney + harborMoney;
              totalOvertimeSum = totalOvertimeMoney + overtimeMoney;
              totalOtherMoneySum = totalOtherMoney + otherMoney;
              totalDownpaymentSum = totalDownpaymentMoney + downpayment;
              setRowsData([...rowsData, rowsInput])
  
              setTotalPrice(totalPriceSum);
              setTotalPriceCustomer(totalPriceCustomerSum);
              setPphMoney(Math.round((parseInt(document.getElementById("pph").value)/100)*totalPriceSum));
              setTotalDepoMoney(totalDepoSum);
              setTotalCoollieMoney(totalKuliSum);
              setTotalPocketMoney(totalPocketMoneySum);
              setTotalStapelMoney(totalStapelSum);
              setTotalStapelPocketMoney(totalStapelPocketMoneySum);
              setTotalHarborMoney(totalHarborSum);
              setTotalOvertimeMoney(totalOvertimeSum);
              setTotalOtherMoney(totalOtherMoneySum);
              setTotalDownpaymentMoney(totalDownpaymentSum);
              document.getElementById("downpaymentMoney").value = totalDownpaymentSum;
            }
          }else if(ownership == 'MS'){
            if(priceCustomer == '0' || priceCustomer == ''){
              setAlert(true);
              setAlertContent("Harap isi harga customer dan harga mobil orang lain")
            }else{
              subTotalSum = stapelPocketMoney+stapelMoney+harborMoney+depoMoney+coollieMoney+overtimeMoney+parseInt(document.getElementById("priceCustomer").value,10);
              const rowsInput={
                numberPlate:fleet,
                containerSize:containerSize,
                driverCode:driverCode,
                driverName:driverName,
                pocketMoney:pocketMoney,
                from:from,
                destination:destination,
                priceCustomer:document.getElementById("priceCustomer").value,
                stapelMoney:stapelMoney,
                stapelPocketMoney:stapelPocketMoney,
                containerNumber:containerNumber,
                dateOrderRecap:travelDate,
                orderRecapId:orderRecapId,
                deliveryDestinationCode:deliveryDestinationCode,
                deliveryDestinationName:deliveryDestinationName,
                otherMoney:otherMoney,
                harbor:harbor,
                harborMoney:harborMoney,
                depo:depo,
                depoMoney:depoMoney,
                coollieMoney:coollieMoney,
                overtimeMoney:overtimeMoney,
                downpayment:downpayment,
                subTotal:subTotalSum,
                priceRent:0
              }
              totalPriceSum = totalPrice + subTotalSum;
              totalPriceCustomerSum = totalPriceCustomer + parseInt(document.getElementById("priceCustomer").value);
              totalDepoSum = totalDepoMoney + depoMoney;
              totalKuliSum = totalCoollieMoney + coollieMoney;
              totalPocketMoneySum = totalPocketMoney + pocketMoney;
              totalStapelSum = totalStapelMoney + stapelMoney;
              totalStapelPocketMoneySum = totalStapelPocketMoney + stapelPocketMoney;
              totalHarborSum = totalHarborMoney + harborMoney;
              totalOvertimeSum = totalOvertimeMoney + overtimeMoney;
              totalOtherMoneySum = totalOtherMoney + otherMoney;
              totalDownpaymentSum = totalDownpaymentMoney + downpayment;
              setRowsData([...rowsData, rowsInput])
  
              setTotalPrice(totalPriceSum);
              setTotalPriceCustomer(totalPriceCustomerSum);
              setPphMoney(Math.round((parseInt(document.getElementById("pph").value)/100)*totalPriceSum));
              setTotalDepoMoney(totalDepoSum);
              setTotalCoollieMoney(totalKuliSum);
              setTotalPocketMoney(totalPocketMoneySum);
              setTotalStapelMoney(totalStapelSum);
              setTotalStapelPocketMoney(totalStapelPocketMoneySum);
              setTotalHarborMoney(totalHarborSum);
              setTotalOvertimeMoney(totalOvertimeSum);
              setTotalOtherMoney(totalOtherMoneySum);
              setTotalDownpaymentMoney(totalDownpaymentSum);
              document.getElementById("downpaymentMoney").value = totalDownpaymentSum;
            }
          }
        }
      }else{
        if(ownership == 'MO'){
          if((priceRent == '0' || priceRent == '') && (priceCustomer == '0' || priceCustomer == '')){
              setAlert(true);
              setAlertContent("Harap isi harga customer dan harga mobil orang lain")
          }else{
            subTotalSum = stapelPocketMoney+stapelMoney+harborMoney+depoMoney+coollieMoney+overtimeMoney+parseInt(document.getElementById("priceCustomer").value,10);
            const rowsInput={
              numberPlate:fleet,
              containerSize:containerSize,
              driverCode:driverCode,
              driverName:driverName,
              pocketMoney:pocketMoney,
              from:from,
              destination:destination,
              priceCustomer:document.getElementById("priceCustomer").value,
              stapelPocketMoney:stapelPocketMoney,
              subTotal: subTotalSum,
              containerNumber:containerNumber,
              dateOrderRecap:travelDate,
              stapelMoney:stapelMoney,
              orderRecapId:orderRecapId,
              deliveryDestinationCode:deliveryDestinationCode,
              deliveryDestinationName:deliveryDestinationName,
              priceRent:document.getElementById("priceRent").value,
              otherMoney:otherMoney,
              harbor:harbor,
              harborMoney:harborMoney,
              depo:depo,
              depoMoney:depoMoney,
              coollieMoney:coollieMoney,
              overtimeMoney:overtimeMoney,
              downpayment:downpayment
            } 
            totalPriceSum = totalPrice + subTotalSum;
            totalPriceCustomerSum = totalPriceCustomer + parseInt(document.getElementById("priceCustomer").value);
            totalDepoSum = totalDepoMoney + depoMoney;
            totalKuliSum = totalCoollieMoney + coollieMoney;
            totalPocketMoneySum = totalPocketMoney + pocketMoney;
            totalStapelSum = totalStapelMoney + stapelMoney;
            totalStapelPocketMoneySum = totalStapelPocketMoney + stapelPocketMoney;
            totalHarborSum = totalHarborMoney + harborMoney;
            totalOvertimeSum = totalOvertimeMoney + overtimeMoney;
            totalOtherMoneySum = totalOtherMoney + otherMoney;
            totalDownpaymentSum = totalDownpaymentMoney + downpayment;
            setRowsData([...rowsData, rowsInput])

            setTotalPrice(totalPriceSum);
            setTotalPriceCustomer(totalPriceCustomerSum);
            setPphMoney(Math.round((parseInt(document.getElementById("pph").value)/100)*totalPriceSum));
            setTotalDepoMoney(totalDepoSum);
            setTotalCoollieMoney(totalKuliSum);
            setTotalPocketMoney(totalPocketMoneySum);
            setTotalStapelMoney(totalStapelSum);
            setTotalStapelPocketMoney(totalStapelPocketMoneySum);
            setTotalHarborMoney(totalHarborSum);
            setTotalOvertimeMoney(totalOvertimeSum);
            setTotalOtherMoney(totalOtherMoneySum);
            setTotalDownpaymentMoney(totalDownpaymentSum);
            document.getElementById("downpaymentMoney").value = totalDownpaymentSum;
          }
        }else if(ownership == 'MS'){
          if(priceCustomer == '0' || priceCustomer == ''){
            setAlert(true);
            setAlertContent("Harap isi harga customer dan harga mobil orang lain")
          }else{
            subTotalSum = stapelPocketMoney+stapelMoney+harborMoney+depoMoney+coollieMoney+overtimeMoney+parseInt(document.getElementById("priceCustomer").value,10);
            const rowsInput={
              numberPlate:fleet,
              containerSize:containerSize,
              driverCode:driverCode,
              driverName:driverName,
              pocketMoney:pocketMoney,
              from:from,
              destination:destination,
              priceCustomer:document.getElementById("priceCustomer").value,
              stapelMoney:stapelMoney,
              stapelPocketMoney:stapelPocketMoney,
              containerNumber:containerNumber,
              dateOrderRecap:travelDate,
              orderRecapId:orderRecapId,
              deliveryDestinationCode:deliveryDestinationCode,
              deliveryDestinationName:deliveryDestinationName,
              otherMoney:otherMoney,
              harbor:harbor,
              harborMoney:harborMoney,
              depo:depo,
              depoMoney:depoMoney,
              coollieMoney:coollieMoney,
              overtimeMoney:overtimeMoney,
              downpayment:downpayment,
              subTotal:subTotalSum,
              priceRent:0
            }
            totalPriceSum = totalPrice + subTotalSum;
            totalPriceCustomerSum = totalPriceCustomer + parseInt(document.getElementById("priceCustomer").value);
            totalDepoSum = totalDepoMoney + depoMoney;
            totalKuliSum = totalCoollieMoney + coollieMoney;
            totalPocketMoneySum = totalPocketMoney + pocketMoney;
            totalStapelSum = totalStapelMoney + stapelMoney;
            totalStapelPocketMoneySum = totalStapelPocketMoney + stapelPocketMoney;
            totalHarborSum = totalHarborMoney + harborMoney;
            totalOvertimeSum = totalOvertimeMoney + overtimeMoney;
            totalOtherMoneySum = totalOtherMoney + otherMoney;
            totalDownpaymentSum = totalDownpaymentMoney + downpayment;
            setRowsData([...rowsData, rowsInput])

            setTotalPrice(totalPriceSum);
            setTotalPriceCustomer(totalPriceCustomerSum);
            setPphMoney(Math.round((parseInt(document.getElementById("pph").value)/100)*totalPriceSum));
            setTotalDepoMoney(totalDepoSum);
            setTotalCoollieMoney(totalKuliSum);
            setTotalPocketMoney(totalPocketMoneySum);
            setTotalStapelMoney(totalStapelSum);
            setTotalStapelPocketMoney(totalStapelPocketMoneySum);
            setTotalHarborMoney(totalHarborSum);
            setTotalOvertimeMoney(totalOvertimeSum);
            setTotalOtherMoney(totalOtherMoneySum);
            setTotalDownpaymentMoney(totalDownpaymentSum);
            document.getElementById("downpaymentMoney").value = totalDownpaymentSum;
          }
        }
      }
        
    }
    const deleteTableRows = (index)=>{
        const rows = [...rowsData];
        const subTotalRow = [...rowsData];
        const priceCustomerRow = [...rowsData];
        const depoMoneyRow = [...rowsData];
        const coollieMoneyRow = [...rowsData];
        const stapelMoneyRow = [...rowsData];
        const stapelPocketMoneyRow = [...rowsData];
        const harborMoneyRow = [...rowsData];
        const overtimeMoneyRow = [...rowsData];
        const downpaymentRow = [...rowsData];
        const pocketMoneyRow = [...rowsData];
        const otherMoneyRow = [...rowsData];
       
        const minusTotalPrice = subTotalRow.splice(index, 1)[0]['subTotal'];
        const minusPriceCustomer = parseInt(priceCustomerRow.splice(index, 1)[0]['priceCustomer']);
        const minusTotalDepoMoney = depoMoneyRow.splice(index, 1)[0]['depoMoney'];
        const minusTotalCoollieMoney = coollieMoneyRow.splice(index, 1)[0]['coollieMoney'];
        const minusTotalPocketMoney = pocketMoneyRow.splice(index, 1)[0]['pocketMoney'];
        const minusTotalOtherMoney = otherMoneyRow.splice(index, 1)[0]['otherMoney'];
        const minusTotalStapelMoney = stapelMoneyRow.splice(index, 1)[0]['stapelMoney'];
        const minusTotalStapelPocketMoney = stapelPocketMoneyRow.splice(index, 1)[0]['stapelPocketMoney'];
        const minusHarborMoney = parseInt(harborMoneyRow.splice(index, 1)[0]['harborMoney']);
        const minusOvertimeMoney = parseInt(overtimeMoneyRow.splice(index, 1)[0]['overtimeMoney']);
        const minusDownpayment = parseInt(downpaymentRow.splice(index, 1)[0]['downpayment']);
        setTotalPrice(totalPrice - minusTotalPrice);
        setTotalPriceCustomer(totalPriceCustomer - minusPriceCustomer);
        setPphMoney(Math.round((parseInt(document.getElementById("pph").value)/100)*(totalPrice - minusTotalPrice)));
        setTotalDepoMoney(totalDepoMoney - minusTotalDepoMoney);
        setTotalCoollieMoney(totalCoollieMoney - minusTotalCoollieMoney);
        setTotalPocketMoney(totalPocketMoney - minusTotalPocketMoney);
        setTotalOtherMoney(totalOtherMoney - minusTotalOtherMoney);
        setTotalStapelMoney(totalStapelMoney - minusTotalStapelMoney);
        setTotalStapelPocketMoney(totalStapelPocketMoney - minusTotalStapelPocketMoney);
        setTotalHarborMoney(totalHarborMoney - minusHarborMoney);
        setTotalOvertimeMoney(totalOvertimeMoney - minusOvertimeMoney);
        setTotalDownpaymentMoney(totalDownpaymentMoney - minusDownpayment);
        document.getElementById("downpaymentMoney").value = totalDownpaymentMoney - minusDownpayment;
        rows.splice(index, 1);
        setRowsData(rows);
        
    }
 
    const handleChange = (index, evnt)=>{
        const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        setRowsData(rowsInput);
    }

    function pilih(numberPlate,containerSize,driverCode,driverName,pocketMoney,from,destination,stapelMoney,stapelPocketMoney,containerNumber,dateOrderRecap,orderRecapId,deliveryDestinationCode,deliveryDestinationName,otherMoney,harbor,harborMoney,depo,depoMoney,coollieMoney,overtimeMoney,downpayment,ownership){
      setOrderRecapId(orderRecapId);
      setHarbor(harbor);
      setHarborMoney(harborMoney);
      setFleet(numberPlate);
      setDepo(depo);
      setDepoMoney(depoMoney);
      setTravelDate(dateOrderRecap);
      setOtherMoney(otherMoney);
      setDeliveryDestinationCode(deliveryDestinationCode);
      setDeliveryDestinationName(deliveryDestinationName);
      setFrom(from);
      setDestination(destination);
      setContainerSize(containerSize);
      setContainerNumber(containerNumber);
      setDriverCode(driverCode);
      setDriverName(driverName);
      setStapelPocketMoney(stapelPocketMoney);
      setDownpayment(downpayment);
      setStapelMoney(stapelMoney);
      setPocketMoney(pocketMoney);
      setCoollieMoney(coollieMoney);
      setOvertimeMoney(overtimeMoney);
      setPriceCustomer("0");
      document.getElementById("priceCustomer").value = '';
      setPriceRent("0");
      document.getElementById("priceRent").value = '';
      setOwnership(ownership);
      if(ownership == 'MS'){
        document.getElementById("priceRentDiv").style.display = "none";
      }else if(ownership == 'MO'){
        document.getElementById("priceRentDiv").style.display = "block";
      }
      setShow(false);
    }

    function ambilData(){
      if(idCustomer.length !== 0){
        let url;
        setProgress(progress + 40);
        setLoading(true);
        setAlert(true);
        setAlertSeverity("info");
        setAlertContent("Load data Rekap Order Pelanggan : " + idCustomer.idCustomer.label)
  
        url = 'https://revasamudra.ivantie.com/backend/revasamudra/api/searchOrderRecap/' + idCustomer.idCustomer.value;
  
        const settings = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'usercode': localStorage.getItem('username'),
                'token': localStorage.getItem('token'),
            }
        };
  
        const fetchData = async () => {
        const res = await fetch(
            url,settings
        );
        const json = await res.json();
        
        if(json.status == "true"){
          setProgress(100);
          setLoading(false);
          setOrderRecap(json.data);
          setShow(true);
          setAlert(false);
        }else{
          setProgress(100);
          setLoading(false);
          setAlert(true);
          setAlertSeverity("error");
          setAlertTitle("Perhatikan !");
          setAlertContent("Tidak ada Rekap Order Pelanggan : " + idCustomer.idCustomer.label)
        }
        
        };
        fetchData();
      }else{
        setAlert(true);
        setAlertSeverity("error");
        setAlertTitle("Perhatikan !");
        setAlertContent("Harap isi customer terlebih dahulu untuk mencari Rekap Order ...")
      }
    }

    function ambilDataBank(){
      let url;
      setProgress(progress + 40);
      setLoading(true);
      setAlert(true);
      setAlertSeverity("info");
      setAlertContent("Load data Nomor Rekening")
  
      url = 'https://revasamudra.ivantie.com/backend/revasamudra/api/searchBank';
  
      const settings = {
          method: 'GET',
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'usercode': localStorage.getItem('username'),
              'token': localStorage.getItem('token'),
          }
      };
  
      const fetchData = async () => {
      const res = await fetch(
          url,settings
      );
      const json = await res.json();
      
      setProgress(100);
      setLoading(false);
      setBank(json.data);
      setShowBank(true);
      setAlert(false);
      
      };
      fetchData();
    }
  
    function pilihBank(nomorRekening,atasNama,bank){
      
      setProgress(progress + 40)
      setLoading(true)

      setAlert(true);
      setAlertSeverity("info");
      setAlertContent("Proses simpan ...");

      console.log(invoiceDate,idCustomer.length,note,tempo,pph,downpayment,fee,rowsData.length);

      if(invoiceDate == undefined){
        setProgress(100);
        setLoading(false);
        setAlert(true);
        setAlertSeverity("error");
        setAlertTitle("Perhatikan !");
        setAlertContent("Harap isi Tanggal Invoice ...")
      }else{
        // console.log("pphmoney :" + pphMoney,"stapel money" + totalStapelMoney,"stapel pocket money" + totalStapelPocketMoney,"harbor money" + totalHarborMoney,"depo money" + totalDepoMoney,"coollie money" + totalCoollieMoney,"overtime money" + totalOvertimeMoney);
        let note_fix,tempo_fix,pph_fix,downpaymentMoney_fix,fee_fix;
        if(note == undefined){
          note_fix = '0';
        }else{
          note_fix = note;
        }
        if(tempo == undefined){
          tempo_fix = '0';
        }else{
          tempo_fix = tempo;
        }
        if(pph == undefined){
          pph_fix = '0';
        }else{
          pph_fix = pph;
        }
        if(downpaymentMoney == undefined){
          downpaymentMoney_fix = '0';
        }else{
          downpaymentMoney_fix = downpaymentMoney;
        }
        if(fee == undefined){
          fee_fix = '0';
        }else{
          fee_fix = fee;
        }

        const promise1 = Promise.resolve(create_rent(invoiceNumber,invoiceDate,idCustomer.idCustomer.value,idCustomer.idCustomer.label,note_fix,pph_fix,downpaymentMoney_fix,totalPrice,pay,pphType,tempo,pphMoney,fee_fix,totalPocketMoney,totalOtherMoney,totalStapelMoney,totalStapelPocketMoney,totalHarborMoney,totalDepoMoney,totalCoollieMoney,totalOvertimeMoney,rowsData));
        promise1.then((value) => {
          console.log(value.hasil);
          if(value.hasil == '1'){
              //sukses
              setProgress(100);
              setLoading(false);
              console.log(value.message);
              setAlertSeverity("success");
              setAlertContent(value.message + ". Silahkan print invoice dan aplikasi Refresh dalam 3 detik lagi ...");
              setShowBank(false);
              window.open("https://revasamudra.ivantie.com/backend/revasamudra/api/invoice/"+invoiceNumber+"/"+nomorRekening+"/"+atasNama+"/"+bank);
              setTimeout(() => {
              history.push('/rent');
              window.location.reload(true);
              }, "3000");
          }else if(value.hasil == '2'){
              //username password salah
              setProgress(100);
              setLoading(false);
              console.log(value.message);
              setAlertSeverity("error");
              setAlertContent(value.message + ". Refresh dalam 3 detik lagi ...");
              // setTimeout(() => {
              // history.push('/add_rent');
              // window.location.reload(true);
              // }, "3000");
          }else{
            setProgress(100);
            setLoading(false);
            console.log(value.message);
            setAlertSeverity("error");
            setAlertContent(value.message);
            // setTimeout(() => {
            // setAlert(false);
            // }, "3000");
          }
        });
      }
    }

    function simpan(){
      
      
      setProgress(progress + 40)
      setLoading(true)

      setAlert(true);
      setAlertSeverity("info");
      setAlertContent("Proses simpan ...");

      console.log(invoiceDate,idCustomer.length,note,tempo,pph,downpayment,fee,rowsData.length);

      if(invoiceDate == undefined){
        setProgress(100);
        setLoading(false);
        setAlert(true);
        setAlertSeverity("error");
        setAlertTitle("Perhatikan !");
        setAlertContent("Harap isi Tanggal Invoice ...")
      }else{
        // console.log("pphmoney :" + pphMoney,"stapel money" + totalStapelMoney,"stapel pocket money" + totalStapelPocketMoney,"harbor money" + totalHarborMoney,"depo money" + totalDepoMoney,"coollie money" + totalCoollieMoney,"overtime money" + totalOvertimeMoney);
        let note_fix,tempo_fix,pph_fix,downpaymentMoney_fix,fee_fix;
        if(note == undefined){
          note_fix = '0';
        }else{
          note_fix = note;
        }
        if(tempo == undefined){
          tempo_fix = '0';
        }else{
          tempo_fix = tempo;
        }
        if(pph == undefined){
          pph_fix = '0';
        }else{
          pph_fix = pph;
        }
        if(downpaymentMoney == undefined){
          downpaymentMoney_fix = '0';
        }else{
          downpaymentMoney_fix = downpaymentMoney;
        }
        if(fee == undefined){
          fee_fix = '0';
        }else{
          fee_fix = fee;
        }

        const promise1 = Promise.resolve(create_rent(invoiceNumber,invoiceDate,idCustomer.idCustomer.value,idCustomer.idCustomer.label,note_fix,pph_fix,downpaymentMoney_fix,totalPrice,pay,pphType,tempo,pphMoney,fee_fix,totalPocketMoney,totalOtherMoney,totalStapelMoney,totalStapelPocketMoney,totalHarborMoney,totalDepoMoney,totalCoollieMoney,totalOvertimeMoney,rowsData));
        promise1.then((value) => {
          console.log(value.hasil);
          if(value.hasil == '1'){
              //sukses
              setProgress(100);
              setLoading(false);
              console.log(value.message);
              setAlertSeverity("success");
              setAlertContent(value.message + ". Refresh dalam 3 detik lagi ...");
              setTimeout(() => {
              history.push('/add_rent');
              window.location.reload(true);
              }, "3000");
          }else if(value.hasil == '2'){
              //username password salah
              setProgress(100);
              setLoading(false);
              console.log(value.message);
              setAlertSeverity("error");
              setAlertContent(value.message + ". Refresh dalam 3 detik lagi ...");
              setTimeout(() => {
              history.push('/add_rent');
              window.location.reload(true);
              }, "3000");
          }else{
            setProgress(100);
            setLoading(false);
            console.log(value.message);
            setAlertSeverity("error");
            setAlertContent(value.message);
            setTimeout(() => {
            setAlert(false);
            }, "3000");
          }
        });
      }
    }
   
    const handleChangeCustomer = (idCustomer) => {
      setIdCustomer({ idCustomer });
    }

    const style_pembatas = {
      color: "#061C54",
      fontStyle: "bold"
     };

     const form_order = {
      color: "#061C54",
      fontStyle: "bold"
     };

     function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hours = '' + d.getHours(),
            minutes = '' + d.getMinutes(),
            seconds = '' + d.getSeconds();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        if (hours.length < 2) 
        hours = '0' + hours;
        if (minutes.length < 2) 
            minutes = '0' + minutes;
        if (seconds.length < 2) 
        seconds = '0' + seconds;

        return [day, month, year].join('/');
    }

  return (
    <div>
        <LoadingBar
          color='#f11946'
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
        <Snackbar open={alert} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%' }}>
                  {alertContent}
                </Alert>
        </Snackbar>
        <Header_admin></Header_admin>
        <main id="main" class="main">

          <div class="pagetitle">
            <h1>Buat Invoice</h1>
            <br />
            <nav>
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                <li class="breadcrumb-item active">Buat Invoice</li>
              </ol>
            </nav>
          </div>
          <div class="row mb-3 justify-content-center align-items-center">
              <div class="col-sm-12">
              {alert ? 
              <Alert severity={alertSeverity}>
                <AlertTitle>{alertTitle}</AlertTitle>
                {alertContent}
              </Alert> : 
              <></> 
              }
            </div>
          </div>

          <section class="section">
          <div class="row">
    <div class="col-lg-12">

      <div class="card">
        <div class="card-body">
            <div class="row mb-3 align-items-center">
              <h4 style={style_pembatas}>Data Invoice</h4>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4">
                    <label>No Invoice</label>
                    </div>
                    <div class="col-md-8">
                      <input type="text" id="invoiceNumber" name="invoiceNumber" defaultValue={invoiceNumber} disabled onChange={(e) => setInvoiceNumber(e.target.value)} class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4">
                      <label>Tanggal Invoice</label>
                    </div>
                    <div class="col-md-8">
                      <input type="date" id="invoiceDate" name="invoiceDate" onChange={(e) => setInvoiceDate(e.target.value)} class="form-control" />
                    </div>
                  </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4">
                      <label>Customer</label>
                    </div>
                    <div class="col-md-8">
                        <div style={{width: '100%'}}>
                        <Select
                            options={selectCustomer}
                            onChange={handleChangeCustomer}
                        />
                        </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                      <label>Keterangan</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="note" name="note" onChange={(e) => setNote(e.target.value)} class="form-control" />
                      </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4">
                      <label>Tempo</label>
                    </div>
                    <div class="col-md-8">
                      <input type="date" id="tempo" name="tempo" onChange={(e) => setTempo(e.target.value)} class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>PPH (%)</label>
                      </div>
                      <div class="col-md-5">
                        <input type="text" id="pph" name="pph" onChange={(e) => setPph(e.target.value)} defaultValue='0' class="form-control" />
                      </div>
                      <div class="col-md-3">
                        <input type="text" id="pphType" name="pphType" defaultValue="%" value="%" onChange={(e) => setPphType(e.target.value)} class="form-control" disabled="true" />
                      </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-4">
                      <label>Downpayment (DP)</label>
                    </div>
                    <div class="col-md-8">
                      <input type="number" id="downpaymentMoney" name="downpaymentMoney" onChange={(e) => setDownpaymentMoney(e.target.value)} class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-4">
                        <label>Fee</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" id="fee" name="fee" onChange={(e) => setFee(e.target.value)} class="form-control" />
                      </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3 align-items-center">
              <h4 style={style_pembatas}>Rekap Order</h4>
            </div>
            <div class="row mb-3">
            <div class="col-md-6">
            <div class="row">
                <div class="col-md-4">
                <label>No Rekap Order</label>
                </div>
                <div class="col-md-3">
                    <input type="text" value={orderRecapId} name="orderRecapId" onChange={(e) => setOrderRecapId(e.target.value)} className="form-control" disabled="true"/>
                </div>
                <div class="col-md-5">
                <button className="button_cari_tablerows" disabled={loading} onClick={ambilData}>
                    {loading ? (
                        <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                        />
                    ):
                    (<i
                        className="fa fa-search"
                        style={{ marginRight: "5px" }}
                    />)} &nbsp;
                    {loading && <span>Harap Tunggu ...</span>}
                    {!loading && <span>Cari</span>}
                </button>
                </div>
            </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-4">
                    <label>Pelabuhan / Nominal</label>
                    </div>
                    <div class="col-md-4">
                        <input type="text" value={harbor}  name="harbor" onChange={(e) => setHarbor(e.target.value)} className="form-control" disabled="true" /> 
                    </div>
                    <div class="col-md-4">
                        <input type="text" value={harborMoney}  name="harborMoney" onChange={(e) => setHarborMoney(e.target.value)} className="form-control" disabled="true" /> 
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6">
            <div class="row">
                <div class="col-md-4">
                <label>Plat Nomor</label>
                </div>
                <div class="col-md-4">
                    <input type="text" value={fleet}  name="fleet" onChange={(e) => setFleet(e.target.value)} className="form-control" disabled="true" /> 
                </div>
                <div class="col-md-4">
                    <input type="text" value={ownership}  name="ownership" onChange={(e) => setOwnership(e.target.value)} className="form-control" disabled="true" /> 
                </div>
            </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                <div class="col-md-4">
                <label>Depo / Nominal</label>
                </div>
                <div class="col-md-4">
                    <input type="text" value={depo}  name="depo" onChange={(e) => setDepo(e.target.value)} className="form-control" disabled="true" />
                </div>
                <div class="col-md-4">
                    <input type="text" value={depoMoney}  name="depoMoney" onChange={(e) => setDepoMoney(e.target.value)} className="form-control" disabled="true" />
                </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6">
            <div class="row">
                <div class="col-md-4">
                <label>Tgl Jalan</label>
                </div>
                <div class="col-md-8">
                    <input type="text" value={formatDate(travelDate)}  name="travelDate" onChange={(e) => setTravelDate(e.target.value)} className="form-control" disabled="true" /> 
                </div>
            </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                <div class="col-md-4">
                <label>Lain - Lain</label>
                </div>
                <div class="col-md-8">
                    <input type="number" value={otherMoney} name="otherMoney" onChange={(e) => setOtherMoney(e.target.value)} className="form-control" disabled="true" />
                </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6">
            <div class="row">
                <div class="col-md-4">
                <label>Pabrik</label>
                </div>
                <div class="col-md-4">
                    <input type="text" value={deliveryDestinationCode}  name="deliveryDestinationCode" onChange={(e) => setDeliveryDestinationCode(e.target.value)} className="form-control" disabled="true" /> 
                </div>
                <div class="col-md-4">
                    <input type="text" value={deliveryDestinationName}  name="deliveryDestinationName" onChange={(e) => setDeliveryDestinationName(e.target.value)} className="form-control" disabled="true" /> 
                </div>
            </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                <div class="col-md-4">
                <label>Dari / Ke</label>
                </div>
                <div class="col-md-4">
                    <input type="text" value={from} name="from" onChange={(e) => setFrom(e.target.value)} className="form-control" disabled="true" />
                </div>
                <div class="col-md-4">
                    <input type="text" value={destination} name="destination" onChange={(e) => setDestination(e.target.value)} className="form-control" disabled="true" />
                </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6">
            <div class="row">
                <div class="col-md-4">
                <label>Ukuran</label>
                </div>
                <div class="col-md-8">
                    <input type="text" value={containerSize}  name="containerSize" onChange={(e) => setContainerSize(e.target.value)} className="form-control" disabled="true" /> 
                </div>
            </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                <div class="col-md-4">
                <label>No Container</label>
                </div>
                <div class="col-md-8">
                    <input type="text" value={containerNumber} name="containerNumber" onChange={(e) => setContainerNumber(e.target.value)} className="form-control" disabled="true" />
                </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6">
            <div class="row">
                <div class="col-md-4">
                <label>Sopir</label>
                </div>
                <div class="col-md-4">
                    <input type="text" value={driverCode}  name="driverCode" onChange={(e) => setDriverCode(e.target.value)} className="form-control" disabled="true" /> 
                </div>
                <div class="col-md-4">
                    <input type="text" value={driverName}  name="driverName" onChange={(e) => setDriverName(e.target.value)} className="form-control" disabled="true" /> 
                </div>
            </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-4">
                    <label>Ekstra</label>
                    </div>
                    <div class="col-md-8">
                        <input type="text" value={stapelPocketMoney} name="stapelPocketMoney" onChange={(e) => setStapelPocketMoney(e.target.value)} className="form-control" disabled="true" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6">
            <div class="row">
                <div class="col-md-4">
                <label>DP</label>
                </div>
                <div class="col-md-8">
                    <input type="text" value={downpayment}  name="downpayment" onChange={(e) => setDownpaymentMoney(e.target.value)} className="form-control" disabled="true" /> 
                </div>
            </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-4">
                    <label>Stapel</label>
                    </div>
                    <div class="col-md-8">
                        <input type="text" value={stapelMoney} name="stapelMoney" onChange={(e) => setStapelMoney(e.target.value)} className="form-control" disabled="true" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6">
            <div class="row">
                <div class="col-md-4">
                <label>Saku</label>
                </div>
                <div class="col-md-8">
                    <input type="text" value={pocketMoney}  name="pocketMoney" onChange={(e) => setPocketMoney(e.target.value)} className="form-control" disabled="true" /> 
                </div>
            </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-4">
                    <label>Harga Untuk Customer</label>
                    </div>
                    <div class="col-md-8">
                        <input type="text" defaultValue={priceCustomer} name="priceCustomer" id="priceCustomer" onChange={(e) => setPriceCustomer(e.target.value)} className="form-control" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6">
            <div class="row">
                <div class="col-md-4">
                <label>Kuli</label>
                </div>
                <div class="col-md-8">
                    <input type="text" value={coollieMoney}  name="coollieMoney" onChange={(e) => setCoollieMoney(e.target.value)} className="form-control" disabled="true" /> 
                </div>
            </div>
            </div>
            <div class="col-md-6" id="priceRentDiv">
                <div class="row">
                <div class="col-md-4">
                <label>Harga Mobil Orang Lain</label>
                </div>
                <div class="col-md-8">
                    <input type="text" defaultValue={priceRent}  name="priceRent" id="priceRent" onChange={(e) => setPriceRent(e.target.value)} className="form-control" />
                </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-4">
                    <label>Lemburan</label>
                    </div>
                    <div class="col-md-8">
                        <input type="text" value={overtimeMoney}  name="overtimeMoney" onChange={(e) => setOvertimeMoney(e.target.value)} className="form-control" disabled="true" />
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4">
                                <label>TAMBAHKAN</label>
                                </div>
                                <div class="col-md-8">
                                    <button className="btn btn-outline-danger" onClick={addTableRows}>Tambahkan</button>
                                </div>
                            </div>
                        </div>
        </div>
            <div class="row mb-3 align-items-center">
              <h4 style={style_pembatas}>Rekap Order Terpilih</h4>
            </div>
            <div class="row mb-3 justify-content-center align-items-center">
                <table className="table">
                    <thead>
                    <tr>
                        <th>TOTAL : {totalPrice} <br /> Harga Customer : {totalPriceCustomer} <br /> PPH : {pphMoney} <br /> Depo / Kuli / Stapel : {totalDepoMoney} / {totalCoollieMoney} / {totalStapelMoney} <br /> Ekstra : {totalStapelPocketMoney}</th>
                    </tr>
                    </thead>
                <tbody>
                <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} />
                </tbody> 
                </table>
            </div>
            <div class="row mb-3 justify-content-center align-items-center">
              <div class="col-sm-12">
                <button className="button_simpan" disabled={loading} onClick={ambilDataBank}>
                  {loading ? (
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px" }}
                    />
                  ):
                  (<i
                    className="fa fa-save"
                    style={{ marginRight: "5px" }}
                  />)} &nbsp;
                  {loading && <span>Harap Tunggu ...</span>}
                  {!loading && <span>Simpan</span>}
                </button>
              </div>
            </div>
        </div>
      </div>

    </div>
  </div>
          </section>

        </main>
        
        <Modal size="lg" show={showBank} onHide={handleCloseBank}>
          <Modal.Header closeButton>
            <Modal.Title>Data Nomor Rekening</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <form class="row g-3 needs-validation" novalidate>
            <div class="row">
              <div class="col-12">
              <div class="table-responsive">
                <table id="example" className="table table-hover table-bordered">
                    <thead>
                    <tr>
                        <th>Action</th>
                        <th>Bank</th>
                        <th>No Rekening</th>
                        <th>Atas Nama</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                    bank != 'Data Tidak Tersedia' ?
                    bank.map((bank) => (
                        <tr>
                        <td>
                          <div class="w3-show-inline-block">
                            <div class="w3-bar">
                              <button type="button" className="button_edit" disabled={loading} onClick={() => pilihBank(bank.accountNumber,bank.owner,bank.bank)}>
                                {loading ? (
                                  <i
                                    className="fa fa-refresh fa-spin"
                                    style={{ marginRight: "5px" }}
                                  />
                                ):
                                (<i
                                  className="fa fa-edit"
                                  style={{ marginRight: "5px" }}
                                />)}
                                {loading && <span>Pilih</span>}
                                {!loading && <span>Pilih</span>}
                              </button>
                            </div>
                          </div>
                        </td>
                        <td>{bank.bank}</td>
                        <td>{bank.accountNumber}</td>
                        <td>{bank.owner}</td>
                        </tr>
                    ))
                    :
                    <tr>
                        <td colSpan={5}>Data Tidak Tersedia</td>
                    </tr>
                    }
                    </tbody>
                </table>
                </div>
              </div>
            </div>
          </form>
          </Modal.Body>
        </Modal>
        <Modal size="lg" show={show} onHide={handleClose}>
        
          <Modal.Header closeButton>
            <Modal.Title>Data Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <form class="row g-2 needs-validation" novalidate>
            <div class="row">
              <div class="col-lg-12">
                <div class="table-responsive">
                  <table id="example" className="table table-hover table-bordered">
                      <thead>
                      <tr>
                          <th>Action</th>
                          <th>No Rekap</th>
                          <th>Plat Nomor</th>
                          <th>No Kontainer</th>
                          <th>Pelanggan</th>
                          <th>Dari</th>
                          <th>Tujuan</th>
                          <th>Tanggal</th>
                          <th>Uang Saku</th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                      orderRecap != 'Data Tidak Tersedia' ?
                      orderRecap.map((order) => (
                          <tr>
                          <td>
                            <div class="w3-show-inline-block">
                              <div class="w3-bar">
                                <button type="button" className="button_edit" disabled={loading} onClick={() => pilih(order.numberPlate,order.containerSize,order.driverCode,order.driverName,order.pocketMoney,order.from,order.destination,order.stapelMoney,order.stapelPocketMoney,order.containerNumber,order.dateOrderRecap,order.id,order.deliveryDestinationCode,order.deliveryDestinationName,order.otherMoney,order.harbor,order.harborMoney,order.depo,order.depoMoney,order.coolieMoney,order.overtimeMoney,order.downpaymentMoney,order.ownership)}>
                                  {loading ? (
                                    <i
                                      className="fa fa-refresh fa-spin"
                                      style={{ marginRight: "5px" }}
                                    />
                                  ):
                                  (<i
                                    className="fa fa-edit"
                                    style={{ marginRight: "5px" }}
                                  />)}
                                  {loading && <span>Pilih</span>}
                                  {!loading && <span>Pilih</span>}
                                </button>
                              </div>
                            </div>
                          </td>
                          <td>{order.id}</td>
                          <td>{order.numberPlate}</td>
                          <td>{order.containerNumber}</td>
                          <td>{order.customerName}</td>
                          <td>{order.from}</td>
                          <td>{order.destination}</td>
                          <td>{order.dateOrderRecap}</td>
                          <td>{order.billMoney}</td>
                          </tr>
                      ))
                      :
                      <tr>
                          <td colSpan={5}>Data Tidak Tersedia</td>
                      </tr>
                      }
                      </tbody>
                  </table>
                </div>
              </div>
            </div>
          </form>
          </Modal.Body>
        </Modal>
        <Footer_admin></Footer_admin>

        
    </div>
  );
}

export default Add_rent;